import React from "react";
import ReactDOM from "react-dom";
import './css/bootstrap.min.css';
import './css/reset.css';
// import './css/grid.css';
// import './css/font-awesome.min.css';
import './css/ebonik.css';
import './css/style.css';
import './css/responsive.css';
import "./index.css";
import "./monami.css";
import "./index2.css";
import "./override.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import ScrolToTop from "./components/pages/ScrolToTop";
import CartContextProvider from "./store/CartContextProvider";
import AddressContextProvider from "./store/AddressContextProvider";
import AppContextProvider from "./store/AppContextProvider";
import AuthContextProviderV2 from "./store/AuthContextProviderV2";
import CheckoutContextProvider from "./components/Checkout/store/CheckoutContextProvider";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrolToTop />
      <AppContextProvider>
        <AuthContextProviderV2>

          <AddressContextProvider>
            <CartContextProvider>
              <CheckoutContextProvider>
                <App />
              </CheckoutContextProvider>
            </CartContextProvider>
          </AddressContextProvider>

        </AuthContextProviderV2>
      </AppContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
reportWebVitals();
