import { getLazzPolliSliders } from "../../../../Service/DataService";
import { filterImagesByViewPort } from "../../../../shared/image.util";
import Slider from "../../../utilities/Slider";
import ShowCaseGalleryTemplate from "./ShowCaseGalleryTemplate";

const ShowCasegallery = () => {
  const options = {
    rewind: true,
    type: "loop",
    autoplay: true,
    rewindSpeed: 1000,
    speed: 500,
    pauseOnHover: false,
    width: "100%",
  };

  const sliders = getLazzPolliSliders();

  const filteredSliders = filterImagesByViewPort(sliders);

  return (
    <div id="content-wrap">
      <div class="container-fluid">
        <div class="common-heading lazzPollyHeading">
          <h1>Lazz Polli Resort</h1>
        </div>
        <div class="row">
        <Slider
            Template={ShowCaseGalleryTemplate}
            data={filteredSliders}
            options={options}
          />
        </div>
      </div>
    </div>
  );
};

export default ShowCasegallery;
