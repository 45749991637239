import React from 'react';
import { Link } from 'react-router-dom';

const Item = ({ item }) => {
  const isPhoneNumber = item.link?.startsWith('tel');

  return (
    <span
      key={item.id}
      className={`category-gallery-shadow ${item.link && 'pointer'}`}
    >
      {!!!item.link && (
        <span>
          <img src={window.BaseUrl + item.image} alt={item.altrText} />
        </span>
      )}
      {!!item.link && !isPhoneNumber && (
        <Link to={item.link}>
          <img src={window.BaseUrl + item.image} alt={item.altrText} />
        </Link>
      )}
      {!!item.link && isPhoneNumber && (
        <a href={item.link}>
          <img src={window.BaseUrl + item.image} alt={item.altrText} />
        </a>
      )}
    </span>
  );
};

export default Item;
