import React from 'react';
import { createPortal } from 'react-dom';

const AuthenticationModalBody = ({
  Template,
  closeModal,
  closeLoginModalhandler,
  isOrderNowPressed,
  forgotPassPressed,
  reviewPressed,
  pressUploadPrescription,
  onSuccessed = () => {},
}) => {
  const alertEl = document.querySelector('#alert');
  return (
    <>
      {createPortal(
        <div id='demo-modal' class='modal'>
          <div class='modal__content new_modal__content'>
            <Template
              onSuccessed={onSuccessed}
              closeModal={closeModal}
              closeLoginModalhandler={closeLoginModalhandler}
              isOrderNowPressed={isOrderNowPressed}
              forgotPassPressed={forgotPassPressed}
              reviewPressed={reviewPressed}
              pressUploadPrescription={pressUploadPrescription}
            />
            <a href class='modal__close' onClick={closeModal}>
              &times;
            </a>
          </div>
        </div>,
        alertEl
      )}
    </>
  );
};

export default AuthenticationModalBody;
