export const addressType = {
  Home: 0,
  Office: 1,
  HomeTown: 2,
  Recent: 3,
};

export const OrderStatus = {
  Pending: 'Pending',
  Confirmed: 'Confirmed',
  Processing: 'Processing',
  Delivering: 'Delivering',
  Delivered: 'Delivered',
  Cancelled: 'Cancelled',
};
