import React from 'react';
import AutoOrder from './AutoOrder/AutoOrder';
import Order from './Order/Order';
import styles from './Action.module.css';

const Action = ({setLoading}) => {
    return (
        <div className={styles.action}>
            <AutoOrder />
            <Order setLoading={setLoading}/>
        </div>
    );
};

export default Action;