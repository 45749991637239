import { Link } from 'react-router-dom';
import { getCategories } from '../../../../../Service/DataService';
import {
  imageURL,
  IMAGE_OF,
  IMAGE_SIZE,
} from '../../../../../Service/imageResolver';
import Card from '../../../../Shared/Card/Card';
import styles from './Category.module.css';

const Category = () => {
  const categories = getCategories();

  return (
    <div className={styles.category}>
      {categories.map((category) => (
        <Link to={'/Category/' + category.permalink}>
          <Card
            key={category.id}
            className={`cursor-pointer background-white transition-200 flex align-center gap-8 justify-start hover-background-secondary transition-200 ${styles.catgory__item}`}
          >
            <div className='w-24'>
              <img
                src={imageURL(category.image, IMAGE_OF.BANNER, IMAGE_SIZE.ICON)}
                alt={category.name}
                className='w-max'
              />
            </div>
            <p className='text-gray text-14 transition-200'>{category.name}</p>
          </Card>
        </Link>
      ))}
    </div>
  );
};

export default Category;
