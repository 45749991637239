import React from 'react';
import { Link } from 'react-router-dom';
import {
  urlCarrerRoute,
  urlContactRoute,
  urlHowToOrderRoute,
  urlPrescriptionHistory,
  urlPrivacyPolicy,
  urlRequestOrderRoute,
  urlReturnPolicy,
  urlReviewRoute,
  urlSpecialOfferRoute,
  urlTermsConditionRoute,
} from '../../Service/UrlService';
import SocialGroup from '../SocialGroup/SocialGroup';
import Trademark from './Trademark/Trademark';

const css = { color: 'white' };



const FooterParent = () => {
  return (
    <>
    <footer id='footer' className='p-16'>
      <div>
        <div id='introduce-box' className='parentFooter'>
          <div className='footerSingle'>
            <div id='address-box'>
              <a href='https://www.lazzpharma.com/#'>
                <img
                  src='/Contents/assets/image/logo.png'
                  alt='logo'
                  style={{
                    'box-shadow': '0 2px 7px 0px #3a3a3a',
                    background: 'white',
                  }}
                />
              </a>
              <div id='address-list'>
                <div className='tit-name'>Address:</div>
                <div className='tit-contain' style={css}>
                  Lazz Center, 63/C, Lake Circus, Kalabagan, West Panthapath,
                  Dhaka.
                </div>
                <div className='tit-name'>Mobile:</div>
                <div className='tit-contain'>
                  <a href='tel:+8801886886041' style={css}>
                  +8801886886041,
                  </a>
                  <a href='tel:+8801319864049' style={css}>
                    +8801319864049
                  </a>
                </div>
                <div className='tit-name'>Email:</div>
                <div className='tit-contain' style={css}>
                  <a
                    className='new-phon'
                    href='mailto:lazzcorporate@gmail.com'
                    style={css}
                  >
                    lazzcorporate@gmail.com
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className='footerSingle'>
            <div className='custom-bonik'>
              <div className='linksFooter1'>
                <div className='introduce-title'>For Customer</div>
                <ul id='introduce-company' className='introduce-list'>
                  <li>
                    <i className='fa fa-angle-double-right'></i>
                    <Link to={urlRequestOrderRoute()} style={css}>
                      Request Order
                    </Link>
                  </li>

                  <li>
                    <i className='fa fa-angle-double-right'></i>
                    <Link to={urlPrescriptionHistory()} style={css}>
                      Upload Prescription
                    </Link>
                  </li>
                  <li>
                    <i className='fa fa-angle-double-right'></i>
                    <Link to={urlReviewRoute()} style={css}>
                      Site Review
                    </Link>
                  </li>
                  <li>
                    <i className='fa fa-angle-double-right'></i>
                    <Link to={urlSpecialOfferRoute()} style={css}>
                      Special Offers
                    </Link>
                  </li>
                  <li>
                    <i className='fa fa-angle-double-right'></i>
                    <Link to={urlHowToOrderRoute()} style={css}>
                      How to Order
                    </Link>
                  </li>
                </ul>
              </div>
              <div className='linksFooter2'>
                <div className='introduce-title'>Support</div>
                <ul id='introduce-support' className='introduce-list'>
                  <li>
                    <i className='fa fa-angle-double-right'></i>
                    <Link to={urlCarrerRoute()} style={css}>
                      Carrer
                    </Link>
                  </li>
                  <li>
                    <i className='fa fa-angle-double-right'></i>
                    <Link to={urlContactRoute()} style={css}>
                      Contact Us
                    </Link>
                  </li>
                  <li>
                    <i className='fa fa-angle-double-right'></i>
                    <Link to={urlReturnPolicy()} style={css}>
                      Return Policy
                    </Link>
                  </li>
                  <li>
                    <i className='fa fa-angle-double-right'></i>
                    <Link to={urlTermsConditionRoute()} style={css}>
                      Terms &amp; Conditions
                    </Link>
                  </li>
                  <li>
                    <i className='fa fa-angle-double-right'></i>
                    <Link to={urlPrivacyPolicy()} style={css}>
                      Privacy Policy
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className='footerSingle custom-content-box'>
            <div id='contact-box'>
              <div className='introduce-title'>Let's Socialize</div>
              <div className='social-link'>
                <a href='https://www.facebook.com/lazzpharma'>
                  <i className='fa fa-facebook'></i>
                </a>
                <a href='https://twitter.com/PharmaLazz93183'>
                  <i className='fa fa-twitter'></i>
                </a>
                {/* <a href='https://www.instagram.com/lazzpharma.online/'>
                  <i className='fa fa-instagram'></i>
                </a> */}
                <a href='https://www.linkedin.com/in/lazz-pharma-online-3a9a3727a/'>
                  <i className='fa fa-linkedin'></i>
                </a>
              </div>
            </div>
            <div className='develop_by'>
              <li id='payment-methods'>Accepted Payment Methods</li>
              <li>
                <a href='https://www.lazzpharma.com/#'>
                  <img src='/Contents/assets/image/card4.png' alt='Lazz' />
                </a>
              </li>

              <li>
                <a href='https://www.lazzpharma.com/#'>
                  <img src='/Contents/assets/image/card4.png' alt='Lazz' />
                </a>
              </li>
              <li>
                <a href='https://www.lazzpharma.com/#'>
                  <img src='/Contents/assets/image/card7.png' alt='Lazz' />
                </a>
              </li>
            </div>
          </div>
        </div>
      </div>
      <Trademark />
    </footer>
    <SocialGroup />
    </>
  );
};

export default FooterParent;
