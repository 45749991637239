import Categories from './Categories/Categories';
import Links from './Links/Links';
import MobileNav from './MobileNav/MobileNav';
import styles from './Navbar.module.css';

const Navbar = () => {
  return (
    <>
      <div className={styles.navbar}>
        <Categories />
        <Links />
        <div></div>
      </div>
      <MobileNav />
    </>
  );
};

export default Navbar;
