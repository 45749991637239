import {useState, useEffect, useContext} from 'react';
import { GET_CASHBACK_OFFERS } from '../../../lib/endpoints';
import { postV2 } from '../../../Service/http-service-v2';
import authContextV2 from '../../../store/auth-context-v2';
import cartContext from '../../../store/cart-context';
import Card from '../../Shared/Card/Card';
import checkoutContext from '../store/checkout-context';
import styles from './Payment.module.css';

const Payment = () => {
  const { getCartModel: cart } = useContext(cartContext);
  const { profile: {credit}, isAuthenticated } = useContext(authContextV2);
  const { coupon, storeCashback, storeFineCash, fineCash, charge } = useContext(checkoutContext);

  const [offers, setOffers] = useState([]);

  // const displayDeliveryCharge = () => {

  // }

  const getOffers = () => {
    postV2({ url: GET_CASHBACK_OFFERS })
      .then((data) => {
        if (!data.IsError && Array.isArray(data.Data)) {
          const cashbackOffers = [...data.Data]
            .filter((o) => o.OfferType === 'Cashback')
            .sort((a, b) => a.LeastAmount - b.LeastAmount);

          setOffers(cashbackOffers);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  useEffect(() => {
    getOffers();
  }, []);

  useEffect(() => {
    const offer = offers.find((o) => o.LeastAmount <= cart.TotalAmmount);
    storeCashback(offer?.Discount || 0);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart, offers]);

  useEffect(()=>{
    if(isAuthenticated){
      const walletCash = cart.TotalAmmount <= credit ? cart.TotalAmmount : credit;
      storeFineCash(walletCash);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [credit, cart, isAuthenticated]);

  return (
    <Card>
      <table className={styles.payment}>
        <tbody>
          <tr className={styles.payment__row}>
            <td>Amount (tax incl.)</td>
            <td>{cart.TotalAmmount.toFixed(2)}</td>
            <td>TK</td>
          </tr>
          {coupon.id && (
            <tr className={styles.payment__row}>
              <td>Cupon Discount</td>
              <td>-{coupon.amount?.toFixed(2)}</td>
              <td>TK</td>
            </tr>
          )}
          {!!credit && (
            <tr className={styles.payment__row}>
              <td>From Account</td>
              <td>-{fineCash}</td>
              <td>TK</td>
            </tr>
          )}
          <tr className={styles.payment__row}>
            <td>Delivery Charge</td>
            <td>{+charge}</td>
            <td>TK</td>
          </tr>

          <tr className={styles.payment__row}>
            <td>Total Amount</td>
            <td>
              {(cart.TotalAmmount - coupon.amount + +charge - (fineCash || 0)).toFixed(
                2
              )}
            </td>
            <td>TK</td>
          </tr>
        </tbody>
      </table>
    </Card>
  );
};

export default Payment;
