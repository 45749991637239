import { humanizeShortDateTime } from "../../../lib/utilities";

export const printInvoice = (order, user, products) => {
    localStorage.setItem(
      "Invoice",
      JSON.stringify({
        orderNo: order.OrderNo,
        userName: order.ComtactName,
        phone: user.phone,
        orderDate: humanizeShortDateTime(order.CreatedAt),
        today: humanizeShortDateTime(`/Date(${new Date().getTime()})/`),
        products: products,
        subTotal: order.TotalAmount,
        payable: order.PayableAmount,
        couponDiscount: order.CouponDiscount,
        deliveryChange: order.ShippingCharge,
        address: {
          contactName: order.ComtactName,
          phone: order.ComtactPhone,
          email: order.ComtactEmail,
          text: order.Remarks,
          upazila: order.Upazila,
          district: order.District,
          division: order.Province,
        },
      })
    );
    window.open("/invoice.html", "_blank");
  };