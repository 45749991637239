import { Link } from 'react-router-dom';
import styles from './Logo.module.css';

const Logo = () => {
  return (
    <Link to='/' className={styles.logo}>
      <img alt='Lazz Pharma Limited' src='/Contents/assets/image/logo.png' className='w-max'/>
    </Link>
  );
};

export default Logo;
