import React, { useCallback, useEffect, useState } from "react";
import { useContext } from "react";
import { SEARCH_PRODUCT } from "../../lib/endpoints";
import { tranformQuery } from "../../lib/utilities";
import { postV2 } from "../../Service/http-service-v2";
import { http } from "../../Service/httpService";
import appContext from "../../store/app-context";
import SearchPortal from "./SearchPortal";

const SearchProduct = ({ searchValue, closeSearchHandler, onProductClick }) => {
  const appCtx = useContext(appContext);

  const [searchedProduct, setSearchProduct] = useState([]);
  const [inputTimeout, setInputTimeout] = useState(null);
  const [isGetting, setIsGetting] = useState(false);

  const getSearchProducts = useCallback((searchQuery) => {
    if (searchQuery.trim().length === 0) {
      setIsGetting(false);
      return false;
    }

    setIsGetting(true);
    appCtx.searchQuery.storeSearchQuery(searchQuery);

    const payload = {
      PageNumber: 1,
      PageSize: 50,
      Query: tranformQuery(searchQuery),
    }

    postV2({url: SEARCH_PRODUCT, payload})
    .then(data => {
        if(!data.Data.IsError){
          setIsGetting(false);
          setSearchProduct(data.Data);
        } else {
          console.log(data);
        }
    })
    .catch((err) => {
      console.log(err);
    });
  }, []);

  const searchHandler = useCallback(
    (searchQuery) => {
      setIsGetting(true);
      if (inputTimeout) clearTimeout(inputTimeout);

      setInputTimeout(
        setTimeout(() => {
          getSearchProducts(searchQuery);
        }, 300)
      );
    },
    [getSearchProducts]
  );

  useEffect(() => {
    searchHandler(searchValue);
  }, [searchHandler, searchValue]);

  useEffect(() => () => clearTimeout(inputTimeout), [inputTimeout]);

  const lowerSearchvalue = searchValue.toLowerCase();
  const data =
    searchedProduct.length > 5 ? searchedProduct.slice(0, 5) : searchedProduct;

  return (
    <>
      {!appCtx.searchQuery.isLocked && <SearchPortal
      data={data}
      closeSearchHandler={closeSearchHandler}
      lowerSearchvalue={lowerSearchvalue}
      totalData={searchedProduct}
      isGetting={isGetting}
      onProductClick={onProductClick}
    />}
    </>
  );
};

export default SearchProduct;
