import React, { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "../../../Service/httpService";
import authContextV2 from "../../../store/auth-context-v2";
import cartContext from "../../../store/cart-context";
import AlertPopUp from "../../Checkout_Deprecitated/OrderAlert/AlertPopUp";
import Suspense from "../../Suspense/Suspense";

const RefilOrderAlert = ({
  closeModal,
  orderDetails,
  prescriptions,
  currentInfo,
}) => {
  const {user} = useContext(authContextV2);
  const [isLoading, setIsLoading] = useState(true);
  const [successOrderAlert, setSuccessOrderAlert] = useState(null);
  const cartCtx = useContext(cartContext);
  const history = useHistory();
  let subtotal = 0;
  const orderCreateHandler = () => {
    cartCtx.clearCart();
    
    orderDetails.Products?.forEach((p) => {
      const curP = currentInfo.find(cp => cp.Id === p.ProductId);

      const product = {
        id: curP.Id,
        Nm: p.Name,
        St: p.Strength,
        MRP: curP.UnitSalePrice,
        discountPercent: curP.DiscountPercent,
        category_id: '',
        subCategory_id: '',
        Stk: curP.Stock,
        image: p.Image,
        GN: p.GenericName,
        Ct: p.Category,
        Sp: p.Supplier,
        discount: curP.Discount,
        quantity: 1
      };

      cartCtx.storeCartItems(product);

      console.log({product});
    });

    

    history.push('/Checkout');
  };

  const closeModalOrder = () => {
    setSuccessOrderAlert(null);
    closeModal();
  };

  const updateCurrentInfoProducts = (item) => {
    const findItem = currentInfo?.find((item2) => item2.Id === item.ProductId);
    if (findItem?.Discount > 0) {
      findItem.UnitSalePrice -= findItem.Discount;
    }
    item.UnitPrice = findItem?.UnitSalePrice;
    item.PayableAmount = item.UnitPrice * item.Quantity;
    item.TotalAmount = item.UnitPrice * item.Quantity;
    subtotal += item.TotalAmount;
    return item;
  };

  useEffect(() => {
    if (
      (currentInfo.length > 0 &&
        orderDetails !== undefined &&
        prescriptions.length > 0) ||
      (currentInfo.length > 0 &&
        orderDetails !== undefined &&
        prescriptions.length === 0)
    ) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [currentInfo.length, orderDetails, prescriptions.length]);

  return (
    <>
      {!successOrderAlert && (
        <div id="pop-up">
          <div class="overlay__popup show">
            <div class="popup undefined">
              <div class="popup__title">
                <h2>Order Summarize</h2>
                <div
                  style={{
                    color: "white",
                    fontSize: "1.5rem",
                    cursor: "pointer",
                  }}
                  onClick={closeModal}
                >
                  ✖
                </div>
              </div>

              <div
                class="popup__body"
                style={{
                  maxHeight: "400px",
                  overflowX: "hidden",
                  overflowY: "scroll",
                  minHeight: "150px",
                }}
              >
                {!isLoading && (
                  <>
                    {prescriptions.length > 0 && (
                      <p style={{ fontWeight: "bold", padding: "2px" }}>
                        Prescriptions Found
                      </p>
                    )}
                    {prescriptions.length > 0 && (
                      <div className="prescription_card_orderDetails">
                        <div className="image_preview_container">
                          <>
                            <div className="image_previewer">
                              {/* single item */}
                              {prescriptions.map((file) => (
                                <div className="image_prev">
                                  <img
                                    src={BASE_URL + file.FilePath}
                                    alt="img"
                                    srcset=""
                                    style={{ height: "100%" }}
                                  />
                                </div>
                              ))}
                            </div>
                          </>
                        </div>
                      </div>
                    )}
                    <p style={{ fontWeight: "bold" }}>Shipping Address</p>
                    <div class="shaping-address-saveing-row">
                      <div class="shapping-address-inner-content">
                        <div class="saving-address-content">
                          <small>{orderDetails?.Order.ComtactName}</small>
                          <small>{orderDetails?.Order.ComtactPhone}</small>
                          <span>
                            <aside>{orderDetails?.Order.AddressType}</aside>
                          </span>
                          <span>{orderDetails?.Order.ComtactEmail} &nbsp;</span>
                          <span>
                            {" "}
                            {orderDetails?.Order.Remarks}&nbsp;
                            {orderDetails?.Order.Upazila}
                            &nbsp; {orderDetails?.Order.District}&nbsp;
                            {orderDetails?.Order.Province}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="custom-table-row">
                      <p style={{ fontWeight: "bold" }}>Products</p>
                      <div class="span12  invoice-body">
                        <table class="table table-bordered">
                          <thead>
                            <tr>
                              <th>sl</th>
                              <th>Product</th>
                              <th>Prescription</th>
                              <th>Price</th>
                              <th>Quantity</th>
                              <th>Discount</th>
                              <th>Amount</th>
                            </tr>
                          </thead>
                          <tbody style={{textAlign:"center"}}>
                            {orderDetails?.Products.map((item, index) => {
                              const updatedInfoProduct =
                                updateCurrentInfoProducts(item);
                              return (
                                <tr>
                                  <td>0{index + 1}</td>
                                  <td>{item?.Name}</td>
                                  <td>
                                    {currentInfo[index]
                                      ?.IsPrescriptionRequired === false
                                      ? "Not Required"
                                      : "Required"}
                                  </td>
                                  <td>{updatedInfoProduct.UnitPrice}</td>
                                  <td>{updatedInfoProduct.Quantity}</td>
                                  <td>{updatedInfoProduct.Discount}</td>
                                  <td>{updatedInfoProduct.TotalAmount}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div class="sum-table-for-invoice">
                        <table class="table table-bordered small-table-sum">
                          <tbody>
                            <tr>
                              <td>SubTotal</td>
                              <td class="SubTotal-tab">
                                <span>{subtotal?.toFixed(2)}</span>
                              </td>
                            </tr>
                            {/* <tr>
                              <td>Coupon Discount</td>
                              <td class="SubTotal-tab">
                                <span>
                                  {orderDetails?.Order.CouponDiscount}
                                </span>
                              </td>
                            </tr> */}
                            <tr>
                              <td>Delivery charge</td>
                              <td class="SubTotal-tab">
                                <span>
                                  {orderDetails?.Order.ShippingCharge?.toFixed(2)}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>Amount Payable</strong>
                              </td>
                              <td class="SubTotal-tab">
                                <strong>
                                  {(subtotal +
                                    orderDetails?.Order.ShippingCharge).toFixed(2)}
                                </strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="confirmation-buttons">
                      <div
                        className="confirmation-button order"
                        onClick={orderCreateHandler}
                      >
                        Order
                      </div>
                      <div
                        className="confirmation-button cancle"
                        onClick={closeModal}
                      >
                        Cancle
                      </div>
                    </div>
                  </>
                )}
                {isLoading && <Suspense />}
              </div>
            </div>
          </div>
        </div>
      )}
      {successOrderAlert && (
        <AlertPopUp
          alertStateChangedHandler={closeModalOrder}
          mobile={user.phone}
          order={successOrderAlert}
        />
      )}
    </>
  );
};

export default RefilOrderAlert;

/*
Ct: "Syrup"
GN: "CHLORAMPHENIRAMINE MALEATE"
MRP: 21.85
Nm: "HISTACIN "
Sp: "JAYSON PHARMACEUTICALS LTD"
St: "100 ML"
Stk: 15
category_id: "05554363-1502-48cc-90f5-96f2ffff41e2"
discount: 0
discountPercent: 0
id: "f05034e9-f1b8-47cf-a13b-6b567212bea4"
image: "f2fca71d-4282-4a65-9fc9-ae680db0d9db.jpg"
quantity: 1
subCategory_id: "7f813daa-27fb-4019-8610-4bdf73779452"

Ct: "Suspension "
GN: "LACTULOSE"
MRP: 140
Nm: "AVOLAC"
Sp: "ARISTOPHARMA LTD"
St: "100ML"
Stk: undefined
category_id: ""
discount: 0
discountPercent: 0
id: "c60ccada-cd9b-4b6f-9a9a-a513d7d7b8b9"
image: null
quantity: 1
subCategory_id: ""

*/
