import { Link } from 'react-router-dom';
import { searchItemsConvertObject } from '../../lib/utilities';
import { urlProductDetails } from '../../Service/UrlService';
import Action from '../Shared/ProductCard/Action/Action';
import ProductImage from '../Shared/ProductCard/ProductImage/ProductImage';

const SearchTemplate = ({ item, closeSearch, lowerSearchvalue, setalert, onProductClick }) => {
  item = searchItemsConvertObject(item, 'SEARCHED');

  const getHTML = () => {
    return {
      __html: item.Nm.toLowerCase().replace(
        lowerSearchvalue,
        `<span class="t-pink">${lowerSearchvalue}</span>`
      ),
    };
  };

  return (
    <div class='search-result__items'>
      <div class='result-card'>
        <ProductImage
          className={'result-card__img'}
          name={item.Nm}
          url={item.image}
        />
        <div class='result-card__details'>
          <Link
            to={urlProductDetails() + item.permalink}
            class='result-card__details--name text-14'
            onClick={() => {
              closeSearch();
              onProductClick();
            }}
          >
            <span dangerouslySetInnerHTML={getHTML()}></span>
            <span>
              &nbsp;{item.St}&nbsp;{item.Ct}
            </span>
            <div style={{ fontWeight: '400' }}>
              <span>{item.GN}</span>
            </div>
            {/* <div style={{ fontWeight: '400' }}>
              <span>{item.Sp}</span>
            </div> */}
          </Link>
          <p class='result-card__details--price'>
            {item.discount > 0 ? (
              <span class='current'>
                ৳{(item.MRP - item.discount).toFixed(2)}
              </span>
            ) : (
              <span class='current'>৳{item.MRP}</span>
            )}

            {item.discount > 0 ? (
              <span class='original'>
                <del class='cross_price'>৳ {item.MRP}</del>
              </span>
            ) : (
              ''
            )}
          </p>
          <Link
            to={'/category/' + item.category_id}
            class='result-card__details--category'
            href
            onClick={closeSearch}
          ></Link>
        </div>
        <div className='d-flex mobile-action'>
          <Action product={item} />
        </div>
      </div>
    </div>
  );
};

export default SearchTemplate;
