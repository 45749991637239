import styles from './PaymentOptions.module.css';
import PaymentOption from './PaymentOption/PaymentOption';
import { useContext } from 'react';
import cartContext from '../../../store/cart-context';
import { PAYMENT_OPTIONS } from '../checkout.util';
import checkoutContext from '../store/checkout-context';
import { useEffect } from 'react';

const PaymentOptions = () => {
  const { getCartModel: cart } = useContext(cartContext);
  const {
    coupon,
    charge,
    fineCash,
    shipping: { districtId },
    storePaymentOption,
    paymentOption
  } = useContext(checkoutContext);

  useEffect(()=>{
    if(districtId === 95)
    storePaymentOption(PAYMENT_OPTIONS.HUNDRED_PERCENT);
    else storePaymentOption(PAYMENT_OPTIONS.HUNDRED_PERCENT);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [districtId])

  const payable = cart.TotalAmmount - coupon.amount + +charge - (fineCash || 0);
  return (
    <div className={styles['payment-options']}>
      {/* <PaymentOption
        onSelect={onSelect}
        title={`Pay ${Math.ceil(payable * 0.2).toFixed(2)}TK (20%)`}
        uniqueName={PAYMENT_OPTIONS.TWENTY_PERCENT}
        value={value}
      /> */}


{/*
      <PaymentOption
        title={`Pay ${Math.ceil(payable).toFixed(2)}tk`}
        uniqueName={PAYMENT_OPTIONS.HUNDRED_PERCENT}
      />
      {districtId === 95 && (
        <PaymentOption
          title={'Cash On Delivery'}
          uniqueName={PAYMENT_OPTIONS.PAY_LATER}
        />
      )}
      */}

<PaymentOption
          title={'Cash On Delivery'}
          uniqueName={PAYMENT_OPTIONS.PAY_LATER}
        />
        
    </div>
  );
};

export default PaymentOptions;
