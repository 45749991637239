import React, { useCallback, useEffect, useState } from "react";
import  Suspense  from "../Suspense/Suspense";
import { GET_GALLERY } from "../../lib/endpoints";
import { paramsUrlGenerator } from "../../lib/utilities";
import { http } from "../../Service/httpService";
import { imageURL, IMAGE_OF, IMAGE_SIZE } from "../../Service/imageResolver";

const GalleryBodyTemplate = () => {
  const [isGetting, setIsGetting] = useState(false);
  const [images, setImages] = useState({
    items: [],
    totalCount: 0,
    count: 0,
  });
  const [params, setParams] = useState({
    isDescending: false,
    pageNumber: 1,
    pageSize: 5,
  });

  const getGallary = useCallback((params) => {
    http.get({
      url: GET_GALLERY + params,
      before: () => {
        setIsGetting(true);
      },
      successed: (res) => {
        setImages({
          items: res.Data.Data,
          totalCount: res.Data.Total,
          count: res.Data.Data?.length ?? 0,
        });
      },
      failed: () => {
        setIsGetting(false);
      },
      always: () => {
        setIsGetting(false);
      },
    });
  }, []);

  useEffect(() => {
    const paramsUrl = paramsUrlGenerator(params);
    getGallary(paramsUrl);
  }, [params, getGallary]);

  if(isGetting){
    return <Suspense></Suspense>
  }

  return (
    <div class="container section">
      <div class="row">
        <div align="center" className="flex">
          {images.items?.map((image) => (
            <div key={image.Id} class="gallery_product filter hdpe">
              <img
                src={imageURL(image.ImagePath, IMAGE_OF.GALLERY, IMAGE_SIZE.ORIGINAL)}
                class="img-responsive gallery_image"
                alt={image.Name}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GalleryBodyTemplate;
