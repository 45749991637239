import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import AttachPrescriptionAlert from '../PrescriptionAlert/AttachPrescriptionAlert';
import UploadPrescriptionAlert from '../PrescriptionAlert/UploadPrescriptionAlert';
import {
  urlOrderDetailsRoute,
  urlProfileRoute,
} from '../../../Service/UrlService';
import { humanizeShortDateTime } from '../../../lib/utilities';
import RefilOrderAlert from '../PrescriptionAlert/RefilOrderAlert';
import { http } from '../../../Service/httpService';
import {
  GET_CURRENT_INFO,
  GET_ORDER_BY_PRESCRIPTIONS,
  GET_ORDER_DETAILS,
} from '../../../lib/endpoints';
import Chips from '../../Lib/Chips/Chips';
import { OrderStatus } from '../../utilities/dictionaries';

const OrderCard = ({ order }) => {
  console.log({ order });
  let created_at = new Date(order.CreatedAt);
  let history = useHistory();
  const [UploadPresAlert, setUploadPresAlert] = useState(false);
  const [attachPres, setAttachPres] = useState(false);
  const [refilOrder, setRefilOrder] = useState(false);
  const [orderDetails, setOrderDetails] = useState();
  const [prescriptions, setPrescriptions] = useState([]);
  const [currentInfo, setCurrentInfo] = useState([]);
  const products = [];
  const closePrescriptionModal = () => {
    setUploadPresAlert((prevState) => !prevState);
  };
  const closeAttachPreshandler = () => {
    setAttachPres((prevState) => !prevState);
  };
  const uploadPrescriptionHandler = (e) => {
    e.stopPropagation();
    setUploadPresAlert((prevState) => !prevState);
  };
  const attachedPrescriptionHandler = (e) => {
    e.stopPropagation();
    setAttachPres((prevState) => !prevState);
  };
  const refilOrderHandler = (orderId, e) => {
    e.stopPropagation();

    setRefilOrder((prevState) => !prevState);
    http.get({
      url: GET_ORDER_DETAILS + orderId,
      before: () => {},
      successed: (res) => {
        setOrderDetails(res.Data);
        res.Data.Products.map(function (element) {
          return products.push(element.ProductId);
        });
        getPrescriptionsByOrder(orderId);
        getCurrentInfo();
        // setIsLoading(false);
      },
      failed: () => {
        // setIsLoading(true);
      },
      always: () => {
        // setIsLoading(false);
      },
    });
  };
  const getPrescriptionsByOrder = useCallback((id) => {
    http.get({
      url: GET_ORDER_BY_PRESCRIPTIONS + id,
      before: () => {},
      successed: (res) => {
        setPrescriptions(res.Data);
      },
      failed: () => {},
      always: () => {},
    });
  }, []);
  const getCurrentInfo = useCallback(() => {
    http.post({
      url: GET_CURRENT_INFO,
      payload: {
        productIds: products,
      },
      before: () => {},
      successed: (res) => {
        setCurrentInfo(res.Data);
      },
      failed: () => {},
      always: () => {},
    });
  }, [products]);
  const clickedToViewOrder = (e) => {
    e.stopPropagation();
    history.push(urlProfileRoute() + urlOrderDetailsRoute() + order.Id);
  };

  const goto = (id) => {
    history.push(urlProfileRoute() + urlOrderDetailsRoute() + id);
  };

  const closeRefilAlertHandler = () => {
    setRefilOrder((prevState) => !prevState);
  };

  const chipType = (curStatus) => {
    curStatus = curStatus?.toLowerCase();
    let chips;
    switch (curStatus) {
      case OrderStatus.Pending.toLowerCase():
        chips = 'hard';
        break;
      case OrderStatus.Confirmed.toLowerCase():
        chips = 'caution';
        break;
      case OrderStatus.Processing.toLowerCase():
        chips = 'info';
        break;
      case OrderStatus.Delivering.toLowerCase():
        chips = 'positive';
        break;
      case OrderStatus.Delivered.toLowerCase():
        chips = 'primary';
        break;
      case OrderStatus.Cancelled.toLowerCase():
        chips = 'warn';
        break;
      default:
        break;
    }
    return chips;
  };

  return (
    <>
      <div id='Tab6' class='tabcontent tab-content detalis-page-tab-content'>
        {/* <!-- product desc review information --> */}
        <div class='profile-order-tab profile-order-tab-new'>
          <a href onClick={goto.bind(null, order.Id)}>
            <div class='order-id display-flex jc-s-b'>
              <span> Order ID #{order.OrderNo}</span>
              <ul className='group-of-buttons'>
                <li className='prescription-mobile-hide'>
                  <button
                    className='button-order'
                    onClick={uploadPrescriptionHandler}
                  >
                    <div style={{color: 'white'}}>Upload Prescription </div>
                  </button>
                </li>
                <li className='prescription-mobile-hide'>
                  <div
                    className='button-order'
                    onClick={attachedPrescriptionHandler}
                  >
                    <div style={{color: 'white'}}>Attach Prescription </div>
                  </div>
                </li>
                <li>
                  <div
                    className='button-order'
                    onClick={refilOrderHandler.bind(null, order.Id)}
                  >
                    <div style={{color: 'white'}}>Refil Order</div>
                  </div>
                </li>
                <li>
                  <div className='button-order' onClick={clickedToViewOrder}>
                    <div style={{color: 'white'}}>View Details</div>
                  </div>
                </li>
              </ul>
            </div>


          <div className='profile-order-flex'>
            <div className='profile-order-left'>
            <div class='order-dsc display-flex jc-s-b'>
              <span> Date :</span>
              <aside>{humanizeShortDateTime(order.CreatedAt)}</aside>
            </div>
            <div class='order-dsc display-flex jc-s-b'>
              <span> Status :</span>
              <Chips type={chipType(order.Status)}>{order.Status}</Chips>
            </div>
            </div>

            <div className='profile-order-right'>
            <div class='order-dsc display-flex jc-s-b'>
              <span> Payable Amount :</span>
              <aside>{order?.PayableAmount.toFixed(2)} tk</aside>
            </div>
            <div class='order-dsc display-flex jc-s-b'>
              <span className='shipping-hide'>Shipping Address :</span>
              <aside>
                {order.Remarks}, {order.Upazila}, {order.District},{' '}
                {order.Province}
              </aside>
            </div>
            </div>
            </div>
          </a>
        </div>
      </div>
      {UploadPresAlert && (
        <UploadPrescriptionAlert
          orderId={order.Id}
          orderNumber={order.OrderNo}
          closeModal={closePrescriptionModal}
        />
      )}
      {attachPres && (
        <AttachPrescriptionAlert
          closeModal={closeAttachPreshandler}
          orderId={order.Id}
        />
      )}
      {refilOrder && (
        <RefilOrderAlert
          closeModal={closeRefilAlertHandler}
          orderDetails={orderDetails}
          prescriptions={prescriptions}
          currentInfo={currentInfo}
        />
      )}
    </>
  );
};

export default OrderCard;
