import React from 'react';
import SearchProduct from '../../../SearchPortal/SearchProduct';
import searchIcon from '../../../../icons/search.svg';
import { useContext } from 'react';
import appContext from '../../../../store/app-context';
import { useRef } from 'react';
import { useState } from 'react';
import { useCloseOnOutsideClick } from '../../../../hooks/useOutSideClickedHandler';

const Search = ({styles}) => {
    const { searchQuery } = useContext(appContext);

    let searchRef = useRef(null);
    const [text, setText] = useState('');
    const [resultIsShown, setResultIsShown] = useState(false);
  
    useCloseOnOutsideClick(searchRef, () => {
        setResultIsShown(false);
    });
  
    const focusHandler = ({ target }) => {
      if (target.value?.length > 0) setResultIsShown(true);
      else setResultIsShown(false);
    };
  
    const changeHandler = ({ target }) => {
      if (target.value?.length > 0) setResultIsShown(true);
      else setResultIsShown(false);
  
      setText(target.value);
      console.log(target.value);
      searchQuery.storeSearchQuery(target.value);
    };
  
    const productClickHandler = () => {
      setResultIsShown(false);
    };
  return (
    <div className={styles.search} ref={searchRef}>
      <div className={styles.search__field}>
        <input
          type='text'
          onFocus={focusHandler}
          onChange={changeHandler}
          value={text}
        />
        <button>
          <div>
            <img src={searchIcon} alt={'Search'} className='w-max mobile-res' />
          </div>
        </button>
      </div>
      <div className={styles.search__result}>
        {resultIsShown && (
          <SearchProduct
            searchValue={text}
            closeSearchHandler={() => {}}
            onProductClick={productClickHandler}
          />
        )}
      </div>
    </div>
  );
};

export default Search;
