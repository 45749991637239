
const APP_DATA = window.appData;


export const productTypes = {
  special: 0,
  featured: 1,
};

export const getAppData = () => {
  return APP_DATA;
};

// Category and Sub Category
export const getCategories = (parentId) => {
  if (parentId) {
    return APP_DATA[1]
      .filter((a) => a[3] === parentId)
      .map((a) => ({ id: a[0], name: a[1], rank: a[2], parentId: a[3] }));
  }

  return APP_DATA[0].map((a) => ({ id: a[0], name: a[1], image: a[2], permalink: a[3] }));
};

export const getCategory = (id) => {
  return APP_DATA[0].map((a) => ({ id: a[0], name: a[1], image: a[2], permalink: a[3] })).find(c => c.id === id );
}

export const getCategoryByPermalink = (permalink) => {
  return APP_DATA[0].map((a) => ({ id: a[0], name: a[1], image: a[2], permalink: a[3] })).find(c => c.permalink === permalink );
}

export const getSubCategory = (id) => {
  return APP_DATA[1].map((a) => ({ id: a[0], name: a[1], image: a[2], parentId: a[3] })).find(c => c.id === id );
}

// Banners
export const getBanners = () => {
  return APP_DATA[2].map(a => ({ id: a[0], image: a[1], viewport: a[2], link: a[3], altrText: a[4] }));
};

// Notices
export const getNotices = () => {
  return APP_DATA[3].map((n) => n[0]);
};




// Home Category
export const getHomeCategories = () => {
  return APP_DATA[4].map(c => (
    {
      id: c[0],
      categoryName: c[1],
      products: c[2].map(p => ({
        id: p[0],
        Nm: p[1],
        St: p[2],
        MRP: p[3],
        discountPercent: p[4],
        category_id: p[5],
        subCategory_id: p[6],
        Stk: 999999, // p[8],
        image: p[9],
        GN: p[7],
        Ct: p[10],
        Sp: p[11],
        discount: p[12],
        permalink: p[13]
      }))
    }
  ));
}

// Category Slider by Category id 
export const getCategorySlider = (categoryId) => {
  const slider = APP_DATA[5].find(s => s[2] === categoryId);
  
  if(!slider) return;

  return {
    id: slider[0],
    type: slider[1],
    categoryId: slider[2],
    gap: slider[3],
    items: slider[4].map(i => ({
      id: i[0],
      image: i[1],
      link: i[3],
      viewport: i[4],
      altrText: i[5]
    }))
  };
}

// Footer Slider | Lazz Polli Slider
export const getLazzPolliSliders = () => {
  return APP_DATA[6].map(a => ({ id: a[0], image: a[1], viewport: a[2], link: a[3], altrText: a[4] }));
}


export const getMedicineTypes = () => {
  return APP_DATA[2].map((a) => ({ id: a[0], name: a[1], rank: a[2] }));
};

export const getProducts = (productType = productTypes.special) => {
  const productsFromAppData = getProductsByType(APP_DATA, productType);

  return productsFromAppData.map((p) => ({
    id: p[0],
    name: p[1],
    genericName: p[2],
    strength: p[3],
    MRP: p[4],
    Ds: p[5],
    imagePath: p[6],
    imageType: p[7],
    category: p[8],
    suplier: p[9],
    totalStock: p[10],
  }));
};





const getProductsByType = (data, productType) => {
  switch (productType) {
    case productTypes.special:
      return data[3];

    case productTypes.featured:
      return data[4];

    default:
      throw new Error("product types not specified");
  }
};
