import Page from '../Shared/Page/Page';
import BranchTable from './BranchTable/BranchTable';
import Map from './Map/Map';

const breadcrumb = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'Request Order',
    url: '/Request-Order',
  },
];

var mapModel={};


const BranchLocations = () => {
  return (
    <Page
      path={breadcrumb}
      title={'Branch Locations'}
      className='flex flex-column gap-16'
    >
      <BranchTable  mapModel={mapModel} />
      <Map mapModel={mapModel} />
    </Page>
  );
};

export default BranchLocations;
