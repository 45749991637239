import React from 'react';

const Price = ({ discount, mrp }) => {
  return (
    <div class='basket-add'>
      {discount === 0 && (
        <span class='item__price item__price--now'>৳{mrp}</span>
      )}
      {discount > 0 && (
        <>
          <span class='item__price item__price--now'>
            ৳{(mrp - discount).toFixed(2)}
          </span>
          <span class='price product-price'>
            <del class='cross_price'> ৳{mrp}</del>
          </span>
        </>
      )}
    </div>
  );
};

export default Price;
