import React, { useEffect } from 'react';
import { useCallback } from 'react';
import { useContext } from 'react';
import { useReducer } from 'react';
import { GET_LATEST_ADDRESS } from '../../../lib/endpoints';
import { postV2 } from '../../../Service/http-service-v2';
import authContextV2 from '../../../store/auth-context-v2';
import cartContext from '../../../store/cart-context';
import checkoutContext from './checkout-context';

const initialState = {
  paymentOption: null,
  coupon: { id: null, amount: 0 },
  cashback: 0,
  listOfDxId: [],
  prescriptions: [],
  isAutoOrderActive: false,
  fineCash: 0,
  charge: 0,
  shipping: {
    receiverName: null,
    receiverPhone: null,
    customerPhone: null,
    divisionId: null,
    division: null,
    districtId: null,
    district: null,
    areaId: null,
    area: null,
    address: null,

    maxAmount: null,
    minChargeAmount: null,
    chargeAmount: null,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'STORE_COUPON':
      return {
        ...state,
        coupon: { ...action.coupon },
      };

    case 'STORE_PAYABLE':
      return {
        ...state,
        payable: action.payable,
      };

    case 'STORE_PAYMENT_OPTION':
      return {
        ...state,
        paymentOption: action.paymentOption,
      };

    case 'STORE_AUTO_ORDER_STATUS':
      return {
        ...state,
        isAutoOrderActive: action.isActive,
      };

    case 'STORE_PRESCRIPTION': {
      return {
        ...state,
        prescriptions: [...state.prescriptions, action.prescription],
      };
    }

    case 'REMOVE_PRESCRIPTION': {
      const newPrescriptions = state.prescriptions.filter(
        (p) => action.id && p.id !== action.id
      );
      return {
        ...state,
        prescriptions: [...newPrescriptions],
      };
    }

    case 'UPDATE_SHIPPING_CHARGE': {
      return {
        ...state,
        charge: action.charge,
      };
    }

    case 'STORE_CASHBACK': {
      return {
        ...state,
        cashback: action.amount,
      };
    }

    case 'STORE_FINECASH': {
      return {
        ...state,
        fineCash: action.amount,
      };
    }

    case 'UPDATE_ADDRESS': {
      return {
        ...state,
        shipping: {
          ...action.address,
        },
      };
    }

    case 'CLEAR_CONTEXT': {
      const addressJSON = JSON.stringify(state.shipping);

      localStorage.setItem('address', addressJSON);

      return {
        ...state,
        paymentOption: null,
        coupon: { id: null, amount: 0 },
        cashback: 0,
        listOfDxId: [],
        isAutoOrderActive: false,
        fineCash: 0,
      };
    }

    default:
      return {
        ...state,
      };
  }
};

const CheckoutContextProvider = ({ children }) => {
  const { user, isAuthenticated, isAuthenticating } = useContext(authContextV2);
  const {
    getCartModel: { TotalAmmount: amount },
  } = useContext(cartContext);

  const [state, dispatch] = useReducer(reducer, initialState);

  const storeCoupon = (id, amount) => {
    dispatch({ type: 'STORE_COUPON', coupon: { id, amount } });
  };

  const storePaymentOption = (paymentOption) => {
    dispatch({ type: 'STORE_PAYMENT_OPTION', paymentOption });
  };

  const storeAutoOrderStatus = (isActive) => {
    dispatch({ type: 'STORE_AUTO_ORDER_STATUS', isActive });
  };

  const storePrescription = (prescription) => {
    dispatch({ type: 'STORE_PRESCRIPTION', prescription });
  };

  const removePrescription = (id) => {
    dispatch({ type: 'REMOVE_PRESCRIPTION', id });
  };

  const updateShippingCharge = (charge) => {
    dispatch({ type: 'UPDATE_SHIPPING_CHARGE', charge });
  };

  const storeCashback = (amount) => {
    dispatch({ type: 'STORE_CASHBACK', amount });
  };

  const storeFineCash = (amount) => {
    dispatch({ type: 'STORE_FINECASH', amount });
  };

  const clearContext = () => {
    dispatch({ type: 'CLEAR_CONTEXT' });
  };

  const updateAddress = ({
    receiverPhone,
    receiverName,
    customerPhone,
    divisionId,
    division,
    districtId,
    district,
    areaId,
    area,
    address,
    maxAmount,
    minChargeAmount,
    chargeAmount,
  }) => {
    dispatch({
      type: 'UPDATE_ADDRESS',
      address: {
        receiverPhone,
        receiverName,
        customerPhone,
        divisionId,
        division,
        districtId,
        district,
        areaId,
        area,
        address,
        maxAmount,
        minChargeAmount,
        chargeAmount,
      },
    });
  };

  // Private Functions
  const getAddress = useCallback(() => {
    const formIsDirty =
      state.shipping.address ||
      state.shipping.districtId ||
      state.shipping.divisionId ||
      state.shipping.areaId ||
      state.shipping.receiverName ||
      state.shipping.receiverPhone;

    if (formIsDirty) return;

    postV2({ url: GET_LATEST_ADDRESS })
      .then((data) => {
        if (!data.IsError && data.Data) {
          const charge =
            amount > data.Data.MaxAmount
              ? data.Data.MinChargeAmount
              : data.Data.ChargeAmount;

          updateAddress({
            receiverPhone: data.Data.ReceiverPhone,
            receiverName: data.Data.ReceiverName,
            customerPhone: user.phone,
            divisionId: data.Data.DivisionId,
            division: data.Data.DivisionName,
            districtId: data.Data.DistrictId,
            district: data.Data.DistrictName,
            areaId: data.Data.AreaId,
            area: data.Data.AreaName,
            address: data.Data.Address,
            chargeAmount: data.Data.ChargeAmount,
            maxAmount: data.Data.MaxAmount,
            minChargeAmount: data.Data.MinChargeAmount
          });

          updateShippingCharge(charge);
        } else if (!data.IsError && !data.Data) {
          updateAddress({
            ...state.shipping,
            receiverName: user.name,
            receiverPhone: user.phone,
            customerPhone: user.phone,
          });
        } else {
          console.log(data);
        }
      })
      .catch((err) => {
        console.log(err);
        updateAddress({
          ...state.shipping,
          receiverName: user.name,
          receiverPhone: user.phone,
          customerPhone: user.phone,
        });
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getAddressFromCache = useCallback(() => {
    const addressJSON = localStorage.getItem('address');

    let address;
    if (addressJSON) {
      try {
        address = JSON.parse(addressJSON);
        updateAddress({
          ...address,
        });
        const charge =
          amount > address.maxAmount
            ? address.minChargeAmount
            : address.chargeAmount;

        updateShippingCharge(charge);
      } catch (error) {
        console.log(error);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAuthenticated && !isAuthenticating) {
      getAddress();
    } else if (!isAuthenticated && !isAuthenticating) {
      getAddressFromCache();
    }
  }, [isAuthenticated, isAuthenticating, getAddress, getAddressFromCache]);

  useEffect(() => {
    console.log('District')
    if (!state.shipping.district) {
      updateShippingCharge(0);
    } 
  }, [state.shipping.district]);

  useEffect(() => {
  const charge =
      amount > state.shipping.maxAmount
        ? state.shipping.minChargeAmount
        : state.shipping.chargeAmount;

    updateShippingCharge(charge);

  }, [amount, state.shipping.chargeAmount, state.shipping.maxAmount, state.shipping.minChargeAmount]);

  const context = {
    coupon: state.coupon,
    isCollectingAddress: state.isCollectingAddress,
    charge: state.charge,
    shipping: state.shipping,
    paymentOption: state.paymentOption,
    isAutoOrderActive: state.isAutoOrderActive,
    cashback: state.cashback,
    finecash: state.fineCash,
    prescriptions: state.prescriptions,
    storeCoupon,
    storePaymentOption,
    storeAutoOrderStatus,
    storePrescription,
    removePrescription,
    updateShippingCharge,
    storeCashback,
    storeFineCash,
    updateAddress,
    clearContext,
  };

  return (
    <checkoutContext.Provider value={context}>
      {children}
    </checkoutContext.Provider>
  );
};

export default CheckoutContextProvider;
