import { useState } from 'react';
import ReactPaginate from 'react-paginate';
import './Paginator.css';

/**
 * * Paginator API:
 * ! v1.0
 * ? {sl}. @{props name} {{data type}}: [{is optional}] {descriptions} {default value}
 * # 1. items [X] {number}: Total items count to scaffold pages {undefined}
 * # 2. pageItems [Y] {number}: Number of items will show in a page {10}
 * # 3. startPage [Y] {number}: active page number when initialze {1}
 * # 4. onPageChange [Y] {function}: callback with one parameter current page number {null}
 *
 * todo: add documentation for css classes
 */

const Paginator = ({
  items,
  pageItems = 10,
  startPage = 1,
  onPageChange = (pageNumber) => {
    return pageNumber + 1;
  },
  className,
  current
}) => {
  const [activeIndex, setActiveIndex] = useState(current || startPage ? startPage - 1 : 0);
  const pageCount = Math.ceil(items / pageItems);

  const pageChangeHandler = ({ selected: i }) => {
    setActiveIndex(i);
    onPageChange(i + 1);
  };

  if (pageCount < 2) {
    return <></>;
  }

  return (
    <div className='paginator-pagination'>
      <ReactPaginate
        previousLabel='previous'
        nextLabel='next'
        breakLabel='...'
        breakClassName='page-item'
        pageCount={pageCount}
        pageRangeDisplayed={4}
        marginPagesDisplayed={2}
        onPageChange={pageChangeHandler}
        containerClassName='pagination'
        pageClassName='page-item'
        previousClassName='page-item'
        nextClassName='page-item'
        activeClassName='active'
        forcePage={current}
        initialPage={activeIndex}
      />
    </div>
  );
};

export default Paginator;
