import { useEffect, useContext } from 'react';
import checkoutContext from '../../store/checkout-context';
import styles from './AutoOrder.module.css';

const AutoOrder = () => {
  const { autoOrderIsActive, storeAutoOrderStatus } = useContext(checkoutContext);

  const changeHandler = ({target}) => {
    storeAutoOrderStatus(target.checked);
  }

  useEffect(()=>{
    return () => {
      storeAutoOrderStatus(false);
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.auto_order}>
      <label htmlFor='auto-order'>
        <input
          type='checkbox'
          name='autoOrder'
          id='auto-order'
          checked={autoOrderIsActive}
          onChange={changeHandler}
        />
        Auto Order After 28 Days
      </label>
    </div>
  );
};

export default AutoOrder;
