import React from 'react';
import Slider from '../../../../../utilities/Slider';
import SlideShowTemplate from './SlideShowTemplate';

const SlideShow = ({ images, onClick }) => {
  const options = {
    rewind: true,
    type: 'loop',
    autoplay: true,
    rewindSpeed: 1000,
    autoHeight: false,
    fixedHeight: false,
    speed: 500,
    pauseOnHover: false,
    width: '100%',
  };

  return (
    <div className='category-gallery-shadow'>
      <Slider
        Template={SlideShowTemplate}
        data={images}
        options={options}
        goto={onClick}
      />
    </div>
  );
};

export default SlideShow;
