import React from 'react';

const Description = ({name, strength, type, genericName, supplier, category}) => {
    return (
        <div class='product-content'>
          <div className='product-top-heading'>
            <div className='product_heading_info'>
              <h3>
                {name}&nbsp;
                {strength !== '.' && strength}&nbsp;
                {type && type}
              </h3>
              <span>
                <i>{genericName && genericName}</i>
                {/* <br />
                {supplier && supplier} */}
              </span>
            </div>
          </div>
        </div>
    );
};

export default Description;