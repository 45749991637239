import { useContext } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { GET_AREA, GET_DISTRICT, GET_PROVINCE } from '../../../lib/endpoints';
import authContextV2 from '../../../store/auth-context-v2';
import cartContext from '../../../store/cart-context';
import AutoComplete from '../../Shared/AutoComplete/AutoComplete';
import Card from '../../Shared/Card/Card';
import Input from '../../Shared/Input/Input';
import Textarea from '../../Shared/Textarea/Textarea';
import { PAYMENT_OPTIONS } from '../checkout.util';
import checkoutContext from '../store/checkout-context';
import styles from './Address.module.css';

const Address = () => {
  const { updateShippingCharge, updateAddress, shipping } =
    useContext(checkoutContext);
  const { isAuthenticated, user, isAuthenticating } = useContext(authContextV2);
  const { getCartModel: cart } = useContext(cartContext);

  const [receiverPhone, setReceiverPhone] = useState('');
  const [receiverName, setReceiverName] = useState('');
  const [customerPhone, setCustomerPhone] = useState('');
  const [division, setDivision] = useState({});
  const [district, setDistrict] = useState({});
  const [area, setArea] = useState({});
  const [address, setAddress] = useState('');

  const receiverPhoneChangeHandler = (value) => {
    setReceiverPhone(value);
  };

  const receiverNameChangeHandler = (value) => {
    setReceiverName(value);
  };

  const customerNameChangeHandler = (value) => {
    setCustomerPhone(value);
  };

  const autoCompleteMapper = useCallback((res) => {
    const transformedData = [];

    res.Data.Data[0].forEach((item) => {
      transformedData.push({
        id: item[1],
        name: item[0],
        charge: item[2],
        maxAmount: item[3],
        minChargeAmount: item[4],
      });
    });

    return transformedData;
  }, []);

  const divisionChangeHandler = (division) => {
    updateAddress({
      ...shipping,
      division: division.name,
      divisionId: division.id,
      district: null,
      districtId: null,
      areaId: null,
      area: null,
    });
    setDivision(division);
  };

  const districtChangeHandler = (district) => {
    const charge = district.id
      ? cart.TotalAmmount >= district.maxAmount
        ? district.minChargeAmount
        : district.charge
      : 0;

    updateShippingCharge(charge);
    updateAddress({
      ...shipping,
      district: district.name,
      districtId: district.id,
      maxAmount: district.maxAmount,
      chargeAmount: district.charge,
      minChargeAmount: district.minChargeAmount,
      areaId: null,
      area: null,
    });
    setDistrict(district);
  };

  const areaChangeHandler = (area) => {
    setArea(area);
    updateAddress({ ...shipping, area: area.name, areaId: area.id });
  };

  const addressChangeHandler = (address) => {
    setAddress(address);
  };

  const addressBlurHandler = (address) => {
    updateAddress({ ...shipping, address: address });
  };

  const customerPhoneBlurHandler = (phone) => {
    updateAddress({ ...shipping, customerPhone: phone });
  };

  const receiverNameBlurHandler = (name) => {
    updateAddress({ ...shipping, receiverName: name });
  };

  const receiverPhoneBlurHandler = (phone) => {
    updateAddress({ ...shipping, receiverPhone: phone });
  };

  const phoneNumberValidator = (phone = '') => {
    if (!phone) return true;

    return /(^(\+88|0088)?(01){1}[3456789]{1}(\d){8})$/.test(phone);
  };

  useEffect(() => {
    setReceiverPhone(shipping.receiverPhone);
    setReceiverName(shipping.receiverName);
    setDivision({ id: shipping.divisionId, name: shipping.division });
    setDistrict({ id: shipping.districtId, name: shipping.district });
    setArea({ id: shipping.areaId, name: shipping.area });
    setAddress(shipping.address);
  }, [shipping]);

  return (
    <Card className={styles.address}>
      <div>
        <Input
          label={'Receiver Name'}
          onChange={receiverNameChangeHandler}
          value={receiverName}
          onBlur={receiverNameBlurHandler}
        />
        <Input
          required
          label={'Receiver Phone'}
          onChange={receiverPhoneChangeHandler}
          value={receiverPhone}
          onBlur={receiverPhoneBlurHandler}
          validators={[
            {
              validator: phoneNumberValidator,
              message: 'Invalid Phone Number',
            },
          ]}
        />
        {/* {isAuthenticated && (
          <Input
            required
            label={'Customer Phone'}
            onChange={customerNameChangeHandler}
            value={customerPhone}
            disabled={isAuthenticated}
            onBlur={customerPhoneBlurHandler}
          />
        )} */}
      </div>
      <div>
        <AutoComplete
          idField={'id'}
          nameField={'name'}
          label={'Region'}
          mapper={autoCompleteMapper}
          onChange={divisionChangeHandler}
          required
          url={GET_PROVINCE}
          value={division}
          readyToLoad={true}
        />
        <AutoComplete
          idField={'id'}
          nameField={'name'}
          label={'City'}
          mapper={autoCompleteMapper}
          onChange={districtChangeHandler}
          required
          url={GET_DISTRICT}
          value={district}
          filterName={'ProvinceId'}
          filterValue={division.id}
          readyToLoad={!!division.id}
          previewText={'Please, First Select a Region'}
        />
        <AutoComplete
          idField={'id'}
          nameField={'name'}
          label={'Area'}
          mapper={autoCompleteMapper}
          onChange={areaChangeHandler}
          url={GET_AREA}
          value={area}
          filterValue={district.id}
          filterName={'DistrictId'}
          readyToLoad={!!district.id && division.id}
          others={['ProvinceId', division.id]}
          previewText={'Please, First Select a City'}
        />
      </div>
      <div>
        <Textarea
          label={'Address'}
          required
          onChange={addressChangeHandler}
          onBlur={addressBlurHandler}
          value={address}
        />
      </div>
    </Card>
  );
};

export default Address;
