import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { GET_PRODUCTS_BY_CATEGORY } from '../../../lib/endpoints';
import { gotoTop, searchItemsConvertObject } from '../../../lib/utilities';
import { getCategoryByPermalink } from '../../../Service/DataService';
import { http } from '../../../Service/httpService';
import { urlHomeRoute } from '../../../Service/UrlService';
import Paginator from '../../Paginators/Paginators';
import ProductCard from '../../Shared/ProductCard/ProductCard';
import Suspense from '../../Suspense/Suspense';

const CategoryWiseProduct = () => {
  const { permalink } = useParams();
  const [isGetting, setIsGetting] = useState(true);
  const [categoryProducts, setCategoryProducts] = useState({
    items: [],
    totalCount: 0,
    count: 0,
  });
  const [params, setParams] = useState({
    filter: [
      {
        field: 'Permalink',
        value: '',
        Operation: 0,
      },
    ],
    pageNumber: 1,
    pageSize: 24,
  });

  const pageChangeHandler = (page) => {
    setParams((prevState) => ({ ...prevState, pageNumber: page }));
  };

  const getCategoryProduct = useCallback((params) => {
    http.post({
      url: GET_PRODUCTS_BY_CATEGORY,
      payload: params,
      before: () => {
        setIsGetting(true);
        gotoTop();
      },
      successed: (res) => {
        setCategoryProducts({
          items: res.Data.Data,
          totalCount: res.Data.Total,
          count: res.Data.Data?.length ?? 0,
        });
        setIsGetting(false);
      },
      failed: () => {},
      always: () => {
        setIsGetting(false);
      },
    });
  }, []);

  useEffect(() => {
    params.filter[0].value = permalink;
    getCategoryProduct(params);
  }, [getCategoryProduct, permalink, params]);

  const category = getCategoryByPermalink(permalink);

  return (
    <>
      {(!isGetting || true) && (
        <>
          <div id='body_content'>
            <div class='columns-container'>
              <div id='columns' class='container'>
                <div class='breadcrumb clearfix'>
                  <Link class='home' title='Return to Home' to={urlHomeRoute()}>
                    Home
                  </Link>
                  <span class='navigation-pipe'> </span>
                  <a href>Category</a>
                  <span class='navigation-pipe'> </span>
                  <a href>{category?.name||''}</a>
                </div>
              </div>
            </div>
            <div class='row'>
              <div id='view-product-list' style={{ padding: '0px 20px' }}>
                <ul>
                  {categoryProducts.items.map((item) => {
                    let converItem = searchItemsConvertObject(item);
                    return (
                      <li class=''>
                        <ProductCard item={converItem} />
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>

          <Paginator
            items={categoryProducts.totalCount}
            pageItems={params.pageSize}
            startPage={params.pageNumber}
            onPageChange={pageChangeHandler}
          />
        </>
      )}
      {isGetting && <Suspense />}
    </>
  );
};

export default CategoryWiseProduct;
