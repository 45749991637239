import { createContext } from "react";

const checkoutContext = createContext({

  isCollectingAddress: false,
  coupon: { id: null, amount: 0 },
  charge: 0,
  shipping: {
    receiverName: null,
    receiverPhone: null,
    customerPhone: null,
    divisionId: null,
    division: null,
    districtId: null,
    district: null,
    areaId: null,
    area: null,
    address: null,

    maxAmount: 0,
    minChargeAmount: 0,
    chargeAmount: 0
  },
  paymentOption: null,
  autoOrderIsActive: false,
 
  cashback: 0,
  fineCash: 0,
  prescriptions: [],
  storeCoupon: (id, amount) => { },
  storePaymentOption: (paymentOption) => { },
  storeAutoOrderStatus: (isActive) => { },
  updateShippingCharge: (charge) => { },
  storeCashback: (amount) => { },
  storeFineCash: (amount) => { },
  updateAddress: ({ 
    receiverName,
    receiverPhone,
    customerPhone,
    divisionId,
    division,
    districtId,
    district,
    areaId,
    area,
    address,
    maxAmount,
    minChargeAmount,
    chargeAmount }) => {},
    clearContext: () => {},
    storePrescription: ({objectUrl, id}) => {},
    removePrescription: (id) => {}
});

export default checkoutContext;

