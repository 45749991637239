import { VIEWPORTS_FOR_BANNER } from "../constants";

const VIEWPORT_LOWER_RANGE = {
    LARGE: 764,
    MEDIUM: 400
}

export const filterImagesByViewPort = (images = []) => {
    const width = window.innerWidth;

    if (VIEWPORT_LOWER_RANGE.LARGE <= width) {
        return filterLargeImages(images);
    }

    if (VIEWPORT_LOWER_RANGE.LARGE > width && width >= VIEWPORT_LOWER_RANGE.MEDIUM) {
        return filterMediumImages(images);
    }

    if (VIEWPORT_LOWER_RANGE.MEDIUM > width) {
        return filterSmallImages(images);
    }
    
    
    return images;

}

const filterSmallImages = (images = []) => {
    let filteredImages = [];
    filteredImages = filterImages(images, VIEWPORTS_FOR_BANNER.SMALL);
    filteredImages = filteredImages.length ? filteredImages : filterImages(images, VIEWPORTS_FOR_BANNER.MEDIUM);
    filteredImages = filteredImages.length ? filteredImages : filterImages(images, VIEWPORTS_FOR_BANNER.LARGE);

    return filteredImages;
}

const filterMediumImages = (images = []) => {
    let filteredImages = [];
    filteredImages = filterImages(images, VIEWPORTS_FOR_BANNER.MEDIUM);
    filteredImages = filteredImages.length ? filteredImages : filterImages(images, VIEWPORTS_FOR_BANNER.LARGE);

    return filteredImages;
}

const filterLargeImages = (images = []) => {
    return filterImages(images, VIEWPORTS_FOR_BANNER.LARGE);
}

const filterImages = (images = [], viewport) => {
    return images.filter((b) => b.viewport.toLowerCase() === viewport);
}