import React from 'react';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import authContextV2 from '../../../../store/auth-context-v2';
import prescriptionIcon from '../../../../icons/prescription-white.svg';
import userIcon from '../../../../icons/user.svg';
import phoneIcon from '../../../../icons/phone.svg';
import Link from './Link/Link';
import User from './User/User';
import appContext from '../../../../store/app-context';

const Links = () => {
  const { isAuthenticated, open, user } = useContext(authContextV2);
  const { toggleUserMenu } = useContext(appContext);

  const history = useHistory();

  const linkClickHandler = (to) => {
    if (!isAuthenticated) {
      open('LOGIN', to);
    } else {
      history.push(to);
    }
  };

  const getUserName = () => {
    if (user.name)
      return user.name.length <= 7 ? user.name : user.name.slice(0, 5) + '..';

    return 'Profile';
  };

  const profileButtonText = isAuthenticated ? getUserName() : 'Login';

  const prescriptionButtonText =
    window.innerWidth < 1240 ? 'Prescriptions' : 'Upload Prescription';

  return (
    <ul className={'flex gap-4'}>
      <Link
        icon={prescriptionIcon}
        text={prescriptionButtonText}
        onClick={linkClickHandler.bind(null, '/Profile/Prescription/History')}
      />
      <Link
        icon={userIcon}
        text={profileButtonText}
        onClick={linkClickHandler.bind(null, '/Profile/OrderHistory/All')}
        // onClick={toggleUserMenu}
      />
      {/* <User /> */}
      {/* <Link icon={phoneIcon} href={'tel:01704247162'} title={'Call to Order'} /> */}
    </ul>
  );
};

export default Links;
