import { BASE_URL } from "../../../../../Service/httpService";

const Prescription = ({ path }) => {
  return (
    <div className="image_prev">
      <img
        src={BASE_URL + path}
        alt="img"
        srcset=""
        style={{ height: "100%" }}
      />
    </div>
  );
};

export default Prescription;
