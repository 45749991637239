import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import {
  GET_CURRENT_INFO,
  GET_ORDER_BY_PRESCRIPTIONS,
  GET_ORDER_DETAILS,
} from '../../../lib/endpoints';
import { http } from '../../../Service/httpService';
import authContextV2 from '../../../store/auth-context-v2';
import Suspense from '../../Suspense/Suspense';
import Loader from '../../utilities/Loader/Loader';
import AttachPrescriptionAlert from '../PrescriptionAlert/AttachPrescriptionAlert';
import UploadPrescriptionAlert from '../PrescriptionAlert/UploadPrescriptionAlert';
import Address from './Address/Address';
import Invoice from './Invoice/Invoice';
import { printInvoice } from './order-details.utils';
import OrderActions from './OrderActions/OrderActions';
import Prescriptions from './Prescriptions/Prescriptions';
import Progress from './Progress/Progress';

const OrderDetails = () => {
  let { id } = useParams();
  const { user } = useContext(authContextV2);
  const [orderDetails, setOrderDetails] = useState();
  const [currentInfo, setCurrentInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [prescriptions, setPrescriptions] = useState([]);
  const products = [];
  const [UploadPresAlert, setUploadPresAlert] = useState(false);
  const [attachPres, setAttachPres] = useState(false);
  const [isRedirecting, setIsRedirection] = useState(false);

  const closePrescriptionModal = () => {
    setUploadPresAlert((prevState) => !prevState);
  };
  const closeAttachPreshandler = () => {
    setAttachPres((prevState) => !prevState);
  };

  const printInvoiceHandler = () => {
    printInvoice(orderDetails.Order, user, orderDetails.Products);
  };

  const getOrderDetailsHttp = () => {
    http.get({
      url: GET_ORDER_DETAILS + id,
      before: () => {},
      successed: (res) => {
        setOrderDetails(res.Data);
        res.Data.Products.map(function (element) {
          return products.push(element.ProductId);
        });
        getPrescriptionsByOrder();
        getCurrentInfo();
        setIsLoading(false);
      },
      failed: () => {
        setIsLoading(true);
      },
      always: () => {
        setIsLoading(false);
      },
    });
  };

  const getPrescriptionsByOrder = useCallback(() => {
    http.get({
      url: GET_ORDER_BY_PRESCRIPTIONS + id,
      before: () => {},
      successed: (res) => {
        setPrescriptions(res.Data);
      },
      failed: () => {},
      always: () => {},
    });
  }, []);

  const getCurrentInfo = () => {
    http.post({
      url: GET_CURRENT_INFO,
      payload: {
        productIds: products,
      },
      before: () => {},
      successed: (res) => {
        setCurrentInfo(res.Data);
      },
      failed: () => {},
      always: () => {},
    });
  };

  const uploadPrescriptionHandler = () => {
    setUploadPresAlert((prevState) => !prevState);
  };
  const attachedPrescriptionHandler = () => {
    setAttachPres((prevState) => !prevState);
  };

  useEffect(() => {
    getOrderDetailsHttp();
  }, []);

  console.log({ orderDetails });

  return (
    <Fragment>
      {!isLoading && (
        <div class='tabbed niiceeTabContent profile-tab invoice-tab'>
          <Progress status={orderDetails?.Order.Status} />
          <Prescriptions prescriptions={prescriptions} />
          <Address
            slot={orderDetails?.Order.AddressType}
            name={orderDetails?.Order.ComtactName}
            phone={orderDetails?.Order.ComtactPhone}
            email={orderDetails?.Order.ComtactEmail}
            province={orderDetails?.Order.Province}
            district={orderDetails?.Order.District}
            upazila={orderDetails?.Order.Upazila}
            remarks={orderDetails?.Order.Remarks}
          />
          <OrderActions
            onAttach={attachedPrescriptionHandler}
            onPrint={printInvoiceHandler}
            onUpload={uploadPrescriptionHandler}
            setIsRedirection={setIsRedirection}
            orderId={orderDetails?.Order.Id}
            orderNumber={orderDetails?.Order.OrderNo}
            payable={orderDetails?.Order.PaymentLeft}
            paymentStatus={orderDetails?.Order.PaymentStatus}
            userId={orderDetails?.Order.CustomerId}
            phone={user.phone}
          />
          <Invoice
            currentInfo={currentInfo}
            orderDetails={orderDetails}
            customerName={orderDetails?.Order.ComtactName ?? ''}
            phoneNumber={orderDetails?.Order.ComtactPhone ?? ''}
          />
        </div>
      )}
      {isRedirecting && !isLoading && <Loader />}
      {isLoading && <Suspense />}
      {UploadPresAlert && (
        <UploadPrescriptionAlert
          orderId={orderDetails.Order.Id}
          orderNumber={orderDetails.Order.OrderNo}
          closeModal={closePrescriptionModal}
          getPrescriptionsByOrder={getPrescriptionsByOrder}
        />
      )}
      {attachPres && (
        <AttachPrescriptionAlert
          closeModal={closeAttachPreshandler}
          orderId={orderDetails.Order.Id}
          getPrescriptionsByOrder={getPrescriptionsByOrder}
        />
      )}
    </Fragment>
  );
};

export default OrderDetails;
