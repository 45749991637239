import React from 'react';
import Footer from './Footer/Footer';
import Header from './Header/Header';
import Paginator from './Paginator/Paginator';
import Rows from './Rows/Rows';
import styles from './Table.module.css';

const Table = ({
  data = [],
  isLoading = false,
  paginate = false,
  indexed = false,
  inRowForm = false,
  columnDefinitions = [],
  className,
  total,
  // Pagination
  perPage,
  page,
  onPageChange,
  onRowClick
}) => {
  console.log(['onRowClick',onRowClick]);
  return (
    <div className={styles.table_container}>
      <table
        className={`${styles.table} ${paginate && styles['table--paginate']} ${
          !!className && className
        }`}
      >
        <Header columns={columnDefinitions} indexed={indexed} />
        <Rows
          rows={data}
          columnDefinitions={columnDefinitions}
          indexed={indexed}
          inRowForm={inRowForm}
          perPage={perPage}
          page={page}
          onClick={onRowClick}
        />
        {/* <Footer footer={false}/> */}
      </table>
      <Paginator
        paginate={paginate}
        styles={styles}
        total={total}
        perPage={perPage}
        page={page}
        onPageChange={onPageChange}
      />
    </div>
  );
};

export default Table;
