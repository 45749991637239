import { Link } from 'react-router-dom';
import { imageURL, IMAGE_OF, IMAGE_SIZE } from '../../Service/imageResolver';

const BannerTemplate = ({ item }) => {
  const isPhoneNumber = item.link?.startsWith('tel');

  return (
    <>
      {!!!item.link && (
        <span>
          <img
            src={imageURL(item.image, IMAGE_OF.BANNER, IMAGE_SIZE.ORIGINAL)}
            alt={item.altrText}
          />
        </span>
      )}
      {!!item.link && !isPhoneNumber && (
        <Link to={item.link}>
          <img
            src={imageURL(item.image, IMAGE_OF.BANNER, IMAGE_SIZE.ORIGINAL)}
            alt={item.altrText}
          />
        </Link>
      )}
      {!!item.link && isPhoneNumber &&  (
        <a href={item.link}>
          <img
            src={imageURL(item.image, IMAGE_OF.BANNER, IMAGE_SIZE.ORIGINAL)}
            alt={item.altrText}
          />
        </a>
      )}

    </>
  );
};

export default BannerTemplate;
