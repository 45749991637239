import React from 'react';
import { createPortal } from 'react-dom';
import { useHistory } from 'react-router-dom';
import { urlHomeRoute } from '../../../Service/UrlService';

const AlertPopUp = ({
  alertStateChangedHandler,
  order,
  mobile,
  show = true,
}) => {
  let history = useHistory();

  if (!show) return <></>;

  const removeButtonHandler = () => {
    alertStateChangedHandler();
    history.push(urlHomeRoute());
  };

  const viewOrderClickedHandler = () => {
    history.push(`/profile/order/details/${order.Id}`);
  };

  const alertEl = document.querySelector('#alert');

  return (
    <>
      {createPortal(
        <div id='demo-modal' class='modal'>
          <div class='modal__content' style={{ padding: 0 }}>
            <div class='login-main-area'>
              <div
                class='login-info-from'
                style={{
                  padding: 24,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 20,
                }}
              >
                <div style={{textAlign: 'center', display: 'flex', flexDirection: 'column', gap: 8}}>
                  <h1 style={{fontSize: 24, color: 'var(--primary)', fontWeight: 'bold'}}>Your order has been placed</h1>
                  <h2>order number is <span style={{fontWeight: 'bold'}}>"#{order.OrderNo}"</span></h2>
                  <p style={{fontWeight: 'bold'}}>
                    Please pay 20% to process the order. We'll call your number{' '}
                    {mobile} to reconfirm
                  </p>
                </div>
                <button
                  className='btn-special'
                  onClick={viewOrderClickedHandler}
                >
                  View Order
                </button>
              </div>
            </div>
            <a href class='modal__close' onClick={removeButtonHandler}>
              &times;
            </a>
          </div>
        </div>,
        alertEl
      )}
    </>
  );

  // return (
  //   <div id="pop-up">
  //     <div class="overlay__popup show">
  //       <div class="popup undefined">
  //         <div class="popup__title">
  //           <h2>Order Placed Successfully</h2>
  //           <div
  //             style={{ color: "white", fontSize: "1.5rem", cursor: "pointer" }}
  //             onClick={removeButtonHandler}
  //           >
  //             ✖
  //           </div>
  //         </div>
  //         <div class="popup__body">
  //           <div>
  //             <h1 class="t-20 t-bold t-center mb-8 t-primary">
  //               Your order has been placed
  //             </h1>
  //             <h1 class="t-20 t-bold t-center mb-16 t-primary">
  //               Your order number is "#{order.OrderNo}"
  //             </h1>
  //             <h2 class="t-18 t-bold t-center mb-16 t-secondary">
  //               Please pay 20% to process the order <br />
  //               We'll call your number {mobile} to reconfirm
  //             </h2>
  //             <div class="flex justify-center">
  //               <button
  //                 class="brick primary fill round-corner"
  //                 onClick={viewOrderClickedHandler}
  //               >
  //                 View Order
  //               </button>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
};

export default AlertPopUp;
