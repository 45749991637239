import React from 'react';
import Cell from './Cell/Cell';
import styles from '../../Table.module.css';

const Row = ({ row, indexed = false,  index, columnDefinitions = [], startIndex,onClick }) => {

const onRowClick = ()=>{
  console.log(['row,index,columnDefinitions,onClick',row,index,columnDefinitions,onClick]);
  onClick(row,index,columnDefinitions);
  
};

  return (
    <tr  onClick={onRowClick} >
    {indexed && <Cell data={startIndex + index + 1} className={styles.table__index}/>}
      {columnDefinitions.map(({ field, template, mapper }, i) => (
        <Cell key={i} data={row[field]} Template={template} mapper={mapper} rowIndex={index} />
      ))}
    </tr>
  );
};

export default Row;
