import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import URLS, {
  urlAllOrderRoutes,
  urlOrderDetailsRoute,
  urlOrderRoute,
  urlPrescriptionHistory,
  urlProfileAddressRoute,
  urlProfileComplain,
  urlProfileEditRoute,
  urlProfileRoute,
  urlRequestHistoryRoute,
  urlSpecialOfferRoute,
} from "../../../Service/UrlService";
import Complain from "../Complain/Complain";
import Address from "../OrderDetails/Address/Address";
import OrderDetails from "../OrderDetails/OrderDetails";
import OrderHistory from "../OrderHistory/OrderHistory";
import PaymentHistory from "../PaymentHistory/PaymentHistory";
import PrescriptionHistory from "../PrescriptionHistory/PrescriptionHistory";
import RequestHistory from "../RequestHistory/RequestHistory";
import SpecialOffer from "../Special Offer/SpecialOffer";
import UpdateProfile from "../UpdateProfile/UpdateProfile";

const ProfileBody = ({ getProfileInfo }) => {
  return (
    <div class="profile-body">
      <div class="profile-body-inner-content">
        <div class="product-desc-review-information-main">
          <Switch>
            <Route path={'/Profile'} exact>
              <Redirect to={'/Profile/OrderHistory'} />
            </Route>
            <Route path={'/Profile/OrderHistory'}>
              <OrderHistory />
            </Route>
            <Route path={urlProfileRoute() + urlPrescriptionHistory()} exact>
              <PrescriptionHistory />
            </Route>
            <Route path={urlProfileRoute() + urlRequestHistoryRoute()}>
              <RequestHistory />
            </Route>
            <Route path={urlProfileRoute() + urlProfileAddressRoute()} exact>
              <Address />
            </Route>
            <Route path={urlProfileRoute() + urlSpecialOfferRoute()} exact>
              <SpecialOffer />
            </Route>
            <Route path={urlProfileRoute() + urlProfileEditRoute()} exact>
              <UpdateProfile ProfileInfoHttp={getProfileInfo} />
            </Route>
            <Route path={urlProfileRoute() + urlProfileComplain()} exact>
              <Complain />
            </Route>
            <Route path={urlProfileRoute() + urlOrderDetailsRoute() + ":id"} exact>
              <OrderDetails />
              {/* <Complain /> */}
            </Route>
            <Route path={URLS.PAYMENT_HISTORY_URL} exact>
              <PaymentHistory />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default ProfileBody;
