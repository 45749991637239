import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { GET_USER_INFO } from '../../lib/endpoints';
import { postV2 } from '../../Service/http-service-v2';
import { http } from '../../Service/httpService';
import authContextV2 from '../../store/auth-context-v2';
import ProfileBody from '../Profile/ProfileBody/ProfileBody';
import ProfileSidebar from '../Profile/ProfileSidebar/ProfileSidebar';

const Profile = () => {
  const history = useHistory();
  const location = useLocation();
  const { isAuthenticated, isAuthenticating, open, logout } = useContext(authContextV2);
  const [profileInfo, setProfileInfo] = useState([]);
  const getProfileInfo = () => {
    http.get({
      url: GET_USER_INFO,
      before: () => {},
      successed: (res) => {
        console.log(res.Data);
        setProfileInfo(res.Data.Customer);
      },
      failed: (data) => {
        if(data.IsError){
          logout();
        }
      },
      always: () => {},
    });
  };

  const getProfile = () => {
    postV2({url: GET_USER_INFO, payload: {}})
    .then(data => {
      if(data.IsError){
        logout();
      } else if (!data.IsError) {
        setProfileInfo(data.Data.Customer);
      }
    }).catch(err => console.log(err));
  }

  

  useEffect(() => {
    getProfile();
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated && !isAuthenticating) {
      const path = location.pathname.toString();
      open('LOGIN', path);
      history.push('/');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isAuthenticating]);

  return (
    <>
      {(isAuthenticated && !isAuthenticating) && (
        <section className='profile-area relative'>
          <div className=''>
            <div className='profile-main'>
              <ProfileSidebar profileInfo={profileInfo} />
              <ProfileBody getProfileInfo={getProfileInfo} />
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Profile;
