import { CONFIG } from "./config-service"

export const httpErrorLogger = async (phone, customerId, url, message, orderPayload, remarks, el = 'checkout') => {
    const payload = {
        Url: url,
        Phone: phone,
        Data: JSON.stringify(orderPayload),
        SnapshotString: document.getElementById(el)?.innerHTML,
        SessionId: customerId || '00000000-0000-0000-0000-000000000000',
        CustomerId: customerId || '00000000-0000-0000-0000-000000000000',
        Message: message,
        ActivityId: window.ActivityId,
        Remarks: remarks
    }

    const response = await fetch(`${CONFIG.LOG_URL}/LogArea/OrderError/Add`, {
        method: 'POST',
        headers: {
            "content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(payload)
    });

    if (!response.ok) {
        console.log(response.statusText);
        setTimeout(() => {
            httpErrorLogger(phone, customerId, url, message, orderPayload, remarks);
        }, 1500);
    }

    const data = await response.json();

    if (data.IsError) {
        console.log(response.statusText);
        setTimeout(() => {
            httpErrorLogger(phone, customerId, url, message, orderPayload, remarks);
        }, 1500);
    }

    console.log('DONE!');
}