import styles from '../../Navbar.module.css';
import { NavLink } from 'react-router-dom';

const Link = ({title, url}) => {
  return (
    <NavLink
      to={url}
      exact
      activeClassName={styles['navbar__link--active']}
      className={styles.navbar__link}
    >
      <h4>{title}</h4>
    </NavLink>
  );
};

export default Link;
