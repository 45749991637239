import { useEffect } from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { postV2 } from '../../Service/http-service-v2';
import cartContext from '../../store/cart-context';
import Alert from '../Lib/Alert/Alert';
import Loader from '../utilities/Loader/Loader';
import Action from './Action/Action';
import Address from './Address/Address';
import styles from './Checkout.module.css';
import Coupon from './Coupon/Coupon';
import Payment from './Payment/Payment';
import PaymentOptions from './PaymentOptions/PaymentOptions';
import Policy from './Policy/Policy';
import Prescriptions from './Prescriptions/Prescriptions';
import Products from './Products/Products';

const Checkout = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, text: '', type: 'ERROR' });
  const {
    getCartModel: { Items },
    updateProductsPrice,
  } = useContext(cartContext);
  const history = useHistory();

  useEffect(() => {
    if (Items.length === 0) {
      history.goBack();
    }
  }, [history, Items]);

  const getUpdatedProducts = () => {
    setIsLoading(true);

    const payload = {
      productIds: Items.map((item) => item.id),
    };

    postV2({ url: '/ProductArea/Product/GetCurrentInfo', payload })
      .then((data) => {
        detectChanges(Items, data.Data);
        updateProductsPrice(data.Data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        getUpdatedProducts();
      });
  };

  useEffect(() => {
    getUpdatedProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const alertCloseHandler = () => {
    setAlert({
      show: false,
      text: '',
    });
  };

  const detectChanges = (products = [], updatedProducts = []) => {
    let changeCount = 0;

    updatedProducts.forEach((p) => {
      const changedProduct = products.find((cp) => cp.id === p.Id);

      if (changedProduct.MRP !== p.UnitSalePrice) {
        changeCount++;
      }
    });

    if (changeCount > 0)
      setAlert({
        show: true,
        text: 'Some products price updated! Please check before place order.',
      });
  };

  return (
    <div className={`${styles.checkout} relative`} id={'checkout'}>
      <div className='flex flex-column gap-16'>
        <Products />
        <Coupon setLoading={setIsLoading} />
      </div>
      <div className='flex flex-column gap-16'>
        <Payment />
        <Address />
        <Prescriptions />
      </div>
      <div className={`${styles.grid__span__2} flex flex-column gap-16`}>
        <PaymentOptions />
        <Policy />
        <Action setLoading={setIsLoading} />
      </div>
      <Loader isLoading={isLoading} />
      <Alert show={alert.show} text={alert.text} onClose={alertCloseHandler} />
    </div>
  );
};

export default Checkout;
