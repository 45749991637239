import React from 'react';
import Item from './Item/Item';
import phoneIcon from '../../../icons/phone-primary.svg';
import mailIcon from '../../../icons/mail-primary.svg';
import { forwardRef } from 'react';

const TopLayer = forwardRef((props, ref) => {
  return (
    <div className='header__top-layer flex justify-between align-center py-2 px-16' ref={ref}>
      <div className='header__top-layer__group--left flex flex-center gap-16'>
        <Item
          text={'01319-864049'}
          href={'tel:01319864049'}
          icon={phoneIcon}
        />
        <Item
          text={'lazzcorporate@gmail.com'}
          href={'mailto:lazzcorporate@gmail.com'}
          icon={mailIcon}
        />
      </div>
      <div className='header__top-layer__group--right'>
        <Item text={'First Ever Model Pharmacy in Bangladesh!'} />
      </div>
    </div>
  );
});

export default TopLayer;
