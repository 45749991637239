import React, { useState } from 'react';
import { IMAGE_SIZE } from '../../../Service/imageResolver';
import Action from '../../Shared/ProductCard/Action/Action';
import ProductImage from '../../Shared/ProductCard/ProductImage/ProductImage';
import ProductInfoTabs from './ProductInfoTabs';

const ProductDetailsContent = ({ productDetails, isGetting }) => {
  const [clickedReviewHandler, setClickedReviewHandler] = useState(false);

  const reviewClickedHandler = () => {
    setClickedReviewHandler((prevState) => !prevState);
  };

  return (
    <div id='center_column' className='center_column col-xs-12 col-sm-9'>
      <div id='product'>
        <div className='primary-box row'>
          <div className='pb-left-column col-xs-12 col-sm-6'>
            <div className='product-image'>
              <div className='product-full'>
                <ProductImage
                  className={'product-image'}
                  name={productDetails.Nm}
                  url={productDetails.image}
                  size={IMAGE_SIZE.ORIGINAL}
                  alt={`${productDetails?.Nm} ${productDetails?.St} ${productDetails?.Ct}`}
                />
              </div>
            </div>
          </div>
          <div className='pb-right-column pb-right-column-new col-xs-12 col-sm-6'>
            <h1 className='product-name'>
              {productDetails?.Nm} {productDetails?.St}{' '}
              <span style={{ fontSize: 12 }}>{productDetails?.Ct}</span>
            </h1>
            <div className='product-comments'>
              <div className='comments-advices'>
                <a href onClick={reviewClickedHandler}>
                  <i className='fa fa-pencil'></i> write a review
                </a>
              </div>
            </div>

            <div className='generic-name' style={{ lineHeight: '20px' }}>
              <p>
                <span>Generic Name</span> : {productDetails?.GN}
              </p>
            </div>
            <div className='info-orther' style={{ lineHeight: '20px' }}>
              <p>
                <span>Supplier Name</span> : {productDetails?.Sp}
              </p>
            </div>
            <div className='price-tag'>
              {productDetails?.discount > 0 && (
                <span className='price'>
                  ৳ {productDetails?.MRP - productDetails?.discount}
                </span>
              )}
              <span className={`price ${productDetails?.discount > 0 && 'cross'}`}>
                ৳ {productDetails?.MRP}
              </span>
            </div>
            <Action product={productDetails} />
          </div>
        </div>
        <ProductInfoTabs
          clickedReviewHandler={clickedReviewHandler}
          reviewClickedHandler={reviewClickedHandler}
          productDetails={productDetails}
        />
      </div>
    </div>
  );
};

export default ProductDetailsContent;
