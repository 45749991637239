import { OrderStatus } from '../../../utilities/dictionaries';

const Progress = ({ status }) => {
  return (
    <div className='brick label happiness' style={{ color: '#016449' }}>
      <div className='Steps_steps__3SNbF Steps_wide__2JixU'>
        <div className='Steps_step__2Wic5'>
          <p
            className={`Steps_step__counter__2y6zu false ${
              status === OrderStatus.Pending && 'active'
            } ${
              (status === OrderStatus.Confirmed ||
                status === OrderStatus.Processing ||
                status === OrderStatus.Delivering ||
                status === OrderStatus.Delivered) &&
              'passed'
            }`}
          >
            1
          </p>
          <div className='Steps_details__1CSho'>
            <h5>pending</h5>
          </div>
        </div>
        <div className='Steps_step__2Wic5'>
          <p
            className={`Steps_step__counter__2y6zu false false ${
              status === OrderStatus.Confirmed && 'active'
            } ${
              (status === OrderStatus.Processing ||
                status === OrderStatus.Delivering ||
                status === OrderStatus.Delivered) &&
              'passed'
            }`}
          >
            2
          </p>
          <div className='Steps_details__1CSho'>
            <h5>confirmed</h5>
          </div>
        </div>
        <div className='Steps_step__2Wic5'>
          <p
            className={`Steps_step__counter__2y6zu false false ${
              status === OrderStatus.Processing && 'active'
            } ${
              (status === OrderStatus.Delivering ||
                status === OrderStatus.Delivered) &&
              'passed'
            }`}
          >
            3
          </p>
          <div className='Steps_details__1CSho'>
            <h5>processing</h5>
          </div>
        </div>
        <div className='Steps_step__2Wic5'>
          <p
            className={`Steps_step__counter__2y6zu false false ${
              status === OrderStatus.Delivering && 'active'
            } ${status === OrderStatus.Delivered && 'passed'}`}
          >
            4
          </p>
          <div className='Steps_details__1CSho'>
            <h5>delivering</h5>
          </div>
        </div>
        <div className='Steps_step__2Wic5'>
          <p
            className={`Steps_step__counter__2y6zu false false ${
              status === OrderStatus.Delivered && 'active'
            }`}
          >
            5
          </p>
          <div className='Steps_details__1CSho'>
            <h5>completed</h5>
          </div>
        </div>
        <div className='line'>
          <div className={`filler ${status}`}></div>
        </div>
      </div>
    </div>
  );
};

export default Progress;
