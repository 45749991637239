import React, { useState, useEffect, useContext } from "react";
import { OTP_LOGIN_REQUEST } from "../../lib/endpoints";
import { http } from "../../Service/httpService";
import authContext from "../../store/auth-context";
import AuthenticationModalBody from "./AuthenticationModalBody";
import OtpCodeModal from "./OtpCodeModal";

const LoginModal = ({ closeModal, onSuccessed }) => {
  const authCtx = useContext(authContext);

  const [otpCodeModal, setOtpCodeModal] = useState(false);

  ///validation state defined
  const [phone, setPhone] = useState("");
  const [phoneIsTouched, setPhoneIsTouched] = useState(false);
  const [phoneIsValid, setPhoneIsValid] = useState(false);

  const [clicked, setClicked] = useState(false);
  ///end

  const [isFailedLogin, setIsLoginFailed] = useState(false);

  const phoneChangeHandler = ({ target }) => {
    setPhone(target.value);
  };
  const phoneTouchedHandler = () => {
    setPhoneIsTouched(true);
  };

  const closeOtpModalHandler = () => {
    closeModal();
    setOtpCodeModal((prevState) => !prevState);
  };

  const submitButtonHandler = (evt) => {
    evt.preventDefault();
    setClicked(true);
    if (phone.length !== 0 && phone.length === 11) {
      http.post({
        url: OTP_LOGIN_REQUEST,
        payload: {
          Phone: phone,
        },
        before: () => {},
        successed: (res) => {
          authCtx.userOtpId.id = res.Id;
          authCtx.getRegistrationValue = phone
          setOtpCodeModal(true);
        },
        failed: () => {
          setIsLoginFailed(true);
        },
        always: () => {},
      });
    }
  };

  useEffect(() => {
    if (clicked) {
      if (
        (phoneIsTouched && phone.length === 0) ||
        (!phoneIsTouched && phone.length === 0)
      ) {
        setPhoneIsValid(true);
      } else setPhoneIsValid(false);
    }
  }, [clicked, phone.length, phoneIsTouched]);

  return (
    <div className="loginModal">
      {!otpCodeModal && (
        <div class="login-main-area">
          <h2>LogIn</h2>
          <div class="login-info-from">
            <form>
              
              <i class="fa fa-spinner" aria-hidden="true"></i>
              <div class="login-info-inner-content">
                <div class="custom-input">
                  <label for="mobile">Mobile Number</label>
                  <input
                    type="text"
                    name=""
                    id="mobile"
                    required
                    value={phone}
                    onChange={phoneChangeHandler}
                    onBlur={phoneTouchedHandler}
                  />
                  {phoneIsValid && (
                    <div class="alert alert-error">Phone is required.</div>
                  )}
                  {phoneIsTouched && phone.length === 0 && !phoneIsValid && (
                    <div class="alert alert-error">Phone is required.</div>
                  )}
                </div>

                {isFailedLogin && (
                  <div class="alert alert-error login-failed">
                    Login failed, Phone or Password is wrong!
                  </div>
                )}

                <div class="login-submit" onClick={submitButtonHandler}>
                  <input type="submit" value="Login" />
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {otpCodeModal && (
        <AuthenticationModalBody
          Template={OtpCodeModal}
          closeModal={closeOtpModalHandler}
          closeLoginModalhandler={closeModal}
          onSuccessed={onSuccessed}
        />
      )}
    </div>
  );
};

export default LoginModal;
