import { useContext } from 'react';
import appContext from '../../../store/app-context';
import Search from '../Shared/Search/Search';
import Hamburger from './Hamburger/Hamburger';
import styles from './MobileLayer.module.css';

const MobileLayer = () => {
  const { toggleNavbar } = useContext(appContext);
  const hamClickHandler = () => {
    toggleNavbar();
  }
  return (
    <div className={styles.mobile_layer}>
      <Hamburger className={styles.mobile_layer__ham} onClick={hamClickHandler} />
      <Search styles={styles} />
    </div>
  );
};

export default MobileLayer;
