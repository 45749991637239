import React from "react";
import { useRef } from "react";
import { useHistory } from "react-router-dom";
import { urlSearchProducts } from "../../Service/UrlService";
import Preloader from "../Preloader/Preloader";
import SearchTemplate from "./SearchTemplate";

const SearchPortal = ({
  data,
  closeSearchHandler,
  lowerSearchvalue,
  totalData,
  isGetting,
  onClick,
  onProductClick
}) => {
  let history = useHistory();
  const ref = useRef(null);

  const viewAllProductHandler = (evt) => {
    evt.preventDefault();
    history.push(`${urlSearchProducts()}?query=${lowerSearchvalue}`);
    closeSearchHandler();
  };

  return (
    <div class="search-result" id="search-result" ref={ref}>
      {isGetting && (
        <div class="search-result__no-product-message">
        <div className="product-searching"><Preloader size={'small'}/> Searching</div> 
      </div>
      )}
      {data.length === 0 && !isGetting && (
        <div class="search-result__no-product-message">
          <div className="product-searching" style={{height: 48, color: '#c73700'}}>No Product Found!</div>
        </div>
      )}
      {data.length > 0 &&
        !isGetting &&
        data.map((item) => (
          <SearchTemplate
            item={item}
            closeSearch={closeSearchHandler}
            lowerSearchvalue={lowerSearchvalue}
            onClick={onClick}
            onProductClick={onProductClick}
          />
        ))}
      {data.length >= 5 && !isGetting && (
        <div class="search-result__view-more cursor-pointer" onClick={viewAllProductHandler}>
          <button className="background-primary text-14 text-white py-4">View All Result</button>
        </div>
      )}
    </div>
  );
};

export default SearchPortal;
