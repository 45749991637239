import React from 'react';
import { useState } from 'react';
import styles from '../Auth.module.css';
import authContextV2 from '../../../store/auth-context-v2';
import { useContext } from 'react';
import { postV2 } from '../../../Service/http-service-v2';
import { UPDATE_PASSWORD_WEB } from '../../../lib/endpoints';

const ResetPassword = () => {
  const { open, storeSignupData } = useContext(authContextV2);

  const [error, setError] = useState(null);

  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [phoneError, setPhoneError] = useState(false);
  const [passwordError, setPasswordError] = useState(null);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  const phoneChangeHandler = ({ target: el }) => {
    setPhone(el.value);
  };

  const passwordChangeHandler = ({ target: el }) => {
    setPassword(el.value);
  };

  const confirmPasswordChangeHandler = ({ target: el }) => {
    setConfirmPassword(el.value);
  };

  const phoneFocusHandler = () => {
    setPhoneError(false);
  };

  const passwordFocusHandler = () => {
    setConfirmPasswordError(false);
  };

  const confirmPasswordFocusHandler = () => {
    setPasswordError(null);
  };

  const resetHandler = (e) => {
    e.preventDefault();
    
    let isValid = true;
    if (phone.length === 0) {setPhoneError(true); isValid = false;}
    if (password.length < 4 || password.length > 20) {setPasswordError('Password requires 4 to 20 characters'); isValid = false;}
    if (confirmPassword.length === 0){ setConfirmPasswordError(true); isValid = false;}

    if (!isValid) return;

    if (confirmPassword !== password) return setError('Password did not match');

    reset();
  };

  const reset = () => {
    const payload = {
      ActivityId: window.ActivityId,
      Phone: phone,
    };
    postV2({ url: UPDATE_PASSWORD_WEB, payload }).then((data) => {
      if (!data.IsError) {
        storeSignupData({ phone: phone, password: password, optId: data.Id });
        open('OTP_RESET_PASSWORD');
      } else {
        console.log(data);
        setError(data.Msg);
      }
    });
  };

  const loginClickHandler = () => {
    open('LOGIN');
  };

  const signupClickHandler = () => {
    open('SIGNUP');
  };

  return (
    <>
      <form>
        <div className={styles.auth_dialouge__form_field}>
          <label htmlFor='phone'>
            Phone Number
            <input
              type='text'
              id='phone'
              onChange={phoneChangeHandler}
              onFocus={phoneFocusHandler}
              value={phone}
            />
            <small>{phoneError ? 'Phone number is empty' : ' '}</small>
          </label>
        </div>
        <div className={styles.auth_dialouge__form_field}>
          <label htmlFor='password'>
            Password
            <input
              type='password'
              id='password'
              onChange={passwordChangeHandler}
              onFocus={passwordFocusHandler}
              value={password}
            />
            <small>{!!passwordError ? passwordError : ' '}</small>
          </label>
        </div>
        <div className={styles.auth_dialouge__form_field}>
          <label htmlFor='confirm-password'>
            Confirm Password
            <input
              type='password'
              id='confirm-password'
              onChange={confirmPasswordChangeHandler}
              onFocus={confirmPasswordFocusHandler}
              value={confirmPassword}
            />
            <small>
              {confirmPasswordError ? 'Password number is empty' : ' '}
            </small>
          </label>
        </div>
        <div className={styles.auth_dialouge__error}>
          <p>{error ? error : ''}</p>
        </div>
        <div className={styles.auth_dialouge__actions}>
          <div>
            <p>
              Login to your account{' '}
              <span
                className={styles['auth_dialouge__actions__button--text']}
                onClick={loginClickHandler}
              >
                Login
              </span>
            </p>
            <p>
              Create new account?{' '}
              <span
                className={styles['auth_dialouge__actions__button--text']}
                onClick={signupClickHandler}
              >
                Sign up
              </span>
            </p>
          </div>

          <button
            className={styles.auth_dialouge__actions__button}
            onClick={resetHandler}
            type='button'
          >
            Reset Password
          </button>
        </div>
      </form>
    </>
  );
};

export default ResetPassword;
