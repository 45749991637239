import userImage from '../../../../../../../images/user.png';
import Card from '../../../../../../Shared/Card/Card';

const Profile = ({name, phone, due = 0, wallet = 0, image}) => {

  return (
    <Card className={'flex flex-column gap-8 cursor-pointer'}>
      <div className='flex justify-between border-radius-4'>
        <div className='w-64'>
          <img src={image || userImage} alt='customer' className='w-max-full' />
        </div>
        <div>
          <p className='text-bold text-gray text-right'>{name}</p>
          <p className='text-gray text-semibold text-right'>{phone}</p>
          <p className='text-gray text-semibold text-right text-12'>
            <span className='text-bold'>Wallet: </span> {due.toFixed(2)} Tk
          </p>
          <p className='text-gray text-semibold text-right text-warn text-12'>
            <span className='text-bold'>Due: </span> {wallet < 0 ? 0.00 : wallet.toFixed(2)} Tk
          </p>
        </div>
      </div>
    </Card>
  );
};

export default Profile;
