import React from 'react';
import { useState } from 'react';
import Marquee from 'react-fast-marquee';
import styles from './Noticebar.module.css';

const Noticebar = ({ texts }) => {
  const [pause, setPause] = useState(false);
  const mouseEnterHandler = () => {
    setPause(true);
  };

  const mouseLeaveHandler = () => {
    setPause(false);
  };
  return (
    <div className={`${styles.noticebar}`}>
      <Marquee
        className={styles.marquee}
        pauseOnHover={pause}
        gradient={false}
        speed={50}
        delay={0}
        loop={0}
      >
        <span
          className={styles.noticebar__text}
          onMouseEnter={mouseEnterHandler}
          onMouseLeave={mouseLeaveHandler}
        >
          {texts.join('   ')}
        </span>
      </Marquee>
    </div>
  );
};

export default Noticebar;
