import React from 'react';

const Tab = ({styles, children, label}) => {
    return (
        <div>
            <p>{children || label}</p>
        </div>
    );
};

export default Tab;