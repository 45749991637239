import React, { useContext, useState, useEffect } from 'react';
import { OTP_LOGIN, OTP_LOGIN_REQUEST } from '../../lib/endpoints';
import { http } from '../../Service/httpService';
import authContext from '../../store/auth-context';

const OtpCodeModal = ({ closeModal, forgotPassPressed, onSuccessed }) => {
  const [clicked, setClicked] = useState(false);
  const [isExistUser, setIsExistUser] = useState(false);
  const [otp, setOtp] = useState('');
  const authCtx = useContext(authContext);
  const getAuthCtxRegistrationValue = authCtx.getRegistrationValue;
  const [otpIsTouched, setOtpIsTouched] = useState(false);
  const [otpIsValid, setOtpIsValid] = useState(false);
  const [failedMessage, setFailedMessage] = useState(false);
  const [otpResend, setOtpResend] = useState(false);

  const otpTouchedHandler = () => {
    setOtpIsTouched(true);
  };
  const otpChangedHandler = ({ target }) => {
    setOtp(target.value);
    setFailedMessage(false);
    setIsExistUser(false);
  };

  useEffect(() => {
    if (clicked) {
      if (
        (otpIsTouched && otp.length === 0) ||
        (!otpIsTouched && otp.length === 0)
      ) {
        setOtpIsValid(true);
      } else setOtpIsValid(false);
    }
  }, [clicked, otp.length, otpIsTouched]);

  const submitHandler = (evt) => {
    evt.preventDefault();

    setClicked(true);
    setOtpResend(false);

    if (otp.length === 0) return;

    http.post({
      url: OTP_LOGIN,
      payload: {
        Code: otp,
        Id: authCtx.userOtpId.id,
        ActivityId: '00000000-0000-0000-0000-000000000000',
      },
      before: () => {},
      successed: (res) => {
        authCtx.login({
          id: res.Data.Id,
          name: res.Data.Name,
          token: res.Data.datacontent,
          image: res.Data.Icon,
          email: res.Data.Email,
          phone: res.Data.Phone,
        });
        setTimeout(() => {
          onSuccessed();
          closeModal();
        }, 1000);
      },
      failed: (data) => {
        setFailedMessage(true);
      },
      always: () => {},
    });
  };

  const resendOtpHandler = (evt) => {
    evt.preventDefault();
    http.post({
      url: OTP_LOGIN_REQUEST,
      payload: {
        ActivityId: '00000000-0000-0000-0000-000000000000',
        Phone: getAuthCtxRegistrationValue,
      },
      before: () => {
        setOtpIsTouched(false);
        setOtpIsValid(false);
        setOtpResend(false);
      },
      successed: (res) => {
        //spinner off here
        authCtx.userOtpId.id = res.Id;
        setOtpResend(true);
      },
      failed: () => {},
      always: () => {},
      map: (data) => {
        return data;
      },
    });
  };

  return (
    <div class="login-main-area">
       <h2>Please Enter Your Otp Code</h2>
      <div class="login-info-from">
        <form>
         
          <i class="fa fa-spinner" aria-hidden="true"></i>
          <div class="login-info-inner-content">
            <div class="custom-input">
              <label for="mobile">Otp Code</label>
              <input
                type='text'
                name=''
                id='mobile'
                required
                value={otp}
                onChange={otpChangedHandler}
                onBlur={otpTouchedHandler}
              />
              {failedMessage && <div class='alert alert-error'>Wrong OTP!</div>}
              {!forgotPassPressed && isExistUser && (
                <div class='alert alert-error'>User already Exist!</div>
              )}
              {forgotPassPressed && isExistUser && (
                <div class='alert alert-error'>User doesn't exist!</div>
              )}
              {otpIsValid && (
                <div class='alert alert-error'>Otp is required.</div>
              )}
              {otpIsTouched && otp.length === 0 && !otpIsValid && (
                <div class='alert alert-error'>Otp is required.</div>
              )}
            </div>
            <div class='login-submit'>
              <button
                type='submit'
                className='submit-btn'
                onClick={submitHandler}
              >
                Login
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class='dont-have-account'>
        <p>Don't Receved Otp Code</p>
        <a href onClick={resendOtpHandler}>
          Resent
        </a>
      </div>
      {otpResend && (
        <div class='dont-have-account'>
          <p style={{ color: 'green' }}>OTP Code Send Successfully.</p>
        </div>
      )}
    </div>
  );
};

export default OtpCodeModal;
