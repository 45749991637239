import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { GET_RELATED_PRODUCTS, PRODUCT_DETAILS_GET } from '../../lib/endpoints';
import { searchItemsConvertObject } from '../../lib/utilities';
import { http } from '../../Service/httpService';
import { imageURL, IMAGE_OF, IMAGE_SIZE } from '../../Service/imageResolver';
import ProductDetailsContent from '../Products/ProductDetails/ProductDetailsContent';
import ProductDetailsHeader from '../Products/ProductDetails/ProductDetailsHeader';
import ProductImage from '../Shared/ProductCard/ProductImage/ProductImage';
import Suspense from '../Suspense/Suspense';

const ProductDetails = () => {
  const { id } = useParams();
  const [isGetting, setIsGetting] = useState(true);
  const [productDetails, setProductDetails] = useState();
  const [relatedProducts, setRelatedProducts] = useState([]);

  const getProductDetails = useCallback((id) => {
    http.get({
      url: PRODUCT_DETAILS_GET + id,
      before: () => {
        setIsGetting(true);
      },
      successed: (res) => {
        //res.Id = id;
        setProductDetails(searchItemsConvertObject(res));
        document.querySelector('title').innerText = `${res.Name} ${res.Strength} ${res.Type} | ${res.GenericName} | ${res.SupplierName} | Lazz Pharma Online Medicine Shop`;
        getRelatedProducts(id);
      },
      failed: () => {
        setIsGetting(false);
      },
      always: () => {},
    });
  }, []);

  const getRelatedProducts = useCallback((id) => {
    http.get({
      url: GET_RELATED_PRODUCTS + id,
      before: () => {
        setIsGetting(true);
      },
      successed: (res) => {
        setRelatedProducts(res.Data);
        
        setIsGetting(false);
      },
      failed: () => {},
      always: () => {
        setIsGetting(false);
      },
    });
  }, []);

  useEffect(() => {
    getProductDetails(id);
    return () => {
      document.querySelector('title').innerText = 'Lazz Pharma | Online Pharmacy'
    }
  }, [getProductDetails, id]);

  return (
    <>
      {!isGetting && (
        <div id='body_content'>
          <div class='columns-container'>
            <div id='columns' class='container'>
              <ProductDetailsHeader />
              <ProductDetailsContent
                productDetails={productDetails}
                isGetting={isGetting}
              />
              <div id='left_column' class='column col-xs-12 col-sm-3'>
                <div class='block left-module'>
                  <p class='title_block'>RELATED PRODUCTS</p>
                  <div class='block_content'>
                    <ul class='products-block best-sell'>
                      {relatedProducts.map((item) => {
                        const item2 = searchItemsConvertObject(item);
                        return (
                          <Link
                            key={item2.id}
                            to={`/product/details/${item2.permalink}`}
                          >
                            <div class='products-block-left'>
                                <img
                                  src={
                                    item2.image !== null
                                      ? imageURL(
                                          item2.image,
                                          IMAGE_OF.PRODUCT,
                                          IMAGE_SIZE.SMALL
                                        )
                                      : '/Contents/assets/image/default-medicine.png'
                                  }
                                  alt={`${item2.Nm} ${item2.St} ${item2.Ct}`}
                                  style={{ maxWidth: '100%' }}
                                />
                            </div>
                            <div class='products-block-right'>
                              <p class='product-name'>
                                <a href>
                                  {item2.Nm} {item2.St} {item2.Ct}
                                </a>
                              </p>
                              <p class='product-Category'>
                                <small>Category : {item2.Ct}</small>
                              </p>
                              <p class='product-Category'>
                                <small>Supplier : {item2.Sp}</small>
                              </p>
                              <div className='price-tag'>
                                {item2?.discount > 0 && (
                                  <span class='price'>
                                    ৳{' '}
                                    {item2?.MRP -
                                      item2?.discount}
                                  </span>
                                )}
                                <span
                                  class={`price ${
                                    item2?.discount > 0 && 'cross'
                                  }`}
                                >
                                  ৳ {item2?.MRP}
                                </span>
                              </div>
                            </div>
                          </Link>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isGetting && <Suspense />}
    </>
  );
};

export default ProductDetails;
