import React from 'react';
import { useState } from 'react';
import styles from '../Auth.module.css';
import authContextV2 from '../../../store/auth-context-v2';
import { useContext } from 'react';
import { postV2 } from '../../../Service/http-service-v2';
import { GET_OTP } from '../../../lib/endpoints';

const Signup = () => {
  const { open, storeSignupData } = useContext(authContextV2);

  const [error, setError] = useState(null);

  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [phoneError, setPhoneError] = useState(false);
  const [passwordError, setPasswordError] = useState(null);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  const phoneChangeHandler = ({ target: el }) => {
    setPhone(el.value);
  };

  const passwordChangeHandler = ({ target: el }) => {
    setPassword(el.value);
  };

  const confirmPasswordChangeHandler = ({ target: el }) => {
    setConfirmPassword(el.value);
  };

  const phoneFocusHandler = () => {
    setPhoneError(false);
  };

  const passwordFocusHandler = () => {
    setPasswordError(null);
  };

  const confirmPasswordFocusHandler = () => {
    setConfirmPasswordError(false);
  };

  const signupHandler = (e) => {
    e.preventDefault();
    
    let isValid = true;
    if (phone.length === 0) {
      setPhoneError(true);
      isValid = false;
    }
    if (password.length < 4 || password.length > 20) {
      setPasswordError('Password requires 4 to 20 characters');
      isValid = false;
    }
    if (confirmPassword.length === 0) {
      setConfirmPasswordError(true);
      isValid = false;
    }

    if (!isValid) return;

    if (confirmPassword !== password) return setError('Password did not match');

    requestOTP();
  };

  const requestOTP = () => {
    const payload = {
      ActivityId: window.ActivityId,
      Phone: phone,
    };
    postV2({ url: GET_OTP, payload }).then((data) => {
      if (!data.IsError) {
        storeSignupData({ phone: phone, password: password, optId: data.Id });
        open('OTP');
      } else {
        console.log(data);
        setError(data.Msg);
      }
    });
  };

  const loginClickHandler = () => {
    open('LOGIN');
  };

  const resetClickHandler = () => {
    open('RESET_PASSWORD');
  };

  return (
    <>
      <form>
        <div className={styles.auth_dialouge__form_field}>
          <label htmlFor='phone'>
            Phone Number
            <input
              type='text'
              id='phone'
              onChange={phoneChangeHandler}
              onFocus={phoneFocusHandler}
              value={phone}
            />
            <small>{phoneError ? 'Phone number is empty' : ' '}</small>
          </label>
        </div>
        <div className={styles.auth_dialouge__form_field}>
          <label htmlFor='password'>
            Password
            <input
              type='password'
              id='password'
              onChange={passwordChangeHandler}
              onFocus={passwordFocusHandler}
              value={password}
            />
            <small>{passwordError ? passwordError : ' '}</small>
          </label>
        </div>
        <div className={styles.auth_dialouge__form_field}>
          <label htmlFor='confirm-password'>
            Confirm Password
            <input
              type='password'
              id='confirm-password'
              onChange={confirmPasswordChangeHandler}
              onFocus={confirmPasswordFocusHandler}
              value={confirmPassword}
            />
            <small>
              {confirmPasswordError ? 'Retype the number is empty' : ' '}
            </small>
          </label>
        </div>
        <div className={styles.auth_dialouge__error}>
          <p>{error ? error : ''}</p>
        </div>
        <div className={styles.auth_dialouge__actions}>
          <div>
            <p>
              Already have account ?{' '}
              <span
                className={styles['auth_dialouge__actions__button--text']}
                onClick={loginClickHandler}
              >
                Login
              </span>
            </p>
            <p>
              Forgot password?{' '}
              <span
                className={styles['auth_dialouge__actions__button--text']}
                onClick={resetClickHandler}
              >
                Reset
              </span>
            </p>
          </div>

          <button
            className={styles.auth_dialouge__actions__button}
            onClick={signupHandler}
            type='button'
          >
            Sign Up
          </button>
        </div>
      </form>
    </>
  );
};

export default Signup;
