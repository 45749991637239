
import Item from "./Item/Item";

const Horizontal = ({ gap, images }) => {
  return (
    <div className="banner_image_category_vertical" style={{ gap: `${gap}vw` }}>
      {images.map((item) => (
        <Item item={item} />
      ))}
    </div>
  );
};

export default Horizontal;
