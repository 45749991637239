import React from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import styles from '../../../Table.module.css';

const Control = ({ type, Template, onSubmit, onBlur, onChange, placeHolder, intialValue }) => {
  const [value, setValue] = useState(intialValue || type === 'number' ? 0 : '');
  const ref = useRef(null);
  const blurHandler = (e) => {
    onBlur && onBlur(value, e, ref.current);
  };

  const changeHandler = (e) => {
    setValue(e.target.value);
    onChange && onChange(value, e, ref.current);
  }

  const focusHandler = (e) => {
  }

  const clickHandler = (e) => {
    onSubmit && onSubmit(e);
  }

  if (type === 'submit') {
    return (
      <td>
        <button
          className={`${styles.button} ${styles.submit} in-row-form-submit`}
          onClick={clickHandler}
        >
          Add
        </button>
      </td>
    );
  }

  return <td>{!!type && <input type={type} onBlur={blurHandler} onChange={changeHandler} value={value} placeholder={placeHolder} onFocus={focusHandler} ref={ref} />}</td>;
};

export default Control;
