import React from "react";
import { getBanners } from "../../Service/DataService";
import { filterImagesByViewPort } from "../../shared/image.util";


import Slider from "../utilities/Slider";
import BannerTemplate from "./BannerTemplate";



const Banner = () => {
  const banners = getBanners();
  const options = {
    rewind: true,
    type: "loop",
    autoplay: true,
    rewindSpeed: 1000,
    speed: 500,
    pauseOnHover: false,
    width: "100%",
    fixedHeight: false,
  };

  const filteredBanners = filterImagesByViewPort(banners);

  return (
    <>
      <div className="banner-area">
        <div className="container-fluid">
          <div className="row custom-slide-row">
            <div className="col-md-12 col-lg-12">
              <div className="banner-slider">
                <Slider
                  Template={BannerTemplate}
                  options={options}
                  data={filteredBanners}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
