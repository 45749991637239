import React from 'react';
import Control from './Control/Control';

const Form = ({ columnDefinitions }) => {
  return (
    <tr>
      {columnDefinitions.map((column, i) => (
        <Control
          key={i}
          type={column.input}
          Template={column.template}
          onBlur={column.onBlur}
          onChange={column.onChange}
          placeHolder={column.placeHolder}
          onSubmit={column.onSubmit}
          intialValue={column.intialValue}
        />
      ))}
    </tr>
  );
};

export default Form;
