import { useRef, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { POST_REQUEST_ORDER } from '../../lib/endpoints';
import { postV2 } from '../../Service/http-service-v2';
import Address from '../Checkout/Address/Address';
import Prescriptions from '../Checkout/Prescriptions/Prescriptions';
import checkoutContext from '../Checkout/store/checkout-context';
import Alert from '../Lib/Alert/Alert';
import Button from '../Shared/Button/Button';
import Card from '../Shared/Card/Card';
import Page from '../Shared/Page/Page';
import Table from '../Shared/Table/Table';
import Textarea from '../Shared/Textarea/Textarea';
import Loader from '../utilities/Loader/Loader';
import RequestProductAlert from './RequestProductAlert/RequestProductAlert';

const breadcrumb = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'Request Order',
    url: '/Request-Order',
  },
];

const RequestOrder = () => {
  const { prescriptions, shipping, removePrescription } =
    useContext(checkoutContext);

  const [alert, setAlert] = useState({
    show: false,
    message: '',
    variant: 'ERROR',
    button: 'Close',
    onClose: () => {},
  });
  const [name, setName] = useState();
  const [strength, setStrength] = useState();
  const [quantity, setQuantity] = useState();
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [successed, setSuccessed] = useState(false);

  const remarksRef = useRef(null);
  const checkboxRef = useRef(null);

  const alertCloseHandler = () => {
    setAlert({
      show: false,
      message: '',
      variant: 'ERROR',
      button: 'Close',
      onClose: alertCloseHandler,
    });
  };

  const removeClickHandler = (index, e) => {
    console.log(index, e);
    setProducts((prevState) => prevState.filter((p, i) => i !== index));
  };

  const columnDefinitions = [
    {
      title: '#',
      field: 'Position',
    },
    {
      title: 'Product Name',
      field: 'Name',
      input: 'text',
      placeHolder: 'Napa Extra',
      onBlur: (name, e, ref) => {
        setName(name);
      },
    },
    {
      title: 'Strength',
      field: 'Strength',
      input: 'text',
      placeHolder: '5 MG',
      onBlur: (strength, e) => {
        setStrength(strength);
      },
    },
    {
      title: 'Quantity',
      field: 'Quantity',

      input: 'number',
      placeHolder: '2',
      initialValue: 0,
      onBlur: (quantity, e) => {
        setQuantity(quantity);
      },
    },
    {
      title: 'Action',
      input: 'submit',

      onSubmit: (e) => {
        if (!name || !quantity || !strength) {
          setAlert({
            show: true,
            message: 'Name, Strenth and Quantity Required!',
            variant: 'warn',
            button: 'Close',
            onClose: alertCloseHandler,
          });
          return;
        }
        const length = products.length;
        setProducts((prevState) => [
          ...prevState,
          {
            Name: name,
            Strength: strength,
            Quantity: quantity,
            Position: length + 1,
          },
        ]);
      },

      template: ({ row, index, data }) => (
        <Button
          onClick={removeClickHandler.bind(null, index)}
          variant='warn'
          outline
          className={'table__button--remove h-32 py-2 px-4 w-full'}
        >
          Remove
        </Button>
      ),
    },
  ];

  const orderClickHandler = () => {
    if (!isOrderValid()) return;

    setIsLoading(true);

    let totalQuantity = 0;
    products.forEach((p) => (totalQuantity += p.Quantity));

    const prescriptionsId = prescriptions.map((p) => p.id);

    const payload = {
      ActivityId: window.ActivityId,
      ImgId: prescriptionsId,
      Items: products,
      Remarks: remarksRef.current.value,
      TotalItem: products.length,
      TotalQuantity: totalQuantity,
      address: {
        ...shipping,
        areaId: shipping.areaId || 0,
      },
    };

    postV2({ url: POST_REQUEST_ORDER, payload })
      .then((data) => {
        if (!data.IsError) {
          removePrescription();
          setProducts([]);
          setSuccessed(true);
        } else {
          setAlert({
            message: data.Msg,
            show: true,
            variant: 'warn',
            button: 'Close',
            onClose: alertCloseHandler,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setAlert({
          message: 'Unexpected Error',
          show: true,
          variant: 'warn',
          button: 'Close',
          onClose: alertCloseHandler,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const isOrderValid = () => {
    if (!checkboxRef.current.checked) {
      setAlert({
        show: true,
        button: 'Close',
        message:
          'Please read terms and conditions and if you agree then check the box',
        variant: 'warn',
        onClose: alertCloseHandler,
      });
      return false;
    }

    if (products.length === 0) {
      setAlert({
        show: true,
        message: 'No Product Added',
        variant: 'warn',
        button: 'Close',
        onClose: alertCloseHandler,
      });
      return false;
    }

    const emptyFields = [];
    let errorMessage = '';
    if (!shipping.receiverPhone) emptyFields.push('Receiver Phone Number');

    if (!shipping.division) emptyFields.push('Division');

    if (!shipping.district) emptyFields.push('District');

    if (!shipping.address) emptyFields.push('Address');

    errorMessage = emptyFields.length !== 0 ? `${emptyFields.join(', ')} ${
      emptyFields.length > 1 ? 'are required. ' : 'is required. '
    } ` : errorMessage;

    const numberIsValid = /(^(\+88|0088)?(01){1}[3456789]{1}(\d){8})$/.test(
      shipping.receiverPhone
    );

    if (shipping.receiverPhone && !numberIsValid) {
      setAlert({
        show: true,
        message: "Invalid phone number found.",
        type: 'warn',
        button: 'Close',
        onClose: alertCloseHandler,
      });

      return false;
    }

    if (emptyFields.length > 0) {
      setAlert({
        show: true,
        message: errorMessage,
        type: 'warn',
        button: 'Close',
        onClose: alertCloseHandler,
      });
      return false;
    }

    return true;
  };

  return (
    <>
      <Page path={breadcrumb} title={'Request Order'}>
        <Card
          className={'text-center text-semibold grid-col-1-2 mb-16'}
          variant={'secondary'}
        >
          Note: Product Availability & Price Will Be Confirmed Over
          Phone/E-Mail/Whatsapp. Delivery Charge Within Dhaka City 80TK &
          Outside Dhaka 120TK.
        </Card>
        <div className='grid grid-1-1 gap-16 request-order__row'>
          <div className='flex flex-column gap-16'>
            <Card>
              <Table
                columnDefinitions={columnDefinitions}
                data={products}
                inRowForm
              />
            </Card>
            <Prescriptions />
          </div>
          <div className='flex flex-column gap-16'>
            <Address />
            <Textarea placeholder={'Add Note'} ref={remarksRef} />
          </div>
        </div>
        <div className='flex justify-center flex-column gap-8 align-center'>
          <label className='flex justify-center align-center gap-16 m-0'>
            <input type='checkbox' className='w-unset m-0' ref={checkboxRef} />
            <p>
              have read and agreed to the{' '}
              <Link to={'/TermsCondition'} className='hover-text-underline'>
                Terms and conditions
              </Link>
            </p>
          </label>
          <Button onClick={orderClickHandler}>Send Product Request</Button>
        </div>
        <Alert
          onClose={alert.onClose}
          show={alert.show}
          text={alert.message}
          variant={alert.variant}
          button={alert.button}
        />
        <RequestProductAlert show={successed} />
        <Loader isLoading={isLoading} />
      </Page>
    </>
  );
};

export default RequestOrder;
