import React from 'react';

const DiscountTag = ({amount, percentage}) => {

    const empty = <></>;

    const tag = <div class='group-price-drag'>
                    <span class='product-new-drag'>
                        {percentage} %
                    </span>
                </div>;

    return amount === 0 ? empty : tag;
};

export default DiscountTag;