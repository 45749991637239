import React from "react";
import { Link } from "react-router-dom";
import { getHomeCategories } from "../../../Service/DataService";
import { urlCategoryWiseRoute } from "../../../Service/UrlService";
import ProductCard from "../../Shared/ProductCard/ProductCard";
import Slider from "../../utilities/Slider";
import CategorySlider from "./ShowCase Gallery/CategorySlider";

const CategoryWiseProducts = () => {
  const options = {
    rewind: true,
    type: "slide",
    drag: "free",
    autoplay: false,
    rewindSpeed: 1000,
    speed: 1000,
    pauseOnHover: true,
    autoWidth: true,
    width: "100%",
    perPage: 3,
    perMove: 3,
    autoScroll: {
      speed: 1,
    },
    pagination: false,
  };

  const homeCategories = getHomeCategories();

  return (
    <>
      <section class="product-main-area">
        <div class="container_product">
          {homeCategories.map((item) => {
             return (
              <div className="product_scroll_container">
                <CategorySlider 
                  categoryId={item.id} 
                />
                <div class="common-heading">
                  <h1>{item.categoryName}</h1>
                  <span style={{cursor: 'pointer'}}><Link style={{color: 'white'}} to={urlCategoryWiseRoute() + item.id}>view All</Link></span>
                </div>
                <div class="spices-auto-scroll mt-20">
                  <div class="slide-track splide__track product-main-flex">
                    <ul class="splide__list">
                      <Slider
                        options={options}
                        Template={ProductCard}
                        data={item.products}
                      />
                    </ul>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default CategoryWiseProducts;
