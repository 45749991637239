import styles from './Categories.module.css';
import Category from './Category/Category';
import downArrowIcon from '../../../../icons/down-arrow.svg';

const Categories = () => {
    return (
        <div className={styles.categories}>
           <button className='flex justify-between align-center'>Categories 
            <div className='w-16 flex flex-center'>
                <img src={downArrowIcon} alt={'Hover to Open Category'} className={'w-max'} />
            </div>
           </button>
           <div className={styles.categories__content}>
                <Category />
           </div>
        </div>
    );
};

export default Categories;