import styles from './Alert.module.css';
import { createPortal } from 'react-dom';
import Button from '../../Shared/Button/Button';

const Alert = ({ show, text, button = 'Close', onClose, variant = 'warn', textAlign='center' }) => {
  if (!show) return <></>;
  const alertEl = document.querySelector('#alert');
  return (
    <>
      {createPortal(
        <div className={styles.backdrop}>
          <div className={styles.alert}>
          <p>{text}</p>
          <Button onClick={onClose} outline variant={variant}>Close</Button>
          </div>
        </div>,
        alertEl
      )}
    </>
  );
};

export default Alert;
