import React from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import useAuthGuard from '../../hooks/useAuthGuard';
import { GET_ALL_ORDER } from '../../lib/endpoints';
import { humanizeShortDateTime } from '../../lib/utilities';
import { postV2 } from '../../Service/http-service-v2';
import Page from '../Shared/Page/Page';
import Table from '../Shared/Table/Table';
import Tab from '../Shared/Tabs/TabList/Tab/Tab';
import TabList from '../Shared/Tabs/TabList/TabList';
import Tabs from '../Shared/Tabs/Tabs';

const breadcrumb = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'Purchases',
    url: '/Purchases',
  },
];

const Purchases = () => {
  // useAuthGuard();
  const [isLoading, setIsLoading] = useState(false);
  const [orders, setOrders] = useState({
    Data: [],
    PageNumber: 1,
    PageSize: 10,
    Total: 0,
  });

  const columnDefinitions = [
    {
      title: 'Order No',
      field: 'OrderNo',
    },
    {
      title: 'Status',
      field: 'Status',
    },
    {
      title: 'Date',
      field: 'CreatedAt',
      mapper: (date) => {
        return date ? humanizeShortDateTime(date) : '';
      },
    },
  ];

  const getOrders = useCallback((page, pageSize) => {
    const payload = {
      filter: [],
      pageNumber: page,
      pageSize: pageSize,
    };

    postV2({ url: GET_ALL_ORDER, payload })
      .then((res) => {
        if (!res.IsError) {
          setOrders(res.Data);
        } else {
          alert(res.Msg);
        }
      })
      .catch((error) => {
        alert(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    getOrders(orders.PageNumber, orders.PageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pageChangeHandler = (page) => {
    getOrders(page, orders.PageSize);
  };

  return (
    <Page path={breadcrumb} title={'Purchases'}>
      <Tabs>
        <TabList>
          <Tab value={1}>Orders</Tab>
          <Tab value={2}>Requests</Tab>
        </TabList>
        {/* <TabPanel value={1}>
          <Table
            isLoading={isLoading}
            data={orders.Data}
            columnDefinitions={columnDefinitions}
            indexed
            paginate
            page={orders.PageNumber}
            perPage={orders.PageSize}
            total={orders.Total}
            onPageChange={pageChangeHandler}
          />
        </TabPanel>
        <TabPanel value={2}>
          <Table
            isLoading={isLoading}
            data={orders.Data}
            columnDefinitions={columnDefinitions}
            indexed
            paginate
            page={orders.PageNumber}
            perPage={orders.PageSize}
            total={orders.Total}
            onPageChange={pageChangeHandler}
          />
        </TabPanel> */}
      </Tabs>
    </Page>
  );
};

export default Purchases;
