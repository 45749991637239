import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import authContextV2 from '../../../store/auth-context-v2';
import checkoutContext from '../../Checkout/store/checkout-context';
import Button from '../../Shared/Button/Button';

const RequestProductAlert = ({show}) => {
  const { isAuthenticated } = useContext(authContextV2);
  const { shipping } = useContext(checkoutContext);
  const history = useHistory();
  
  const closeClickHandler = () => {
    history.push('/');
  }

  const viewOrderClickedHandler = () => {
    history.push('/Profile/Prescription/Request');
  };
  
  if(!show) return <></>;
  return (
    <div className='top-0 left-0 fixed backgraound-backdrop flex justify-center w-screen h-screen'>
      <div className="background-background p-16 absolute mt-20h flex flex-column gap-16 flex-center border-radius-4">
        <h1 className='text-24'>Order Placed Successfully</h1>
        <p>Lazz Pharma agent will call this {shipping.receiverPhone} number to confirm.</p>
        {!isAuthenticated && <p className='text-center text-12 text-primary text-semibold'>Login to view your orders</p>}
        <div className='flex gap-16'>
          <Button onClick={closeClickHandler} outline>Close</Button>
          <Button onClick={viewOrderClickedHandler}>{isAuthenticated ? 'View Order' : 'Login'}</Button>
        </div>
      </div>
    </div>
  );
};

export default RequestProductAlert;
