export const PAYMENT_POLICIES =
    [
        'Please pay first for outside Dhaka delivery (ঢাকার বাইরে অর্ডারের ক্ষেত্রে ক্যাশ অন ডেলিভারি প্রযোজ্য নয়)',
        'LP agent will call you for delivery charge and reconfirm your order (আপনার অর্ডার পুনরায় নিশ্চিত করতে লাজ ফার্মা থেকে আপনার সাথে যোগাযোগ করা হবে)'
    ];

    export const PAYMENT_OPTIONS = {
        TWENTY_PERCENT: 'TWENTY_PERCENT',
        HUNDRED_PERCENT: 'PAY_NOW',
        PAY_LATER: 'CASH_ON_DELIVERY'
    }