import React from 'react';

const Link = ({ onClick, icon, text, href, title, className }) => {
  const clickHandler = () => {
    onClick && onClick();
  };
  return (
    <li
      onClick={clickHandler}
      className={'second_layer__link hover-background-secondary transition-200 background-primary overflow-hidden border-radius-4 flex flex-center h-36 cursor-pointer ' + className ?? ''}
      title={title}
    >
      {!!href && (
        <a href={href} className='flex flex-center flex-nowrap gap-4 px-8 py-4 background-transparent cursor-pointer'>
          <div className='w-16 flex flex-center'>
            <img src={icon} alt='user' className='w-max' />
          </div>
        </a>
      )}
      {!!!href && <button className='flex flex-center flex-nowrap gap-4 px-8 py-4 background-transparent cursor-pointer'>
        <div className='w-16 flex flex-center'>
          <img src={icon} alt='user' className='w-max' />
        </div>
        <p className='text-semibold text-white text-capitalize text-14'>{text}</p>
      </button>}
    </li>
  );
};

export default Link;
