import { useContext } from "react";
import { useEffect } from "react";
import appContext from "../store/app-context";

const useCloseOnOutsideClick = (ref, action) => {
    const { searchQuery } = useContext(appContext);

    useEffect(() => {

        // Function for click event
        function handleOutsideClick(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                if(searchQuery.isCloseOnOutSideClideDisabled){
                    //alert(searchQuery.isCloseOnOutSideClideDisabled);
                }
                else {
                    action();
                } 
            }
        }

        // Adding click event listener
        document.addEventListener("click", handleOutsideClick);

    }, [ref]);
}

export { useCloseOnOutsideClick }