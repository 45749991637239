import React from 'react';

const Vertical = ({ images }) => {

  return (
    <div className='banner_image_category category-gallery-shadow'>
      {images.map((item) => (
        <>
          {!!!item.link && (
            <span key={item.id}>
              <img
                src={window.BaseUrl + item.image}
                alt={item.altrText}
                className={item.link && 'pointer'}
              />
            </span>
          )}
          {!!item.link && !item.link?.startsWith('tel') && (
            <span key={item.id}>
              <img
                src={window.BaseUrl + item.image}
                alt={item.altrText}
                className={item.link && 'pointer'}
              />
            </span>
          )}
          {!!item.link && item.link?.startsWith('tel') && (
            <span key={item.id}>
              <img
                src={window.BaseUrl + item.image}
                alt={item.altrText}
                className={item.link && 'pointer'}
              />
            </span>
          )}
        </>
      ))}
    </div>
  );
};

export default Vertical;
