import { useEffect, useState, useCallback } from 'react';
import { BRANCH_LOCATIONS } from '../../../lib/endpoints';
import { post } from '../../../Service/http-service-v2';
import Paginator from '../../Paginators/Paginators';
import Card from '../../Shared/Card/Card';
import Table from '../../Shared/Table/Table';
import Input from '../../Shared/Input/Input';

const BranchTable = ({mapModel}) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState({ items: [], total: 0 });
  const [isGetting, setIsGetting] = useState(true);
  const [search, setSearch] = useState('');
  const [params, setParams] = useState({
    filter: [],
    pageNumber: 1,
    pageSize: 3,
  });

  const onRowClick=(data,index,columnDefinitions)=>{
      console.log(['data,index,columnDefinitions',data,index,columnDefinitions]);


       var map =mapModel.Get(),  
       infowindowContent = mapModel.infowindowContent,  
       infowindow = mapModel.infowindow,  
       markerDic = mapModel.markerDic;
       
       infowindowContent.children["place-name"].textContent = data.Name + ' Branch';
       infowindowContent.children["place-address"].textContent = data.Location;
       infowindowContent.children["place-phone"].textContent = 'Mobile : ' + data.Mobile;
       infowindow.open(map, markerDic[data.Id]);
       map.setCenter({ lat: data.Lat, lng: data.Lng });
       map.setZoom(17); // Why 17? Because it looks good.
  };

  const pageChangeHandler = (page) => {
    setParams((prevState) => ({ ...prevState, pageNumber: page }));
    paginate(data, page, params.pageSize);
  };

  const getData = () => {
    const payload = {
      filter: [],
      pageNumber: 1,
      pageSize: 1000,
    };
    return post({ url: BRANCH_LOCATIONS, payload })
      .then((res) => {
        setData(res.Data.Data);
        setIsGetting(false);
        paginate(res.Data.Data, params.pageNumber, params.pageSize);
      })
      .catch((error) => {
        alert(error);
        setIsGetting(false);
      });
  };

  const paginate = useCallback((branches = [], page, perPage) => {
    const items = branches.slice(
      perPage * (page - 1),
      perPage * (page - 1) + perPage
    );
    setPage({ items, total: branches.length });
  }, []);

  const searchChangeHandler = (search) => {
    search = search.toLowerCase();
    let items =
      search.length > 0
        ? data.filter(
            (item) =>
              item.Name.toLowerCase().includes(search) ||
              item.Mobile.toLowerCase().includes(search) ||
              item.Location.toLowerCase().includes(search)
          )
        : data;

    setPage({ items, total: items.length });
    setSearch(search);
    setParams((prevState) => ({ ...prevState, pageNumber: 1 }));
    paginate(items, 1, params.pageSize);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columnDefinitions = [
    {
      title: 'Branch Name',
      field: 'Name',
    },
    {
      title: 'Contact Number',
      field: 'Mobile',
    },
    {
      title: 'Location',
      field: 'Location',
    },
  ];

  return (
    <Card>
      <Input
        onChange={searchChangeHandler}
        placeholder={'Search By Branch, Contact, Location'}
        className={'mb-16'}
        value={search}
      />
      <Table
        indexed
        page={params.pageNumber}
        perPage={params.pageSize}
        total={page.total}
        columnDefinitions={columnDefinitions}
        data={page.items}
        onRowClick={onRowClick}
      />
      <Paginator
        items={page.total}
        pageItems={params.pageSize}
        startPage={params.pageNumber}
        onPageChange={pageChangeHandler}
        current={params.pageNumber - 1}
      />
    </Card>
  );
};

export default BranchTable;
