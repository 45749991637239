import { PAYMENT_POLICIES } from '../checkout.util';
import styles from './Policy.module.css';

const Policy = () => {
  return (
    <div className={styles['order-policy']}>
      {PAYMENT_POLICIES.map((p) => (
        <p className='OrderNotice'>
          *** {p}
        </p>
      ))}
    </div>
  );
};

export default Policy;
