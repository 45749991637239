import { useEffect, useRef } from 'react';
import Card from '../../Shared/Card/Card';

const Map = ({mapModel}) => {
  let mapRef = useRef(null);

  useEffect(() => {
    window.initMap(mapRef.current,mapModel);
  }, []);
  return (
    <Card>
      <div
        className='map'
        style={{height: 400 }}
        ref={mapRef}
      ></div>
    </Card>
  );
};

export default Map;
