import { forwardRef, useCallback, useContext, useEffect, useRef } from 'react';
import appContext from '../../store/app-context';
import styles from './Header.module.css';
import MobileLayer from './MobileLayer/MobileLayer';
import Navbar from './Navbar/Navbar';
import SecondLayer from './SecondLayer/SecondLayer';
import TopLayer from './Toplayer/TopLayer';

const Header = forwardRef((props, stickyNavRef) => {
  const { storeUserMenuSettings } = useContext(appContext);

  const navTopRef = useRef();
  const navMidRef = useRef();

  const handleScroll = useCallback(() => {
    // const offset = window.scrollY;
    // const navTopHeight = navTopRef.current?.clientHeight;

    // if (offset > navTopHeight) {
    //   stickyNavRef.current.style.top = `${-navTopHeight}px`;
    // } else if (offset < navTopHeight) {
    //   stickyNavRef.current.style.top = `${0}px`;
    // }
  }, [stickyNavRef]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    storeUserMenuSettings({
      positionY:
        navTopRef.current?.clientHeight + navMidRef.current?.clientHeight,
      diff: navTopRef.current?.clientHeight,
    });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.header} ref={stickyNavRef}>
      <TopLayer ref={navTopRef}/>
      <SecondLayer ref={navMidRef}/>
      <MobileLayer />
      <Navbar />
    </div>
  );
});

export default Header;
