import { createContext } from "react";

const appContext = createContext({
  searchQuery: {
    storeSearchQuery: (text = "") => { },
    searchQuery: "",
    disableCloseOnClick: (beDisabled = false) => { },
    isCloseOnOutSideClideDisabled: false,
    lockSearchResult: (isLock = true) => { },
    isLocked: false,
  },

  orderCreated: {
    orderStatus: (isChecked) => { },
    currentOrderStatus: false,
  },

  unseenNotification: 0,
  updateUnseenNotificaion: (n = 0) => {},
  
  userMenuIsOpened: false,
  userMenuSettings: {
    positionY: 0,
    diff: 0
  },
  openUserMenu: () => {},
  closeUserMenu: () => {},
  toggleUserMenu: () => {},
  storeUserMenuSettings: ({positionY, diff }) => {},

  toggleNavbar: (open) => {},
  navbarIsOpened: false
});

export default appContext;
