import { useEffect, useRef, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import appContext from '../../../../store/app-context';
import Card from '../../../Shared/Card/Card';
import { links } from '../navbar.util';
import styles from './MobileNav.module.css';

const MobileNav = () => {
  const { navbarIsOpened, toggleNavbar } = useContext(appContext);

  const ref = useRef(null);

  const closeClickHandler = (e) => {
    toggleNavbar();
  };

  useEffect(() => {
    if (navbarIsOpened) ref.current.style.right = '0';
    else ref.current.style.right = '100vw';
  }, [navbarIsOpened]);

  return (
    <div className={styles.mobile_nav} ref={ref}>
      <div>
        {links.map((link) => (
          <NavLink
            to={link.url}
            activeClassName={styles['mobile_nav__link--active']}
            exact
          >
            <Card
              className={`cursor-pointer hover-background-gainsboro transition-200 flex align-center gap-4 justify-between ${styles.card}`}
              onClick={closeClickHandler}
            >
              <p className='text-14 text-bold text-gray'>{link.title}</p>
            </Card>
          </NavLink>
        ))}
        <Card
          className={`cursor-pointer transition-200 flex align-center gap-4 justify-between ${styles.close}`}
          onClick={closeClickHandler}
        >
          <p className='text-14 text-bold text-gray text-center'>Close</p>
        </Card>
      </div>
    </div>
  );
};

export default MobileNav;
