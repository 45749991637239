import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { imageURL, IMAGE_OF, IMAGE_SIZE } from '../../Service/imageResolver';
import cartContext from '../../store/cart-context';
import Alert from '../Lib/Alert/Alert';
import Button from '../Shared/Button/Button';

const ContentCart = ({
  openCart,
  setIsOrderNowPressed,
  closeAuthModalHandler,
}) => {
  const cartCtx = useContext(cartContext);
  const [qty, setQty] = useState('');
  const history = useHistory();
  const cartCtxModal = cartCtx.getCartModel;
  const [alert, setAlert] = useState({ show: false, text: '', type: 'ERROR' });

  const cartItemRemoverHandler = (item) => {
    cartCtx.singleItemRemover(item);
  };

  const clearCartHandler = (e) => {
    openCart();
    cartCtx.clearCart();
  };

  const qtyDecHandler = (findItem, e) => {
    e.preventDefault();
    let quantity = findItem.quantity - 1;
    cartCtx.updateQuantity(findItem, quantity);
  };

  const qtyIncHandler = (findItem, e) => {
    e.preventDefault();
    let quantity = findItem.quantity + 1;
    cartCtx.updateQuantity(findItem, quantity);
  };

  const qtyChangeHandler = (item, { target }) => {
    if (target.value === '') {
      setQty(0);
    } else {
      setQty(target.value);
    }
    cartCtx.updateEditableQuantity(item, target.value);
    console.log({ item });
  };

  const blurHandler = (item) => {
    if (qty === 0) {
      alert("Quantity can't be less than 1.");
      cartCtx.updateEditableQuantity(item, 1);
      setQty(1);
    }
  };

  const orderClickHandler = () => {
    if (cartCtxModal.Items.length === 0) {
      setAlert({
        show: true,
        text: `There is no product in the shopping bag`,
        type: 'ERROR',
      });
      return;
    }

    history.push('/Checkout');

    openCart();
  };

  const alertCloseHandler = () => {
    setAlert({
      show: false,
      text: '',
    });
    openCart();
  };

  return (
    <div class='cart-box-view'>
      <div class='cart-box-inner-view'>
        <div class='flex justify-between align-center background-secondary p-4'>
          <div class='flex flex-center gap-4'>
            <img
              class='header-bag'
              src='/Contents/assets/image/add-cart.png'
              alt='img'
            />
            <strong class='car-box-title SearchFont m-0'>
              <span className='text-white'>{cartCtxModal.TotalItems}</span>
              <span className='text-white'> Item</span>
            </strong>
          </div>
          <div class='text-bold text-white cursor-pointer px-4 py-2 border-radius-4 hover-background-warn' onClick={openCart}>
            &#10006;
          </div>
        </div>
        <div class='cart-body text-center'>
          <div class='cart-table-wrap'>
            <span>Happy Shopping!! </span>
            <table
              style={{ width: '100%', fontSize: '11px' }}
              class='cart-table'
            >
              <tbody>
                {cartCtxModal.Items.map((item) => (
                  <tr>
                    <td>
                      <img
                        src={
                          item.image
                            ? imageURL(
                                item.image,
                                IMAGE_OF.PRODUCT,
                                IMAGE_SIZE.ICON
                              )
                            : '/Contents/assets/image/cartLogo.jpg'
                        }
                        alt='img'
                      />
                    </td>
                    <td className='card-title-product'>
                      <Link to={`/Product/Details/${item.permalink}`}>
                        <span class='SearchProductName SearchFont'>
                          {item.Nm} {item.St} {item.Ct}
                        </span>
                      </Link>
                      <br />
                      {item.discount > 0 && item.discount !== null && (
                        <del
                          class='SearchDelPrice'
                          style={{ fontSize: '14px', marginRight: '5px' }}
                        >
                          ৳ {item.MRP}
                        </del>
                      )}

                      <strong class='SearchPrice' style={{ fontSize: '15px' }}>
                        ৳ {(item.MRP - item.discount).toFixed(2)}
                      </strong>
                    </td>
                    <td
                      className='cart-inc-dsc'
                      style={{
                        fontSize: '13px',
                        verticalAlign: 'middle',
                        padding: '8px 0',
                      }}
                    >
                      <div class='attributes input-group bootstrap-touchspin'>
                        <div class='qty-holder' style={{ width: '90px' }}>
                          <a
                            href
                            class='qty-dec-btn'
                            title='Dec'
                            style={{ width: '22px' }}
                            onClick={qtyDecHandler.bind(this, item)}
                          >
                            -
                          </a>
                          <input
                            style={{ width: '22px !important' }}
                            type='text'
                            name='product_qty'
                            id='product_qty'
                            class='qty-input'
                            value={item.quantity}
                            onChange={qtyChangeHandler.bind(null, item)}
                            onBlur={blurHandler.bind(null, item)}
                          />
                          <a
                            href
                            class='qty-inc-btn'
                            title='Inc'
                            style={{ width: '22px' }}
                            onClick={qtyIncHandler.bind(this, item)}
                          >
                            +
                          </a>
                        </div>
                      </div>
                    </td>
                    {item.discount > 0 && item.discount !== null && (
                      <td
                        className='dis-new-con'
                        style={{ whiteSpace: 'nowrap' }}
                      >
                        {item.discount > 0 && item.discount !== null && (
                          <>
                            <span class='SearchFont SearchDelPrice'>
                              <aside>৳</aside>
                            </span>
                            <del>{item.MRP * item.quantity}</del>
                            <br />
                            <span class='SearchFont SearchPrice'>
                              {(
                                (item.MRP - item.discount) *
                                item.quantity
                              ).toFixed(2)}
                            </span>
                          </>
                        )}
                      </td>
                    )}
                    {item.discount === 0 && (
                      <td style={{ verticalAlign: 'middle' }}>
                        <span class='SearchFont SearchPrice'>
                          {(item.MRP * item.quantity).toFixed(2)}
                        </span>
                      </td>
                    )}

                    <td>
                      <a
                        className='cross_time'
                        href
                        onClick={cartItemRemoverHandler.bind(null, item)}
                      >
                        <i class='fa fa-times text-danger'></i>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className='product_items__count__container'>
          <div className='cmn-class-items-calc total__items'>
            <p>Total Items</p>
            <span>{cartCtxModal.TotalItems}</span>
          </div>
          <div className='cmn-class-items-calc total__ammount'>
            <p>Total Ammount</p>
            <span>{cartCtxModal.TotalAmmount.toFixed(2)}tk</span>
          </div>
        </div>
        <div class='cart-footer'>
          <Button className={'flex-1'} onClick={orderClickHandler}>Order Now</Button>
          <Button className={'flex-1'} onClick={clearCartHandler} variant='warn'>Clear Cart</Button>
          
        </div>
      </div>
      <Alert show={alert.show} text={alert.text} onClose={alertCloseHandler} />
    </div>
  );
};

export default ContentCart;
