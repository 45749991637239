import { useEffect, useRef, useContext, useState, useCallback } from 'react';
import { createPortal } from 'react-dom';
import appContext from '../../../../../../store/app-context';
import styles from './Menu.module.css';
import purchaseIcon from '../../../../../../icons/purchase.svg';
import bellIcon from '../../../../../../icons/bell.svg';
import prescriptionIcon from '../../../../../../icons/prescription.svg';
import dollarIcon from '../../../../../../icons/dollar.svg';
import signoutIcon from '../../../../../../icons/signout.svg';
import Profile from './Profile/Profile';
import authContextV2 from '../../../../../../store/auth-context-v2';
import Navigator from './Navigator/Navigator';
import Notification from './Notification/Notification';

const Menu = () => {
  const { userMenuIsOpened: isOpened, userMenuSettings, closeUserMenu: close } =
    useContext(appContext);

  const { profile, user } = useContext(authContextV2);

  const ref = useRef(null);

  const [offset, setOffset] = useState(0);

  const menuEl = document.querySelector('#menu');

  const handleScroll = useCallback(() => {
    // const offsetY = window.scrollY;
    // const navTopHeight = userMenuSettings.diff;

    // if (offsetY > navTopHeight) {
    //   setOffset(-userMenuSettings.diff);
    // } else if (offsetY < navTopHeight) {
    //   setOffset(0);
    // }
  }, [userMenuSettings]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return (
    <>
      {createPortal(
        isOpened && (
          <div
            className={`${styles.menu}`}
            style={{ top: userMenuSettings.positionY + offset }}
            ref={ref}
          >
            <Profile
              image={user.image}
              name={user.name}
              phone={user.phone}
              due={profile.debit}
              wallet={profile.credit}
            />
            <Navigator name={'Purchases'} icon={purchaseIcon} to={'/Purchases'} onClick={close} />
            <Notification count={25} name={'Notifications'} icon={bellIcon} to={'/Notifications'} onClick={close} />
            <Navigator name={'Prescriptions'} icon={prescriptionIcon} to={'/Prescriptions'} onClick={close} />
            <Navigator name={'Transactions'} icon={dollarIcon} to={'/Transactions'} onClick={close} />
            <Navigator name={'Logout'} icon={signoutIcon} />
          </div>
        ),
        menuEl
      )}
    </>
  );
};

export default Menu;
