import { useContext } from 'react';
import { useState } from 'react';
import { COUPON_CHECK } from '../../../lib/endpoints';
import http from '../../../Service/http-service-v2';
import cartContext from '../../../store/cart-context';
import Alert from '../../Lib/Alert/Alert';
import Card from '../../Shared/Card/Card';
import checkoutContext from '../store/checkout-context';

const Coupon = ({ setLoading }) => {
  const { storeCoupon } = useContext(checkoutContext);
  const { getCartModel: cart } = useContext(cartContext);

  const [code, setCode] = useState('');
  const [alert, setAlert] = useState({ show: false, text: '', type: 'ERROR' });


  const changeHandler = ({ target }) => {
    setCode(target.value);
  };

  const submitHandler = () => {
    if (code.length === 0) {
      setAlert({
        show: true,
        text:  'Can\'t submit empty field',
      });
      return;
    }

    setLoading(true);

    const payload = {
      CouponCode: code,
      OrderAmount: cart.TotalAmmount,
      OrderDiscount: 0,
      ActivityId: window.ActivityId,
    };

    http
      .post({ url: COUPON_CHECK, payload })
      .then((res) => {
        setAlert({
          show: true,
          text:  `You will receive ${+res.Data.toFixed(2)}Tk discount for this order`,
        });
        storeCoupon(res.Id, res.Data);
        setLoading(false);
      })
      .catch((error) => {
        setAlert({
          show: true,
          text:  error.toString(),
        });
        console.log(error);
        setLoading(false);
      });
  };

  const alertCloseHandler = () => {
    setAlert({
      show: false,
      text: '',
    });
  }

  return (
    <Card class='discount-cupon-payment'>
      <form id='discount_codeSubmit'>
        <input
          type='text'
          id='discount_code'
          placeholder='Coupon Code...'
          onChange={changeHandler}
        />
        <button type='button' onClick={submitHandler}>
          Apply
        </button>
      </form>
      <Alert onClose={alertCloseHandler} show={alert.show} text={alert.text}/>
    </Card>
  );
};

export default Coupon;
