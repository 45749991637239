import React from 'react';
import Page from '../Shared/Page/Page';

const Prescriptions = () => {
    const breadcrumb = [
        {
          title: 'Home',
          url: '/',
        },
        {
          title: 'Prescriptions',
          url: '/Prescriptions',
        },
      ];

    return (
        <Page path={breadcrumb} title={'Prescriptions'}>
            Prescriptions
        </Page>
    );
};

export default Prescriptions;