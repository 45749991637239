import Prescription from "./Prescription/Prescription";

const Prescriptions = ({ prescriptions = [] }) => {
  if (!prescriptions.length) return <></>;

  return (
    <div className="prescription_card_orderDetails">
      <div className="image_preview_container">
        <div className="image_previewer">
          {prescriptions.map((prescription) => (
            <Prescription 
                path={prescription.FilePath} 
                key={prescription.id}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Prescriptions;
