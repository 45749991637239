import React from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { imageURL, IMAGE_OF, IMAGE_SIZE } from "../../../../Service/imageResolver";

const ShowCaseGalleryTemplate = ({ item }) => {
  const ref = useRef();

  useEffect(() => {
    if(item.link){
      ref.current.href = item.link;
    }
  }, [item]);

  return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid  
      <a ref={ref} className={item.link ? '' : 'cursor-default'}>
      <img
        class="img-responsive"
        alt={item.altrText}
        src={imageURL(item.image, IMAGE_OF.BANNER, IMAGE_SIZE.ORIGINAL)}
        style={{ margin: "0px auto" }}
      />
    </a>
  );
};

export default ShowCaseGalleryTemplate;
