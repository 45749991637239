import React from 'react';
import styles from './Button.module.css';

const Button = ({
  onClick,
  label,
  type = 'button',
  outline = false,
  children,
  className,
  variant = '',
}) => {
  return (
    <button
      className={`${styles.button} ${outline && styles['button--outline']} ${
        !!variant && styles[variant]
      } ${className || ''}`}
      onClick={onClick}
      type={type}
    >
      {label || children}
    </button>
  );
};

export default Button;
