import React from "react";
import { getNotices } from "../../../Service/DataService";
import Noticebar from "../../Home/Noticebar/Noticebar";
import CategoryWiseProducts from "./CategoryWiseProducts";
import ShowCasegallery from "./ShowCase Gallery/ShowCasegallery";

const HomeProducts = () => {
  const notices = getNotices();

  return (
    <div id="body_parent">
      {/* <NavHeader /> */}
      <div id="body_content">
        {/* <Banner /> */}
        <div class="content-page">
          {/* <div class="tcontainer">
            <div class="ticker-wrap">
              <div class="ticker-move">
                {notices.map(n => <p>{n}</p> )}
              </div>
            </div>
          </div> */}
          <Noticebar texts={notices}/>
          <CategoryWiseProducts />
        </div>
      
        {/* <FeaturedProducts /> */}
       
       <ShowCasegallery />
      </div>
      {/* here shopping cart  */}
    </div>
  );
};

export default HomeProducts;
