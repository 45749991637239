import { useContext } from 'react';
import checkoutContext from '../../store/checkout-context';
import styles from './PaymentOption.module.css';

const PaymentOption = ({ title, uniqueName }) => {
  const { storePaymentOption, paymentOption } = useContext(checkoutContext);

  const clickHandler = () => {
    storePaymentOption(uniqueName);
  }

  return (
    <div className={`${styles['payment-option']} ${paymentOption === uniqueName ? styles.active : ''}`}>
      <label className='' onClick={clickHandler}>
        <input
          type='radio'
          name='payment-option'
          className={styles['payment-option']}
          checked={paymentOption === uniqueName}
          onChange={()=>{}}
        />
        {title}
      </label>
    </div>
  );
};

export default PaymentOption;
