import React from 'react';
import { forwardRef } from 'react';
import Links from './Links/Links';
import Logo from './Logo/Logo';
import Search from './Search/Search';
import styles from './SecondLayer.module.css';

const SecondLayer = forwardRef((props, ref) => {
  return (
    <div className={styles.second_layer} ref={ref}>
      <Logo />
      <Search />
      <Links />
    </div>
  );
});

export default SecondLayer;
