import React from 'react';
import Page from '../Shared/Page/Page';

const Notifications = () => {
    const breadcrumb = [
        {
          title: 'Home',
          url: '/',
        },
        {
          title: 'Notifications',
          url: '/Notifications',
        },
      ];

    return (
        <Page path={breadcrumb} title={'Notifications'}>
            Notifications
        </Page>
    );
};

export default Notifications;