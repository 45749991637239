import { NavLink } from 'react-router-dom';

const OrderHistoryHeader = () => {
  return (
    <nav class='niiceeTabBtn'>
      <NavLink
        activeClassName='active'
        className='orderBtn'
        to={'/Profile/OrderHistory/All'}
        exact
      >
        <button id='defaultOpen' class='tablinks'>
          All
        </button>
      </NavLink>

      <NavLink
        activeClassName='active'
        className='orderBtn'
        to={'/Profile/OrderHistory/Confirmed'}
        exact
      >
        <button class='tablinks'>Confirmed</button>
      </NavLink>
      <NavLink
        activeClassName='active'
        className='orderBtn'
        to={'/Profile/OrderHistory/Processing'}
        exact
      >
        <button class='tablinks'>Processing</button>
      </NavLink>
      <NavLink
        activeClassName='active'
        className='orderBtn'
        to={'/Profile/OrderHistory/Delivering'}
        exact
      >
        <button class='tablinks'>Delivered</button>
      </NavLink>
      <NavLink
        activeClassName='active'
        className='orderBtn'
        to={'/Profile/OrderHistory/Cancel'}
        exact
      >
        <button class='tablinks'>Canceled</button>
      </NavLink>
    </nav>
  );
};

export default OrderHistoryHeader;
