import React from 'react';
import Form from './Form/Form';
import Row from './Row/Row';

const Rows = ({ rows = [], columnDefinitions, indexed, inRowForm, page, perPage,onClick }) => {
  const startIndex = (page - 1) * perPage
  return (
    <tbody>
      {inRowForm && <Form columnDefinitions={columnDefinitions}/> }
      {rows.map((row, i) => (
        <Row key={row.Id} row={row} columnDefinitions={columnDefinitions} indexed={indexed} index={i} startIndex={startIndex} onClick={onClick} />
      ))}
    </tbody>
  );
};

export default Rows;

// {
//     field: '',
//     component: () => {},
// }
