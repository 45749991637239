import React, { useReducer } from 'react';
import {
  CLOSE_USER_MENU,
  OPEN_USER_MENU,
  TOGGLE_USER_MENU,
  UPDATE_UNSEEN_NOTIFICATION,
} from '../constants';
import appContext from './app-context';

const initialSearchQuery = {
  searchQuery: '',
  isCloseOnOutSideClideDisabled: false,
  isLocked: false,
};

const initialOrder = {
  orderStatus: false,
};

const initialState = {
  unseenNotification: 0,
  userMenuIsOpened: false,
  userMenuSettings: {
    positionY: 0,
    diff: 0
  },
  navbarIsOpened: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_UNSEEN_NOTIFICATION:
      return {
        ...state,
        unseenNotification: action.unseenNotification,
      };

    case OPEN_USER_MENU: {
      return {
        ...state,
        userMenuIsOpened: true,
      };
    }

    case CLOSE_USER_MENU: {
      return {
        ...state,
        userMenuIsOpened: false,
      };
    }

    case TOGGLE_USER_MENU: {
      const userMenuIsOpened = !state.userMenuIsOpened;
      return {
        ...state,
        userMenuIsOpened,
      };
    }

    case 'STORE_USER_MENU_SETTINGS': {
      return {
        ...state,
        userMenuSettings: {
          ...action.settings
        }
      };
    }

    case 'TOGGLE_NAVBAR': {
      return {
        ...state,
        navbarIsOpened: action.navbarIsOpened ?? !state.navbarIsOpened
      };
    }

    default:
      return { ...state };
  }
};

const productAnimeReducer = (state, action) => {
  if (action.type === 'STORE_SEARCH_QUERY') {
    return {
      ...state,
      searchQuery: action.searchQuery,
    };
  }

  if (
    action.type === 'DO_NOT_CLOSE_SEARCH_RESULT_WHEN_OUT_OF_STOCK_ALERT_SHOWN'
  ) {
    return {
      ...state,
      isCloseOnOutSideClideDisabled: action.isDisabled,
    };
  }

  if (action.type === 'LOCK_RESARCH_RESULT') {
    return {
      ...state,
      isLocked: action.isLocked,
    };
  }

  return initialSearchQuery();
};

const orderReducer = (state, action) => {
  if (action.type === 'STATUS_CHANGED_ORDER') {
    return {
      orderStatus: action.isChecked,
    };
  }
  return initialOrder;
};

const AppContextProvider = ({ children }) => {
  const [searchQueryState, dispatchSearchQuery] = useReducer(
    productAnimeReducer,
    initialSearchQuery
  );

  const [orderStatusState, dispatchOrderStatus] = useReducer(
    orderReducer,
    initialOrder
  );

  const [state, dispatch] = useReducer(reducer, initialState);

  const storeSearchQuery = (text) => {
    dispatchSearchQuery({ type: 'STORE_SEARCH_QUERY', searchQuery: text });
  };
  const lockSearchResult = (isLocked = true) => {
    dispatchSearchQuery({ type: 'LOCK_RESARCH_RESULT', isLocked });
  };
  const orderStatusFunction = (isChecked) => {
    dispatchOrderStatus({ type: 'STATUS_CHANGED_ORDER', isChecked: isChecked });
  };
  const disableCloseOnClick = (isDisabled) => {
    dispatchSearchQuery({
      type: 'DO_NOT_CLOSE_SEARCH_RESULT_WHEN_OUT_OF_STOCK_ALERT_SHOWN',
      isDisabled: isDisabled,
    });
  };

  // APP CONTEXT CORE
  const updateUnseenNotificaion = (unseen) => {
    dispatch({ type: UPDATE_UNSEEN_NOTIFICATION, unseenNotification: unseen });
  };

  const openUserMenu = () => {
    dispatch({ type: OPEN_USER_MENU });
  };

  const closeUserMenu = () => {
    dispatch({ type: CLOSE_USER_MENU });
  };

  const toggleUserMenu = () => {
    dispatch({ type: TOGGLE_USER_MENU });
  };

  const storeUserMenuSettings = ({positionY, diff }) => {
    dispatch({ type: 'STORE_USER_MENU_SETTINGS', settings: {positionY, diff } });
  };

  const searchQuery = {
    storeSearchQuery,
    disableCloseOnClick,
    lockSearchResult,
    isLocked: searchQueryState.isLocked,
    searchQuery: searchQueryState.searchQuery,
    isCloseOnOutSideClideDisabled:
      searchQueryState.isCloseOnOutSideClideDisabled,
  };

  const orderCreated = {
    orderStatus: orderStatusFunction,
    currentOrderStatus: orderStatusState.orderStatus,
  };

  const toggleNavbar = (navbarIsOpened) => {
    dispatch({ type: 'TOGGLE_NAVBAR', navbarIsOpened });
  }

  const context = {
    searchQuery: { ...searchQuery },
    orderCreated: { ...orderCreated },
    unseenNotification: state.unseenNotification,
    updateUnseenNotificaion,
    // USER PROFILE 
    userMenuIsOpened: state.userMenuIsOpened,
    userMenuSettings: state.userMenuSettings,
    storeUserMenuSettings,
    openUserMenu,
    closeUserMenu,
    toggleUserMenu,
    toggleNavbar,
    navbarIsOpened: state.navbarIsOpened
  };

  return <appContext.Provider value={context}>{children}</appContext.Provider>;
};

export default AppContextProvider;
