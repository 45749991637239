import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";
import URLS, {
  urlHomeRoute,
  urlOrderRoute,
  urlPrescriptionHistory,
  urlProfileComplain,
  urlProfileEditRoute,
  urlProfileRoute,
  urlRequestHistoryRoute,
} from "../../../Service/UrlService";
import authContextV2 from "../../../store/auth-context-v2";

const SidebarLinks = () => {
  const {logout} = useContext(authContextV2);
  let history = useHistory();
  const logoutHandler = () => {
    logout();
    history.push(urlHomeRoute());
  };
  return (
    <div class="profile-nav">
      <ul>
        <li class="">
          <NavLink
            activeClassName="active"
            to={'/Profile/OrderHistory'}
          >
            <i class="fa fa-angle-double-right" aria-hidden="true"></i>
            Order History
          </NavLink>
        </li>

        <li className="">
          <NavLink
            activeClassName="active"
            to={urlProfileRoute() + urlPrescriptionHistory()}
          >
            <i class="fa fa-angle-double-right" aria-hidden="true"></i>
            Prescription History
          </NavLink>
        </li>

        <li className="">
          <NavLink
            activeClassName="active"
            to={urlProfileRoute() + urlRequestHistoryRoute()}
          >
            <i class="fa fa-angle-double-right" aria-hidden="true"></i>
            Request History
          </NavLink>
        </li>
        {/* <li>
          <NavLink
            activeClassName="active"
            to={urlProfileRoute() + urlSpecialOfferRoute()}
            exact
          >
            <i class="fa fa-angle-double-right" aria-hidden="true"></i>Special
            Offer
          </NavLink>
        </li> */}
        <li>
          <NavLink
            activeClassName="active"
            to={urlProfileRoute() + urlProfileEditRoute()}
            exact
          >
            <i class="fa fa-angle-double-right" aria-hidden="true"></i>Edit
            Profile
          </NavLink>
        </li>
        <li>
          <NavLink
            activeClassName="active"
            to={urlProfileRoute() + urlProfileComplain()}
            exact
          >
            <i class="fa fa-angle-double-right" aria-hidden="true"></i>Submit
            Complain
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName="active" to={URLS.PAYMENT_HISTORY_URL} exact>
            <i class="fa fa-angle-double-right" aria-hidden="true"></i>
            Payment History
          </NavLink>
        </li>
        <li>
          <a href onClick={logoutHandler}>
            <i class="fa fa-angle-double-right" aria-hidden="true"></i>Logout
          </a>{" "}
        </li>
      </ul>
    </div>
  );
};

export default SidebarLinks;
