import React from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import appContext from '../../../../store/app-context';
import SearchProduct from '../../../SearchPortal/SearchProduct';
import styles from './Search.module.css';
import searchIcon from '../../../../icons/search.svg';
import { useCloseOnOutsideClick } from '../../../../hooks/useOutSideClickedHandler';

const Search = () => {
  const { searchQuery } = useContext(appContext);

  let searchRef = useRef(null);
  const [text, setText] = useState('');
  const [resultIsShown, setResultIsShown] = useState(false);

  useCloseOnOutsideClick(searchRef, () => {
    setResultIsShown(false);
  });

  const focusHandler = ({ target }) => {
    if (target.value?.length > 0) setResultIsShown(true);
    else setResultIsShown(false);
  };

  const changeHandler = ({ target }) => {
    if (target.value?.length > 0) setResultIsShown(true);
    else setResultIsShown(false);

    setText(target.value);
    console.log(target.value);
    searchQuery.storeSearchQuery(target.value);
  };

  const productClickHandler = () => {
    setResultIsShown(false);
  };

  return (
    <div className={styles.search} ref={searchRef}>
      <div className={styles.search__field}>
        <input
          type='text'
          onFocus={focusHandler}
          onChange={changeHandler}
          value={text}
        />
        <button>
          <div>
            <img src={searchIcon} alt={'Search'} className='w-max' />
          </div>
        </button>
      </div>
      <div className={styles.search__result}>
        {resultIsShown && (
          <SearchProduct
            searchValue={text}
            closeSearchHandler={() => {}}
            onProductClick={productClickHandler}
          />
        )}
      </div>
    </div>
    // <div class='col-xs-7 col-sm-4 col-lg-6 header-search-box' ref={searchRef}>
    //   <form class='form-inline'>
    //     <div class='form-group input-serach'>
    //       <input
    //         id='txt_product_search'
    //         autocomplete='off'
    //         type='text'
    //         value={searchValue}
    //         onChange={textChangeHandler}
    //         placeholder='Search by Trade or Generic Name...'
    //         style={{ fontSize: '16px' }}
    //         onFocus={searchFocusHandler}
    //       />
    //     </div>
    //     <button type='button' class='pull-right btn-search'>
    //       <i class='fa fa-search'></i>
    //     </button>
    //     {resultIsShown && (
    //       <SearchProduct
    //         searchValue={searchValue}
    //         closeSearchHandler={closeSearchHandler}
    //         onProductClick={productClickHandler}
    //       />
    //     )}
    //     <table
    //       cellspacing='0'
    //       class='table table-bordered table-striped SearchTable'
    //     >
    //       <tbody style={{ fontSize: '0.7em', display: 'none' }}></tbody>
    //     </table>
    //   </form>
    // </div>
  );
};

export default Search;
