import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import { GET_ALL_ORDER } from "../../../lib/endpoints";
import { paramsUrlGenerator } from "../../../lib/utilities";
import { http } from "../../../Service/httpService";
import {
  urlAllOrderRoutes,
  urlConfirmedRoutes,
  urlOrderCancelingRoute,
  urlOrderDelivaringRoute,
  urlOrderDetailsRoute,
  urlOrderProcessing,
  urlOrderRoute,
  urlProfileRoute,
} from "../../../Service/UrlService";
import authContextV2 from "../../../store/auth-context-v2";
import Suspense from "../../Suspense/Suspense";
import { OrderStatus } from "../../utilities/dictionaries";
import OrderDetails from "../OrderDetails/OrderDetails";
import OrderList from "../OrderList/OrderList";

const OrderHistoryBody = () => {
  const { logout } = useContext(authContextV2);
  const [ordersArray, setOrdersArray] = useState([]);
  const [confirmedOrders, setConfirmOrders] = useState([]);
  const [processingOrders, setProcessingOrders] = useState([]);
  const [delivaringOrders, setDelivaringdOrders] = useState([]);
  const [cancellingOrders, setCancellingdOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getAllOrdersHttp = useCallback(() => {
    http.get({
      url: GET_ALL_ORDER,
      before: () => {},
      successed: (res) => {
        setOrdersArray(res.Data.Data);
        setConfirmOrders(
          res?.Data.Data.filter((item) => item?.Status === OrderStatus.Confirmed)
        );
        setProcessingOrders(
          res?.Data.Data.filter((item) => item?.Status === OrderStatus.Processing)
        );
        setDelivaringdOrders(
          res?.Data.Data.filter((item) => item?.Status === OrderStatus.Delivering)
        );
        setDelivaringdOrders(
          res?.Data.Data.filter((item) => item?.Status === OrderStatus.Delivered)
        );
        setCancellingdOrders(
          res?.Data.Data.filter((item) => item?.Status === OrderStatus.Cancelled)
        );
        setIsLoading(false);
      },
      failed: () => {
        setIsLoading(true);
      },
      always: (res) => {
        setIsLoading(false);
        if(res.IsError){
          logout();
        }
      },
    });
  }, []);

  useEffect(() => {
    getAllOrdersHttp();
  }, [getAllOrdersHttp]);

  console.log({ordersArray})

  return (
    <Fragment>
      {!isLoading  && (
        <div>
          <Route path={'/Profile/OrderHistory'} exact>
            <Redirect to={'/Profile/OrderHistory/All'} />
          </Route>

          <Route path={'/Profile/OrderHistory/All'} exact>
            <OrderList ordersArray={ordersArray} />
          </Route>

          <Route path={'/Profile/OrderHistory/Confirmed'} exact>
            <OrderList ordersArray={confirmedOrders} />
          </Route>

          <Route path={'/Profile/OrderHistory/Processing'} exact>
            <OrderList ordersArray={processingOrders} />
          </Route>

          <Route path={'/Profile/OrderHistory/Delivering'} exact>
            <OrderList ordersArray={delivaringOrders} />
          </Route>

          <Route path={'/Profile/OrderHistory/Cancel'} exact>
            <OrderList ordersArray={cancellingOrders} />
          </Route>
        </div>
      )}
      {isLoading && <Suspense />}
    </Fragment>
  );
};

export default OrderHistoryBody;
