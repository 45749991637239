export const paramsUrlGenerator = (params) => {
  const url = "?";
  const paramsStrings = [];

  for (const [key, value] of Object.entries(params)) {
    paramsStrings.push(`${key}=${value}`);
  }

  return url + paramsStrings.join("&");
};

export const sumAProperty = (items = [], path) => {
  return items.reduce((total, product) => +product[path] + total, 0);
};

export const humanizeShortDateTime = (enData) => {
  const regex = /\d+/g;
  const timestamp = (enData.match(regex) || []).join("");

  if (typeof +timestamp === "number") {
    const date = new Date(+timestamp);

    return `${date.toLocaleString("en-GB", {
      dateStyle: "short",
    })} ${date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })}`;
  }

  return "Error parsing date";
};
export const searchItemsConvertObject = (item, area = null) => {
  return {
    Nm: item.Name,
    id: item.Id,
    MRP: item.UnitSalePrice,
    discountPercent: item.DiscountPercent,
    discount: item.Discount,
    St: item.Strength,
    GN: item.GenericName,
    image: item.ImagePath,
    Ct: item.Type,
    Sp: item.SupplierName,
    details: item.Details,
    Stk: 99999, // +item.TotalStock,
    area: area,
    permalink: item.Permalink
  };
};

export const tranformQuery = (text, func) => {
  const duplicateText = text;
  var query = text.split(" ");

  return query;
};

export const gotoTop = () => {
  setTimeout(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, 0);
};
