const Address = ({
  name,
  phone,
  slot,
  email,
  remarks,
  upazila,
  district,
  province,
}) => {
  return (
    <div class="shaping-address-saveing-row">
      <div class="shapping-address-inner-content">
        <div class="saving-address-content">
          <small>{name}</small>
          <small>{phone}</small>
          <span>
            {" "}
            {remarks}&nbsp;
            {upazila}
            &nbsp; {district}&nbsp;
            {province}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Address;
