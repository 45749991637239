import React from 'react';
import {
  imageURL,
  IMAGE_OF,
  IMAGE_SIZE,
} from '../../../../Service/imageResolver';
import defaultImage from '../../../../images/default-medicine.png';

const ProductImage = ({ url, name, className, size = IMAGE_SIZE.SMALL }) => {
  url = url ? imageURL(url, IMAGE_OF.PRODUCT, size) : defaultImage;

  return (
    <div className={className}>
      <img src={url} alt={name} />
    </div>
  );
};

export default ProductImage;
