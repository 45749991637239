import React, { useContext } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import authContextV2 from '../../../store/auth-context-v2';
import Button from '../../Shared/Button/Button';
import Loader from '../../utilities/Loader/Loader';

// URL: /Order/Success?OrderNo=0917005&Payment=0&Phone=01553501368&Id=
// URL: /Order/Success?OrderNo=0917005&Payment=1&Transaction=SUCCESS&Phone=01553501368&Id=
// URL: /Order/Success?OrderNo=0917005&Payment=1&Transaction=FAILED&Phone=01553501368&Id=
// URL: /Order/Success?OrderNo=0917005&Payment=1&Transaction=CANCELLED&Phone=01553501368&Id=

//Order/Success?OrderNo=0921043&Payment=1&Phone=015535013688&Id=2a15a434-bb8b-48b1-a2dd-c9652d0c5329&Transaction=CANCELLED

const Success = () => {
  const { isAuthenticating, isAuthenticated, open } = useContext(authContextV2);

  const history = useHistory();
  const { search } = useLocation();

  const [params, setParams] = useState({
    orderNo: null,
    payment: 0,
    transaction: null,
    phone: null,
    id: null,
    isNew: 0,
  });

  const loginClickHandler = () => {
    open('LOGIN', `/Profile/Order/Details/${params.id}`);
  };

  const signUpClickHandler = () => {
    open('SIGNUP', `/Profile/Order/Details/${params.id}`);
  };

  const viewOrderClickHandler = () => {
    if (isAuthenticated) history.replace(`/Profile/Order/Details/${params.id}`);
    else open('LOGIN', `/Profile/Order/Details/${params.id}`);
  };

  const homeClickHandler = () => {
    history.replace(`/`);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(search);
    const orderNo = queryParams.get('OrderNo');
    const payment = +queryParams.get('Payment');
    const transaction = queryParams.get('Transaction');
    const phone = queryParams.get('Phone');
    const id = queryParams.get('Id');
    const isNew = +queryParams.get('IsNew');

    setParams({ orderNo, payment, transaction, phone, id, isNew });
  }, [search]);

  return (
    <div className='h-screen w-screen relative'>
      {!!params.isNew && (
        <h2 className='text-center text-40 px-16 pt-4 pb-8'>
          Order Placed Successfully!
        </h2>
      )}
      {!!params.payment && (
        <h2
          className={`text-center text-36 px-16 pt-4 pb-8 text-primary ${
            params.transaction !== 'SUCCESS' && 'text-warn'
          }`}
        >
          {params.transaction === 'SUCCESS'
            ? 'Payment Success'
            : params.transaction === 'FAILED'
            ? 'Payment Failed'
            : params.transaction === 'CANCELLED'
            ? 'Payment Cancelled'
            : 'FAILED (error 96)'}
        </h2>
      )}
      <h3 className='text-center text-24 py-4'>
        Order number is{' '}
        <span className='text-bold text-primary'>#{params.orderNo}</span>
      </h3>
      <p className='text-center text-semibold py-2'>
        We'll call your number <span className='text-bold'>{params.phone}</span>{' '}
        to reconfirm.{' '}
      </p>
      {!!params.payment && !isAuthenticating && (
        <>
          {params.transaction === 'SUCCESS' && (
            <p className='text-center text-bold py-2 text-primary'>
              Your transaction successed
            </p>
          )}
          {params.transaction === 'FAILED' && (
            <p className='text-center text-semibold py-2 text-warn'>
              Transaction Failed. Check your information in{' '}
              <span
                className='text-primary text-underline cursor-pointer'
                onClick={viewOrderClickHandler}
              >
                Order Detail Page
              </span>
              . <br />
              If required call our customer service:{' '}
              <a
                href='tel:01319864049'
                className='text-primary text-underline cursor-pointer'
              >
                01319864049
              </a>
            </p>
          )}
          {params.transaction === 'CANCELLED' && (
            <p className='text-center text-semibold py-2'>
              Transaction Cancelled! You can make payment later from{' '}
              <span
                className='text-primary text-underline cursor-pointer'
                onClick={viewOrderClickHandler}
              >
                Order Detail Page.
              </span>
            </p>
          )}
        </>
      )}
      {!isAuthenticated && (
        <p className='text-center text-semibold py-2'>
          <span
            className='text-primary text-underline cursor-pointer'
            onClick={loginClickHandler}
          >
            Login
          </span>{' '}
          to track your order.
          <br />
          If not register then{' '}
          <span
            className='text-primary text-underline cursor-pointer'
            onClick={signUpClickHandler}
          >
            Sign up
          </span>
        </p>
      )}
      <div className='flex justify-center flex-wrap py-16 gap-16'>
        <Button label={'Home'} onClick={homeClickHandler} outline />
        {!isAuthenticated && (
          <Button
            label={'Login to Track your order'}
            onClick={loginClickHandler}
          />
        )}
        {isAuthenticated && (
          <Button label={'View Order'} onClick={viewOrderClickHandler} />
        )}
      </div>
      <Loader isLoading={isAuthenticating} />
    </div>
  );
};

export default Success;
