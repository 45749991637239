import classes from "./Chips.module.css";

const Chips = ({ children, className, outline = false, type = "default" }) => {
  return (
    <span
      className={`${classes.chips} ${className} ${outline && "outline"} ${
        classes[type]
      }`}
    >
      {children}
    </span>
  );
};

export default Chips;
