import React from 'react';

const Item = ({ text, href, icon, className = '' }) => {
  const ParentEl = ({ children }) =>
    !!href ? (
      <a href={href} className={'flex flex-center gap-8 ' + className}>
        {children}
      </a>
    ) : (
      <span className={'flex flex-center gap-8 ' + className}>{children}</span>
    );

  return (
    <ParentEl>
      {!!icon && (
        <div className='w-14'>
          <img src={icon} alt={text} className='w-max flex flex-center' />
        </div>
      )}
      <p className='text-14 text-semibold text-primary'>{text}</p>
    </ParentEl>
  );
};

export default Item;
