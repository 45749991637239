import './Trademark.css';

const Trademark = () => {
  return (
    <div className='flex flex-column justify-between gap-4 border-top-white pt-16 trademark'>
      <p className='text-white text-center text-11'>
        © 2022. Lazz Pharma Limited. All rights reserved
      </p>
      <p className='text-white text-center text-11'>
        <span className='trademark__dev--short-text text-white'>
          Website Developed By
        </span>
        <span className='trademark__dev--long-text text-white display-none'>
          Pharmacy Software & Website Developed By -{' '}
        </span>{' '}
        <a
          className='text-white'
          href='http://iqrasys.com/'
          target={'_blank'}
          rel='noreferrer'
        >
          Iqrasys Solutions Ltd
        </a>
        &nbsp;
        <a className='text-white' href='tel:+8801778772327'>
          (+8801778772327)
        </a>
      </p>
    </div>
  );
};

export default Trademark;
