import { humanizeShortDateTime } from "../../../../lib/utilities";

const Invoice = ({ orderDetails, currentInfo, customerName, phoneNumber }) => {
  const orderDate =
    orderDetails && humanizeShortDateTime(orderDetails?.Order.CreatedAt);

  const today = humanizeShortDateTime(`/Date(${new Date().getTime()})/`);

  const unpaid =
    (orderDetails &&
      (orderDetails?.Order.PaymentLeft < 0
        ? 0
        : orderDetails?.Order?.PaymentLeft)) ||
    0;

  return (
    <div id="page" class="order-invoice">
      <div class="order-invoice-ea">
        <div class="page">
          <div class="custom-row-top">
            <div class="span4">
              <img
                src="/Contents/assets/image/logo.png"
                alt="img"
                class="no-print"
              />
              <img
                src="/Contents/assets/image/logo.png"
                alt="img"
                class="print"
              />
              <address>
                <h2>Lazz Pharma Limited</h2>
                Rasel Square , Dhaka
                <br />
              </address>
            </div>
            <div class="span4 well">
              <table class="invoice-head">
                <tbody>
                  <tr>
                    <td class="pull-right">
                      <strong>Name</strong>
                    </td>
                    <td>{orderDetails?.Order.ComtactName}</td>
                  </tr>
                  <tr>
                    <td class="pull-right">
                      <strong>Order ID</strong>
                    </td>
                    <td>#{orderDetails?.Order.OrderNo}</td>
                  </tr>
                  <tr>
                    <td class="pull-right">
                      <strong>Mobile</strong>
                    </td>
                    <td>{orderDetails?.Order.ComtactPhone}</td>
                  </tr>
                  <tr>
                    <td class="pull-right">
                      <strong>Order Date</strong>
                    </td>
                    <td>{orderDate}</td>
                  </tr>
                  <tr>
                    <td class="pull-right">
                      <strong>Invoice Date</strong>
                    </td>
                    <td>{today}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="invoice">
            <h2>Invoice</h2>
          </div>
          <div class="custom-table-row">
            <div class="span12 well invoice-body">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>sl</th>
                    <th>Product</th>
                    <th>Prescription</th>
                    <th>Price</th>
                    <th>Quantity</th>
                    <th>Discount</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {orderDetails?.Products.map((item, index) => (
                    <tr>
                      <td>0{index + 1}</td>
                      <td>{item?.Name}</td>
                      <td>
                        {currentInfo[index]?.IsPrescriptionRequired === false
                          ? "Not Required"
                          : "Required"}
                      </td>
                      <td>{item.UnitPrice}</td>
                      <td>{item.Quantity}</td>
                      <td>{item.Discount}</td>
                      <td>{item.TotalAmount}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div class="sum-table-for-invoice">
              <table class="table table-bordered small-table-sum">
                <tbody>
                  <tr>
                    <td>SubTotal</td>
                    <td class="SubTotal-tab">
                      <span>{orderDetails?.Order.TotalAmount.toFixed(2)}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Coupon Discount</td>
                    <td class="SubTotal-tab">
                      <span>
                        {orderDetails?.Order.CouponDiscount?.toFixed(2)}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Delivery charge</td>
                    <td class="SubTotal-tab">
                      <span>
                        {orderDetails?.Order.ShippingCharge?.toFixed(2)}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td>Paid Amount</td>
                    <td class="SubTotal-tab">
                      <span>{orderDetails?.Order.PaidAmount?.toFixed(2)}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Unpaid Amount</td>
                    <td class="SubTotal-tab">
                      <span>{unpaid.toFixed(2)}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>Total Order Amount</strong>
                    </td>
                    <td class="SubTotal-tab">
                      <strong>
                        {orderDetails?.Order.PayableAmount?.toFixed(2)}
                      </strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="footer-row">
            <div class="cask-rewarded">
              <span>
                {orderDetails?.Order.CashBack} Taka Cashback Rewarded For This
                Order
              </span>
              <p>
                *** N.B: This cashback will be applicable at your next Order
              </p>
              <h5>Thank You!</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
