import React from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { createPortal } from 'react-dom';
import authContextV2 from '../../../../../store/auth-context-v2';
import Input from '../../../Input/Input';
import styles from './StockOutAlert.module.css';

const StockOutAlert = ({ show, onClose, onRequest, name }) => {
  const {user, isAuthenticating, isAuthenticated} = useContext(authContextV2);

  const [phone, setPhone] = useState();
  const [quantity, setQuantity] = useState(1);


  useEffect(()=> {
    if(isAuthenticated)
      setPhone(user.phone);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticating]);


  if (!show) return <></>;
  const alertEl = document.querySelector('#alert');


  const phoneIsValid = (number) => {
    return /(^(\+88|0088)?(01){1}[3456789]{1}(\d){8})$/.test(
      number
    )
  }

  const requestHandler = () => {
    onRequest({phone, quantity});
  }



  return (
    <>
      {createPortal(
        <div id='demo-modal' class='modal' style={{zIndex: '99999999999'}}>
          <div class='modal__content' style={{ padding: 0 }}>
            <div class='login-main-area'>
              <div
                class='login-info-from'
                style={{
                  padding: 24,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 20,
                }}
              >
                <h1
                  style={{
                    fontSize: 20,
                    textAlign: 'center',
                    textTransform: 'uppercase',
                    fontWeight: 'bold',
                  }}
                >
                  <span
                    style={{
                      padding: 8,
                      display: 'block',
                    }}
                  >
                    {name}
                  </span>
                  <span style={{ color: 'rgb(199, 55, 0)' }}>
                    {' '}
                    is Out of Stock
                  </span>
                </h1>
                <div classsName='w-full'>
                  <Input
                    label={'Phone Number'}
                    value={phone}
                    required
                    validators={[{validator: phoneIsValid, message: 'Invalid Phone Number'}]}
                    onChange={number => setPhone(number)}
                  />
                  <Input
                    label={'Quantity'}
                    value={quantity}
                    required
                    onChange={number => setQuantity(number)}
                    validators={[{validator: n => n > 0, message: 'Quantity Must be More Than Zero'}]}
                  />
                </div>
                <button className={styles['request-btn']} onClick={requestHandler}>
                  Sent Product Request
                </button>
              </div>
            </div>
            <a href class='modal__close' onClick={onClose}>
              &times;
            </a>
          </div>
        </div>,
        alertEl
      )}
    </>
  );
};

export default StockOutAlert;
