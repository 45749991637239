import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import cartContext from "../../store/cart-context";

const MiniCart = ({ closeCart }) => {
  const location = useLocation();
  
  const cartCtx = useContext(cartContext);
  const cartCtxModal = cartCtx.getCartModel;

  console.log(location.pathname.toLocaleLowerCase());

  if(location.pathname.toLocaleLowerCase() === '/checkout')
    return <></>

  return (
    <div class="cart-box" onClick={closeCart}>
      <div class="cart-items text-center text-white">
        <span class="cart-count" style={{color: 'white'}}>{cartCtxModal.TotalItems}</span>
        <span style={{color: 'white'}}>&nbsp;Items</span>
      </div>
      <div class="cart-bag text-center">
        <img
          src="/Contents/assets/image/cartLogo.png"
          style={{ height: "50px !important", width: "50px !important" }}
          alt="img"
        />
      </div>
      <div class="cart-amount">
        <span>৳ </span>
        <span class="cart-amount-span">
          {cartCtxModal.TotalAmmount.toFixed(2)}
        </span>
      </div>
    </div>
  );
};

export default MiniCart;
