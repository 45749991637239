import React from "react";
import { Link } from "react-router-dom";
import { getCategories } from "../../../Service/DataService";
import { imageURL, IMAGE_OF, IMAGE_SIZE } from "../../../Service/imageResolver";
import { urlCategoryWiseRoute } from "../../../Service/UrlService";

const MobileCatagory = () => {
  const getMainCategories = getCategories();
  const getMainCategoriesSlice = getMainCategories.slice(0, 8)
  console.log(getMainCategories);

  return (
    <div className="newCatagory">
        <div className="shopbyCatagory">
          <div class="common-heading">
            <h1>Shop By Category</h1>
          </div>
            <Link to="#">View All</Link>
        </div>
      <div className="catagoryFlex">
        {getMainCategoriesSlice.map((cate, i) => (
          <div className="childItem">
            <div key={i}></div>
            <Link class="parent" to={urlCategoryWiseRoute() + cate.permalink}>
              <div className="mobileCatagoryContainer">  
                  <small className="imageContainer mobileimageContainer">
                    <img
                      class="icon-menu normal-img"
                      alt="Lazz"
                      src={imageURL(cate.image, IMAGE_OF.BANNER, IMAGE_SIZE.ICON)}
                    />
                    <img
                      class="icon-menu hover-img"
                      alt="Lazz"
                      src={imageURL(
                        cate.hoverImage,
                        IMAGE_OF.BANNER,
                        IMAGE_SIZE.ICON
                      )}
                    />
                  </small>
                   <span className="catagortText">{cate.name}</span>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MobileCatagory;
