import React from 'react';
import { BASE_URL } from '../../../Service/httpService';
import SidebarLinks from '../SidebarLinks/SidebarLinks';

const ProfileSidebar = ({ profileInfo }) => {
  return (
    <div class='profile-sidebar'>
      <div class='profile-sidebar-inner'>
        <div class='profile-short-desc'>
          {profileInfo.Image === null || profileInfo.Image === undefined ? (
            <img src='/Contents/assets/image/user.png' alt='img' />
          ) : (
            <img src={`${BASE_URL}/${profileInfo.Image}`} alt='img' />
          )}

          <div className='profile-right'>
            <p>{profileInfo.Name}</p>
            <p>{profileInfo.Email}</p>
            <aside>
              My Wallet: <span>{profileInfo?.Cashback?.toFixed(2)}BDT</span>
            </aside>
            <aside>
              My Due:{' '}
              <span>
                {profileInfo?.Pending?.toFixed(2) < 0
                  ? (0).toFixed(2)
                  : profileInfo?.Pending?.toFixed(2)}
                BDT
              </span>
            </aside>
          </div>
        </div>
        <SidebarLinks />
      </div>
    </div>
  );
};

export default ProfileSidebar;
