import { useCallback } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { POST_PRESCRIPTION, REMOVE_ANONYMOUS_PRESCRIPTION } from '../../../lib/endpoints';
import { http } from '../../../Service/httpService';
import Button from '../../Shared/Button/Button';
import Card from '../../Shared/Card/Card';
import Loader from '../../utilities/Loader/Loader';
import checkoutContext from '../store/checkout-context';

const Prescriptions = () => {
  const {storePrescription, removePrescription, prescriptions} = useContext(checkoutContext);

  const [isLoading, setIsLoading] = useState(false);
  const [imageIsInvalid, setImageIsInvalid] = useState(false);

  const fileRef = useRef();

  const setSelectedFileHandler = ({ target }) => {
    const file = target.files[0];
    if (!file) return;
    const allowedExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx'];

    const subs = file.name.toLowerCase().split('.');

    if (!allowedExtensions.includes(subs[subs.length - 1])) {
      setImageIsInvalid(true);
      target.value = '';
      return false;
    } else setImageIsInvalid(false);

    if (!target.files || target.files.length === 0) {
      return;
    }

    postPrescription(file);
    target.value = '';
  };

  const imageRemoverhandler = (file) => {
    setIsLoading(true);
    http.post({
      url: REMOVE_ANONYMOUS_PRESCRIPTION + file.id,
      before: () => {},
      successed: () => {
        removePrescription(file.id);
        setIsLoading(false);
        // setPreview((prevState) =>
        //   prevState.filter((item) => item.id !== file.id)
        // );
      },
      failed: () => {
        setIsLoading(false);
      },
      always: () => {},
    });
  };

  const postPrescription = useCallback((file) => {
    setIsLoading(true);
    const objectUrl = URL.createObjectURL(file);

    http.file({
      url: POST_PRESCRIPTION,
      payload: {
        Img: file,
        From: 'Upload Prescription',
        Description: '',
        activityId: '00000000-0000-0000-0000-000000000000',
      },
      before: () => {},
      successed: (res) => {
        storePrescription({ objectUrl: objectUrl, id: res.Id });
        setIsLoading(false);
      },
      failed: () => {
        setIsLoading(false);
      },
      always: () => {},
      map: (res) => {
        return res;
      },
    });
  }, []);

  const prescriptionClickHandler = () => {
    fileRef.current.click();
  };

  useEffect(()=>{
    return () => {

    }
  });

  return (
    <Card className={'relative'}>
      {prescriptions.length > 0 && (
        <div className='image_preview_container mb-10'>
          {prescriptions?.length > 0 && (
            <>
              <div className='image_previewer'>
                {prescriptions.map((file) => (
                  <div className='image_prev' key={file.id}>
                    <img src={file.objectUrl} alt='img' srcset='' />
                    <p
                      style={{
                        color: 'red',
                        textAlign: 'center',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                        marginTop: '10px',
                      }}
                      onClick={imageRemoverhandler.bind(null, file)}
                    >
                      Remove
                    </p>
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      )}
      <div className='prescription_order_section'>
        <div className='file_uploader' style={{ maxWidth: '33.33%' }}>
          <input
            className='display-none--important'
            type='file'
            name=''
            id=''
            ref={fileRef}
            onChange={setSelectedFileHandler}
          />
          <Button
            label={'Select Prescription (optional)'}
            onClick={prescriptionClickHandler}
          />
          {imageIsInvalid && (
            <div class='alert alert-error'>
              Only JPG JPEG PNG format acceptable.
            </div>
          )}
        </div>
      </div>
      <Loader isLoading={isLoading} />
    </Card>
  );
};

export default Prescriptions;
