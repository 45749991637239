import { Link } from 'react-router-dom';
import { urlProductDetails } from '../../../Service/UrlService';
import Action from './Action/Action';
import Description from './Description/Description';
import DiscountTag from './DiscountTag/DiscountTag';
import Price from './Price/Price';
import ProductImage from './ProductImage/ProductImage';

const ProductCard = ({ item }) => {

  return (
    <div class='inner-product-main-flex slide-single splide__slide'>
      <DiscountTag amount={item.discount} percentage={item.discountPercent} />
      <Link
        to={urlProductDetails() + item.permalink}
        className='product-top-area'
      >
        <ProductImage url={item.image} name={item.Nm} className={'product-img'} />
        <Description
          category={item.category}
          genericName={item.GN}
          name={item.Nm}
          strength={item.St}
          supplier={item.Sp}
          type={item.Ct}
        />
        <Price discount={item.discount} mrp={item.MRP}/>
      </Link>
      <Action product={item} />
    </div>
  );
};

export default ProductCard;
