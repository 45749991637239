const Paginator = ({ paginate, styles, total, perPage, page, onPageChange }) => {
  if (!paginate) return <></>;

    const clickHandler = (page, direction) => {
        onPageChange && onPageChange(page + direction);
    }

  return (
    <div className={styles.table__paginator}>
      <p>{perPage * page - perPage + 1} - {perPage * page} of {total}</p>
      <div className={styles.table__paginator__actions}>
        <button className={styles.table__paginator__actions__nagivator} onClick={clickHandler.bind(null, page, -1)}>
          <svg
            className={styles.table__paginator__actions__nagivator__arrow}
            focusable='false'
            aria-hidden='true'
            viewBox='0 0 24 24'
            data-testid='KeyboardArrowLeftIcon'
          >
            <path d='M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z'></path>
          </svg>
        </button>
        <button className={styles.table__paginator__actions__nagivator} onClick={clickHandler.bind(null, page, 1)}>
          <svg
             className={styles.table__paginator__actions__nagivator__arrow}
            focusable='false'
            aria-hidden='true'
            viewBox='0 0 24 24'
            data-testid='KeyboardArrowRightIcon'
          >
            <path d='M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z'></path>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Paginator;
