import React, { useContext, useEffect, useState } from 'react';
import { ON_DEMAND_PRODUCT, PRODUCT_REQUEST } from '../../../../lib/endpoints';
import http from '../../../../Service/http-service-v2';
import appContext from '../../../../store/app-context';
import cartContext from '../../../../store/cart-context';
import Alert from '../../../Lib/Alert/Alert';
import StockOutAlert from './StockOutAlert/StockOutAlert';

const Action = ({ product }) => {
  const cartCtx = useContext(cartContext);
  
  const appCtx = useContext(appContext);

  const [alert, setAlert] = useState({show: false, text: "", type: "ERROR"});
  const [stockOutAlert, setStockOutAlert] = useState({show: false, productName: ""});
  const [qty, setQty] = useState('');
  const [visibleCartBox, setVisibleCartBox] = useState(false);

  const storedProduct = cartCtx.getCartModel.Items.find((p) => p.id === product.id);

  const postProductRequest = (product) => {
    const payload = {
      ProductId: product.id,
      ActivityId: window.ActivityId,
    };

    http.post({ url: ON_DEMAND_PRODUCT, payload }).catch(error => {console.log(error)});
  };

  const { searchQuery } = appCtx;
  const addToCartHandler = () => {
    if(product.Stk === 0 && product.area === 'SEARCHED'){
      searchQuery.disableCloseOnClick(true);
    }


    if (product.Stk === 0) {
      postProductRequest(product);
      setStockOutAlert({show: true, productName: `${product.Nm} ${product.St} ${product.Ct}`});
      return;
    }

    storeCartHandler(product);
  };

  const alertCloseHandler = () => {
    setAlert({show: false, text: "", type: "ERROR"});
    searchQuery.disableCloseOnClick(false);
  };

  const blurHandler = (product) => {
    if (qty === 0) {
      alert("Quantity Can't be less than 1");
      cartCtx.updateEditableQuantity(product, 1);
      setQty(1);
    }
  };

  const qtyChangeHandler = (product, { target }) => {
    if (target.value === '') {
      setQty(0);
    } else {
      setQty(target.value);
    }
    cartCtx.updateEditableQuantity(product, target.value);
  };

  const qtyIncHandler = (e) => {
    e.preventDefault();
    let quantity = storedProduct.quantity + 1;
    cartCtx.updateQuantity(storedProduct, quantity);
  };

  const qtyDecHandler = (e) => {
    e.preventDefault();
    let quantity = storedProduct.quantity - 1;
    cartCtx.updateQuantity(storedProduct, quantity);
    if (storedProduct.quantity === 0) {
      setVisibleCartBox(false);
    }
  };

  const storeCartHandler = (itemContain) => {
    console.log({product: itemContain});
    cartCtx.storeCartItems(itemContain);
  };

  useEffect(() => {
    if (storedProduct) {
      setVisibleCartBox(true);
    } else {
      setVisibleCartBox(false);
    }
  }, [storedProduct]);

  const sendProductRequest = (payload) => {
    http.post({ url: PRODUCT_REQUEST, payload })
    .then(res => {
      setAlert({show: true, text: "Product Request Sent", type: "SUCCESS"});
    }).catch(error => {
      console.log(error);
      setAlert({show: true, text: error.toString(), type: "SUCCESS"});
    });

    if(product.Stk === 0 && product.area === 'SEARCHED'){
      searchQuery.disableCloseOnClick(true);
    }
  } 

  const requestHandler = ({phone, quantity}) => {
    if(!phone || !quantity){
      setAlert({show: true, text: "Phone number and Quantity is required", type: "ERROR"});
      return;
    }

    setStockOutAlert({show: false, productName:""});

    const payload = {
      ProductId: product.id,
      Phone: phone,
      Quantity: quantity
    }

    sendProductRequest(payload);
  }

  const stockOutAlertCloseHandler = () => {
    setStockOutAlert({show: false, productName: ""});
    searchQuery.disableCloseOnClick(false);
  }

  return (
    <>
      {!visibleCartBox && (
        <div
          class='add-to-cart d-flex al-center j-center'
          onClick={addToCartHandler}
        >
          <svg
            id='Layer_1'
            data-name='Layer 1'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 512 512'
            height='24'
            width='24'
          >
            <title>wheel-cart-outline</title>
            <path
              class='cls-1'
              d='M511.6,147.38a20.48,20.48,0,0,0-20.08-24.5H139.67L122.48,36.94A20.48,20.48,0,0,0,102.4,20.48H20.48a20.48,20.48,0,1,0,0,41H85.61l37.67,188.35,0,.18,12.77,63.86h0l7.67,38.35a20.48,20.48,0,0,0,20.08,16.46H450.56a20.48,20.48,0,0,0,20.08-16.46L491.08,250l0-.21Zm-45.06,16.46-12.29,61.44H160.15l-11.5-57.47-.79-4ZM180.63,327.68l-12.29-61.44H446.06l-12.29,61.44Z'
            />
            <circle class='cls-1' cx='225.28' cy='450.56' r='40.96' />
            <circle class='cls-1' cx='389.12' cy='450.56' r='40.96' />
          </svg>
          <span>Add to Bag</span>
        </div>
      )}
      {visibleCartBox && (
        <div class='wishlist-btn'>
          <div class='add-tocart-overlay d-flex al-center j-center'>
            <div class='inner-card-flex'>
              <div class='qty-holder2'>
                <span onClick={qtyDecHandler} class='qty-dec-btn2' title='Dec'>
                  -
                </span>
                <aside>
                  <input
                    type='text'
                    name='qty'
                    id='qty'
                    value={storedProduct?.quantity}
                    onChange={qtyChangeHandler.bind(null, product)}
                    onBlur={blurHandler.bind(null, product)}
                  />
                </aside>
                <span onClick={qtyIncHandler} class='qty-inc-btn2' title='Inc'>
                  +
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      <StockOutAlert  name={stockOutAlert.productName} show={stockOutAlert.show} onClose={stockOutAlertCloseHandler} onRequest={requestHandler} />
      <Alert show={alert.show} text={alert.text} onClose={alertCloseHandler}/>
      {/* {authFormIsShown && (
        <AuthenticationModalBody
          Template={LoginModal}
          closeModal={authFormCloseHandler}
          onSuccessed={sendProductRequest.bind(null, product.id)}
        />
      )} */}
    </>
  );
};

export default Action;
