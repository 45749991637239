import { Link } from 'react-router-dom';
import Card from '../../Card/Card';
import styles from './Breadcrumb.module.css';

const Breadcrumb = ({ title, path = [] }) => {
  if (path.length === 0) return <></>;

  return (
    <div className={styles.breadcrumb}>
      <div className={styles.breadcrumb__path}>
        {path.map(({ title, url }) => (
          <Link className={styles.breadcrumb__path__chip} to={url}>{title}</Link>
        ))}
      </div>
      <h4 className={styles.breadcrumb__title}>{title}</h4>
    </div>
  );
};

export default Breadcrumb;
