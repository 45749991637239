import styles from '../Navbar.module.css';
import { links } from '../navbar.util';
import Link from './Link/Link';



const Links = () => {
  return (
    <div className={styles.navbar__links}>
      {links.map((link, i) => (
        <Link title={link.title} url={link.url} key={i} />
      ))}
    </div>
  );
};

export default Links;
