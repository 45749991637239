import styles from './Hamburger.module.css';

const Hamburger = ({className, onClick}) => {
    const clickHandler = () => {
        onClick && onClick();
    }
    
    return (
        <button className={`${styles.hamburger} ${className}`} onClick={clickHandler}>
            <div className={styles.hamburger__petty}></div>
            <div className={styles.hamburger__petty}></div>
            <div className={styles.hamburger__petty}></div>
        </button>
    );
};

export default Hamburger;