import React from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { CREATE_ORDER, PAYMENT_ATTEMPT } from '../../../../lib/endpoints';
import { postV2 } from '../../../../Service/http-service-v2';
import {
  httpErrorLogger,
} from '../../../../Service/logger-service';
import authContextV2 from '../../../../store/auth-context-v2';
import cartContext from '../../../../store/cart-context';
import Alert from '../../../Lib/Alert/Alert';
import Button from '../../../Shared/Button/Button';
import { PAYMENT_OPTIONS } from '../../checkout.util';
import checkoutContext from '../../store/checkout-context';

const Order = ({ setLoading }) => {
  const {
    coupon,
    shipping,
    fineCash,
    autoOrderIsActive,
    prescriptions,
    cashback,
    paymentOption,
    charge,
    clearContext,
  } = useContext(checkoutContext);
  const { clearCart, getCartModel: cart } = useContext(cartContext);
  const { user, isAuthenticated } = useContext(authContextV2);

  const [alert, setAlert] = useState({ show: false, text: '', type: 'ERROR' });

  const history = useHistory();

  const placeOrderClickHandler = () => {
    setLoading(true);

    if (isOrderValid()) placeOrder();
    else setLoading(false);
  };

  const placeOrder = () => {
    const payable =
      cart.TotalAmmount - coupon.amount + +charge - (fineCash || 0);

    let totalQuantity = 0;
    cart.Items.forEach((item) => (totalQuantity += item.quantity));

    const products = cart.Items.map((product) => ({
      ProductId: product.id,
      Quantity: product.quantity,
    }));

    const listOfDxId = prescriptions.map((p) => p.id);

    const payload = {
      TotalItem: cart.TotalItem,
      TotalQuantity: totalQuantity,
      TotalAmount: cart.TotalAmmount,
      ShippingCharge: charge,
      PayableAmount: payable,
      From: 'WEB_CART',
      address: {
        ...shipping,
        areaId: shipping.areaId || 0,
      },
      Items: products,
      ActivityId: window.ActivityId,
      Remarks: shipping.address,
      autoOrderIsActive: autoOrderIsActive,
      CoupenAmount: coupon.amount,
      CouponId: coupon.id || undefined,
      ImgId: listOfDxId,
      Cashback: cashback,
      fineCash: fineCash || 0,
      DistrictId: shipping.districtId,
      PaymentMethod: paymentOption,
    };

    postV2({ url: CREATE_ORDER, payload })
      .then((data) => {
        if (!data.IsError) {
          clearContext();
          cashOnDelivery(payable, data.Data);
          //makePayment(payable, data.Data);
          clearCart();
        } else {
          httpErrorLogger(
          user.phone,
          user.id,
          CREATE_ORDER,
          JSON.stringify(data),
          payload,
          'IS ERROR TRUE',
          'checkout'
        );
          setLoading(false);
          setAlert({
            show: true,
            text: data.Msg,
            type: 'ERROR',
          });
        }
      })
      .catch((err) => {
        setAlert({
          show: true,
          text: `Unexpected Error, Please contact admin`,
          type: 'ERROR',
        });
      });
  };

  const cashOnDelivery = (payable, order)=>{
    history.replace(
      `/Order/Success?OrderNo=${order.OrderNo}&Payment=${0}&Id=${
        order.Id
      }&Phone=${shipping.receiverPhone}&IsNew=1`
    );
    setLoading(false);
  };

  const makePayment = (payable, order) => {
    let amount = 0;
    if (paymentOption === PAYMENT_OPTIONS.TWENTY_PERCENT) {
      amount = Math.ceil(payable * 0.2);
    } else if (paymentOption === PAYMENT_OPTIONS.HUNDRED_PERCENT) {
      amount = Math.ceil(payable);
    } else {
      history.replace(
        `/Order/Success?OrderNo=${order.OrderNo}&Payment=${0}&Id=${
          order.Id
        }&Phone=${shipping.receiverPhone}&IsNew=1`
      );
      setLoading(false);
      return;
    }

    const payload = {
      OrderId: order.Id,
      CustomerId: isAuthenticated
        ? user.id
        : '00000000-0000-0000-0000-000000000000',
      Phone: shipping.receiverPhone,
      PaymentId: '00000000-0000-0000-0000-000000000000',
      AttemptAmount: amount,
      PaidAmount: 0,
      Status: 'Unpaid',
      OrderAmount: payable,
      OrderNo: order.OrderNo,
      isNew: '1',
    };

    postV2({ url: PAYMENT_ATTEMPT, payload })
      .then((data) => {
        if (!data.IsError) {
          window.location.replace(data.Msg);
        } else {
          setAlert({
            show: true,
            text: data.Msg,
            type: 'ERROR',
          });
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const isOrderValid = () => {
    if (!paymentOption) {
      setAlert({
        show: true,
        text: `Please select a payment option`,
        type: 'ERROR',
      });
      return false;
    }

    const emptyFields = [];
    let errorMessage = '';
    if (!shipping.receiverPhone) emptyFields.push('Receiver Phone Number');

    if (!shipping.division) emptyFields.push('Division');

    if (!shipping.district) emptyFields.push('District');

    if (!shipping.address) emptyFields.push('Address');

    errorMessage =
      emptyFields.length !== 0
        ? `${emptyFields.join(', ')} ${
            emptyFields.length > 1 ? 'are required. ' : 'is required. '
          } `
        : errorMessage;

    const numberIsValid = /(^(\+88|0088)?(01){1}[3456789]{1}(\d){8})$/.test(
      shipping.receiverPhone
    );

    if (shipping.receiverPhone && !numberIsValid) {
      errorMessage += 'Invalid phone number found.';
    }

    if (emptyFields.length > 0 || !numberIsValid) {
      setAlert({
        show: true,
        text: errorMessage,
        type: 'ERROR',
      });
      return false;
    }

    return true;
  };

  const alertCloseHandler = () => {
    setAlert({
      show: false,
      text: '',
    });
    setLoading(false);
  };

  return (
    <>
      <Button label={'Place Order'} onClick={placeOrderClickHandler} />
      <Alert show={alert.show} text={alert.text} onClose={alertCloseHandler} />
    </>
  );
};

export default Order;
