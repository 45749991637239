import Breadcrumb from './Breadcrumb/Breadcrumb';
import styles from './Page.module.css';

const Page = ({ className, children, path, title }) => {
  return (
    <div className={styles.page}>
      <Breadcrumb path={path} title={title} />
      <div className={className}>{children}</div>
    </div>
  );
};

export default Page;
