import { Fragment, useEffect, useRef } from "react";
import { Redirect, Route } from "react-router-dom";
import { Switch } from "react-router-dom";
import "./App.css";
import ShoppingCart from "./components/Cart/ShoppingCart";
import FooterParent from "./components/Footer/FooterParent";
import HeaderParent from "./components/Header/HeaderParent";
import About from "./components/pages/About";
import Carrer from "./components/pages/Carrer";
import Contact from "./components/pages/Contact";
import Gallery from "./components/pages/Gallery";
import Home from "./components/pages/Home";
import OrderUS from "./components/pages/OrderUS";
import Privacy from "./components/pages/Privacy";
import Returnpolicy from "./components/pages/Returnpolicy";
import Review from "./components/pages/Review";
import TermsCondition from "./components/pages/TermsCondition";
import ProductDetails from "./components/pages/ProductDetails";
import {
  urlAboutRoute,
  urlBranchLocationRoute,
  urlCarrerRoute,
  urlCategoryWiseRoute,
  urlCheckoutRoute,
  urlContactRoute,
  urlGalleryRoute,
  urlHomeRoute,
  urlHowToOrderRoute,
  urlNotificationRoute,
  urlPrivacyPolicy,
  urlProductDetails,
  urlProfileRoute,
  urlRequestOrderRoute,
  urlReturnPolicy,
  urlReviewRoute,
  urlSearchProducts,
  urlSpecialOfferRoute,
  urlSubCategoryWiseRoute,
  urlTermsConditionRoute,
} from "./Service/UrlService";
import HowToOrder from "./components/pages/HowToOrder";
import CategoryWiseProduct from "./components/Products/CategoryProduct/CategoryWiseProduct";
import SubCategoryProduct from "./components/Products/SubCategoryProduct/SubCategoryProduct";
import Profile from "./components/pages/Profile";
import { useLocation } from "react-router-dom";
import SpecialOffer from "./components/Profile/Special Offer/SpecialOffer";
import Notification from "./components/Notification/Notification";
import SearchAllProduct from "./components/Products/SearchAllProduct/SearchAllProduct";
import authService from "./Service/auth-service";
import config from "./Service/config-service";
import { Activity } from "./Service/activity-service";
import Auth from "./components/Auth/Auth";
import { useContext } from "react";
import authContextV2 from "./store/auth-context-v2";
import Checkout from "./components/Checkout/Checkout";
import Success from "./components/Order/Success/Success";
import Menu from "./components/Header/SecondLayer/Links/User/Menu/Menu";
import Transactions from "./components/Transactions/Transactions";
import Purchases from "./components/Purchases/Purchases";
import Notifications from "./components/Notifications/Notifications";
import Prescriptions from "./components/Prescriptions/Prescriptions";
import Header from "./components/Header/Header";
import RequestOrder from "./components/RequestOrder/RequestOrder";
import BranchLocations from "./components/BranchLocations/BranchLocations";

function App() {
  const { isAuthenticated } = useContext(authContextV2);
  const location = useLocation();
  const headerRef = useRef();
  const mainRef = useRef();

  useEffect(() => {
    const headerHeight = headerRef.current?.clientHeight;
    mainRef.current.style.paddingTop = `${headerHeight}px`;
  }, []);

  useEffect(() => {
    authService.autoLogin();
    config.init();
  }, [isAuthenticated]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location]);

  useEffect(() => {
    Activity.Set();
  }, [location]);



  return (
    <Fragment>
      <Header ref={headerRef}/>
      <main class="home option2 homeServicePadding" ref={mainRef} style={{overflow: 'hidden'}}>
        <Switch>
          <Route path={urlHomeRoute()} exact>
            <Home />
          </Route>
          <Route path={urlRequestOrderRoute()} exact>
            <OrderUS />
          </Route>
          <Route path={urlReviewRoute()} exact>
            <Review />
          </Route>
          <Route path={urlSpecialOfferRoute()} exact>
            <SpecialOffer />
          </Route>
          <Route path={urlCarrerRoute()} exact>
            <Carrer />
          </Route>
          <Route path={urlGalleryRoute()} exact>
            <Gallery />
          </Route>
          <Route path={urlAboutRoute()} exact>
            <About />
          </Route>
          <Route path={urlContactRoute()} exact>
            <Contact />
          </Route>
          <Route path={urlProductDetails() + ":id"} exact>
            <ProductDetails />
          </Route>
          <Route path={urlCheckoutRoute()} exact>
            <Checkout />
          </Route>
          <Route path={urlProfileRoute()}>
            <Profile />
          </Route>
          <Route path={urlReturnPolicy()} exact>
            <Returnpolicy />
          </Route>
          <Route path={urlTermsConditionRoute()} exact>
            <TermsCondition />
          </Route>
          <Route path={urlPrivacyPolicy()} exact>
            <Privacy />
          </Route>
          <Route path={urlHowToOrderRoute()} exact>
            <HowToOrder />
          </Route>
          <Route path={urlBranchLocationRoute()} exact>
            <BranchLocations />
          </Route>
          <Route path={urlCategoryWiseRoute() + ":permalink"}>
            <CategoryWiseProduct />
          </Route>
          <Route path={urlSubCategoryWiseRoute() + ":id"}>
            <SubCategoryProduct />
          </Route>
          <Route path={urlNotificationRoute()}>
            <Notification />
          </Route>
          <Route path={urlSearchProducts()}>
            <SearchAllProduct />
          </Route>
          {/* NEW VERSION */}
          <Route path={'/Transactions'}>
            <Transactions />
          </Route>
          <Route path={'/Purchases'}>
            <Purchases />
          </Route>
          <Route path={'/Notifications'}>
            <Notifications />
          </Route>
          <Route path={'/Prescriptions'}>
            <Prescriptions />
          </Route>
          <Route path={'/Request-Order'}>
            <RequestOrder />
          </Route>
          <Route path={'/Order/Success'}>
            <Success />
          </Route>
          <Route path="*">
            <Redirect to={'/'}></Redirect>
          </Route>
        </Switch>
      </main>
      <ShoppingCart />
      <FooterParent />
      <Auth />
      <Menu />
    </Fragment>
  );
}

export default App;
