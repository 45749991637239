import { CATEGORY_SLIDER_TYPE } from '../../../../constants';
import { getCategorySlider } from '../../../../Service/DataService';
import { filterImagesByViewPort } from '../../../../shared/image.util';
import Horizontal from '../../../Home/CategoryDisplay/DisplayRow/Gallery/Horizontal/Horizontal';
import SlideShow from '../../../Home/CategoryDisplay/DisplayRow/Gallery/SliderShow/SlideShow';
import Vertical from '../../../Home/CategoryDisplay/DisplayRow/Gallery/Vertical/Vertical';

const CategorySlider = ({ categoryId }) => {
  const slider = getCategorySlider(categoryId);

  if (!slider) return <></>;

  const filteredSliders = filterImagesByViewPort(slider.items);


  if (!filteredSliders) return <></>;

  if (slider.type === CATEGORY_SLIDER_TYPE.INLINE)
    return <Horizontal gap={slider.gap} images={filteredSliders} />
    

  if (slider.type === CATEGORY_SLIDER_TYPE.SLIDER)
    return <SlideShow images={filteredSliders} />;

  if (slider.type === CATEGORY_SLIDER_TYPE.VERTICAL)
    return <Vertical images={filteredSliders}/>;

  return <></>;
};

export default CategorySlider;
