
const Header = ({ columns = [], indexed }) => {
  return (
    <thead>
      <tr>
        {indexed && <th>#</th>}
       {columns.map((c, i) => (<th key={i}>{c.title}</th>) )}
      </tr>
    </thead>
  );
};

export default Header;
