import { useEffect } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import useAuthGuard from '../../hooks/useAuthGuard';
import { GET_PAYMENT_HISTORY } from '../../lib/endpoints';
import { humanizeShortDateTime } from '../../lib/utilities';
import { postV2 } from '../../Service/http-service-v2';
import Page from '../Shared/Page/Page';
import Table from '../Shared/Table/Table';

const breadcrumb = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'Transactions',
    url: '/Transactions',
  },
];

const Transactions = () => {
  useAuthGuard();
  
  const [isLoading, setIsLoading] = useState(true);
  const [transactions, setTransactions] = useState({
    Data: [],
    PageNumber: 1,
    PageSize: 10,
    Total: 0,
  });

  const columnDefinitions = [
    {
      title: 'Order No',
      field: 'OrderNo',
    },
    {
      title: 'Transaction Id',
      field: 'TranId',
    },
    {
      title: 'Service',
      field: 'CardType',
    },
    {
      title: 'Amount',
      field: 'AttemptAmount',
    },
    {
      title: 'State',
      field: 'Status',
      template: ({ data }) => <i>{data}</i>,
    },
    {
      title: 'Date',
      field: 'PaymentDate',
      mapper: (date) => {
        return date ? humanizeShortDateTime(date) : '';
      },
    },
  ];

  const getTransactions = useCallback((page, pageSize) => {
    const payload = {
      filter: [],
      pageNumber: page,
      pageSize: pageSize,
    };

    postV2({ url: GET_PAYMENT_HISTORY, payload })
      .then((res) => {
        if (!res.IsError) {
          setTransactions(res.Data);
        } else {
          alert(res.Msg);
        }
      })
      .catch((error) => {
        alert(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    getTransactions(transactions.PageNumber, transactions.PageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pageChangeHandler = (page) => {
    getTransactions(page, transactions.PageSize);
  }

  return (
    <Page path={breadcrumb} title={'Transactions'}>
      <Table
        isLoading={isLoading}
        data={transactions.Data}
        columnDefinitions={columnDefinitions}
        indexed
        paginate
        page={transactions.PageNumber}
        perPage={transactions.PageSize}
        total={transactions.Total}
        onPageChange={pageChangeHandler}
      />
    </Page>
  );
};

export default Transactions;
