import React from "react";

const AboutSliderTemplate = ({item}) => {
  return (
    <img
      src="https://www.lazzpharma.com/Content/assets/iqraimages/AboutSlider/About%204-f.jpg"
      alt="Lazz Pharma"
    />
  );
};

export default AboutSliderTemplate;
