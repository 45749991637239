import React from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import { LOGIN } from '../../../lib/endpoints';
import { postV2 } from '../../../Service/http-service-v2';
import authContextV2 from '../../../store/auth-context-v2';
import Button from '../../Shared/Button/Button';
import styles from '../Auth.module.css';
import { userCamelCase } from '../auth.util';

const Login = () => {
  const { close, open, login: loginCtx } = useContext(authContextV2);

  const [error, setError] = useState(null);

  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');

  const [phoneError, setPhoneError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const phoneChangeHandler = ({ target: el }) => {
    setPhone(el.value);
  };

  const passwordChangeHandler = ({ target: el }) => {
    setPassword(el.value);
  };

  const phoneFocusHandler = () => {
    setPhoneError(false);
  };

  const passwordFocusHandler = () => {
    setPasswordError(false);
  };

  const loginHandler = (e) => {
    e.preventDefault();
    
    if (phone.length === 0){
      setPhoneError(true);
      return;
    } 
    if (password.length === 0){
      setPasswordError(true);
      return;
    } 

    if(phoneError || passwordError) return;


    login();
  };

  const login = () => {
    const payload = {
      Password: password,
      UserName: phone
    }

    postV2({url: LOGIN, payload})
    .then(data => {
      if(!data.IsError){
        const user = userCamelCase(data.Data);
        loginCtx(user);
        close();
      } else {
        console.log(data);
        setError(data.Msg);
      }
      
    }).catch(error => {
      setError(error.toString())
    });
  };

  const singupClickHandler = () => {
    open('SIGNUP');
  }

  const resetClickHandler = () => {
    open('RESET_PASSWORD');
  }

  return (
    <>
      <form>
        <div className={styles.auth_dialouge__form_field}>
          <label htmlFor='phone'>
            Phone Number
            <input
              type='text'
              id='phone'
              onChange={phoneChangeHandler}
              onFocus={phoneFocusHandler}
              value={phone}
            />
            <small>{phoneError ? 'Phone number is empty' : ' '}</small>
          </label>
        </div>
        <div className={styles.auth_dialouge__form_field}>
          <label htmlFor='password'>
            Password
            <input
              type='password'
              id='password'
              onChange={passwordChangeHandler}
              onFocus={passwordFocusHandler}
              value={password}
            />
            <small>{passwordError ? 'Password number is empty' : ' '}</small>
          </label>
        </div>
        <div className={styles.auth_dialouge__error}>
          <p>{error ? error : ''}</p>
        </div>
        <div className={styles.auth_dialouge__actions}>
          <div>
            <p>
              Create new account{' '}
              <span className={styles['auth_dialouge__actions__button--text']} onClick={singupClickHandler}>
                Sign up
              </span>
            </p>
            <p>
              Forgot passwrod?{' '}
              <span className={styles['auth_dialouge__actions__button--text']} onClick={resetClickHandler}>
                Reset
              </span>
            </p>
          </div>

          <Button label={'Login'} onClick={loginHandler} type={'button'} />
        </div>
      </form>
    </>
  );
};

export default Login;
