import React from 'react';
import { useHistory } from 'react-router-dom';
import Card from '../../../../../../Shared/Card/Card';

const Notification = ({ name, icon, count, onClick = () => {}, to }) => {
  const history = useHistory();
  const clickHandler = () => {
    onClick(name, to);

    if (to) history.push(to);
  };

  return (
    <Card
      className={
        'cursor-pointer hover-background-secondary transition-200 flex align-center gap-4 justify-between'
      }
      onClick={clickHandler}
    >
      <div className='w-24'>
        <img src={icon} alt={'Purchase icon'} />
      </div>
      <p className='text-16 text-bold text-gray'>{name}</p>
    </Card>
  );
};

export default Notification;
