import { useEffect, useState } from "react";
import { post } from "../../../Service/http-service-v2";
import Transaction from "./Transaction/Transaction";
import "./PaymentHistory.css";
import Suspense from "../../Suspense/Suspense";
import { GET_PAYMENT_HISTORY } from "../../../lib/endpoints";

const PaymentHistory = () => {
  const [transactions, setTransactions] = useState([]);
  const [isGetting, setIsGetting] = useState(true);

  useEffect(() => {
    post({ url: GET_PAYMENT_HISTORY })
      .then((res) => {
        setTransactions(res.Data.Data);
        setIsGetting(false);
      })
      .catch((error) => {
        alert(error);
        setIsGetting(false);
      });
  }, []);

  return (
    <section className="table-responsive table-responsive-overflow">
      {!isGetting && (
        <table className="table table-sm table-hover w-full">
          <thead className="bg-primary">
            <tr>
              <th className="tableHeading" scope="col">#</th>
              <th className="tableHeading" scope="col">Order No.</th>
              <th className="tableHeading" scope="col">Transaction Id</th>
              <th className="tableHeading" scope="col">Service</th>
              <th className="tableHeading" scope="col">Amount</th>
              <th className="tableHeading" scope="col">Status</th>
              <th className="tableHeading" scope="col">Date</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction, index) => (
              <Transaction
                key={transaction.Id}
                transaction={transaction}
                index={index}
              />
            ))}
          </tbody>
        </table>
      )}
      {isGetting && <Suspense />}
    </section>
  );
};

export default PaymentHistory;
