import { PAYMENT_STATUS } from '../../../../constants';
import { PAYMENT_ATTEMPT } from '../../../../lib/endpoints';
import { http } from '../../../../Service/httpService';
import Button from '../../../Shared/Button/Button';

const OrderActions = ({
  paymentStatus,
  onUpload,
  onAttach,
  onPrint,
  payable,
  orderNumber,
  orderId,
  userId,
  setIsRedirection,
  phone,
}) => {
  const makePayment = () => {
    setIsRedirection(true);

    http.post({
      url: PAYMENT_ATTEMPT,
      payload: {
        OrderId: orderId,
        CustomerId: userId,
        PaymentId: '00000000-0000-0000-0000-000000000000',
        AttemptAmount: Math.ceil(payable),
        PaidAmount: 0,
        Status: 'Cancelled',
        OrderAmount: payable,
        OrderNo: orderNumber,
        Phone: phone,
        isNew: "0"
      },
      before: () => {},
      successed: (res) => {
        window.location.replace(res.Msg);
        setIsRedirection(true);
      },
      failed: () => {
        setIsRedirection(true);
      },
      always: () => {},
    });
  };

  return (
    <div class='inv-flex-content d-flex js-center al-center'>
      <h4>Order Invoice</h4>
      <ul className='order-details-buttons d-flex js-center al-center'>
        {
        /*paymentStatus === PAYMENT_STATUS.UNPAID && (
          <li>
            <Button onClick={makePayment}>
              Make Payment
            </Button>
          </li>
        )*/
        }
        <li>
          <Button onClick={onUpload}>Upload Prescriptions</Button>
        </li>
        <li>
          <Button onClick={onAttach}>Attach Prescription</Button>
        </li>
        <li>
          <Button onClick={onPrint}>Print Invoice</Button>
        </li>
      </ul>
    </div>
  );
};

export default OrderActions;
