import { CONFIG } from "./config-service";

var BASE_URL = CONFIG.IMAGE_URL;

export const IMAGE_SIZE = {
    SMALL: 'Small',
    ICON: 'Icons',
    ORIGINAL: 'Orginal'
}

export const DEFAULT_IMAGE = {
    PRODUCT: 'PRODUCT',
    BANNER: 'BANNER',
    EMPTY: null
}

export const IMAGE_OF = {
    BANNER: 'Banner',
    PRODUCT: 'Product',
    CATEGORY_SLIDER: 'Slider',
    GALLERY: 'Gallery',
    OFFER: 'Offer'
}

export const imageURL = (imageName, imageOf, imageSize = IMAGE_SIZE.ORIGINAL) => {
    return `${BASE_URL}/Content/ImageData/${imageOf}/${imageSize}/${imageName}`;
}