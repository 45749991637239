import React, { useContext } from 'react';
import { imageURL, IMAGE_OF, IMAGE_SIZE } from '../../../Service/imageResolver';
import cartContext from '../../../store/cart-context';
import defaultImage from '../../../images/default-medicine.png';
import Card from '../../Shared/Card/Card';
import { Link } from 'react-router-dom';

const Products = () => {
  const {
    updateQuantity,
    singleItemRemover,
    updateEditableQuantity,
    getCartModel: cart,
  } = useContext(cartContext);

  console.log(cart);

  const qtyDecHandler = (findItem, e) => {
    e.preventDefault();
    let quantity = findItem.quantity - 1;
    updateQuantity(findItem, quantity);
  };

  const qtyIncHandler = (findItem, e) => {
    e.preventDefault();
    let quantity = findItem.quantity + 1;
    updateQuantity(findItem, quantity);
  };

  const cartItemRemoverHandler = (item) => {
    singleItemRemover(item);
  };

  const qtyChangeHandler = (item, { target }) => {
    updateEditableQuantity(item, target.value);
  };

  const blurHandler = (item, e) => {
    console.log(item, e.target.value);
    if (e.target.value === '0') {
      alert("Quantity can't be less than 1.");
      updateEditableQuantity(item, 1);
    }
  };

  return (
    <Card>
      <table class='table table-responsive cart_summary'>
        <thead>
          <tr className='text-semibold'>
            <th class='cart_product text-semibold'>Product</th>
            <th className='text-semibold'>Description</th>
            <th className='unit_price text-semibold'>Price</th>
            <th className='text-semibold'>Qty</th>
            <th className='text-semibold'>Amount</th>
            <th class='action text-semibold'>Action</th>
          </tr>
        </thead>
        <tbody>
          {cart.Items.map((item, index) => (
            <tr key={item.id}>
              <td class='cart_product'>
                <Link to={'/product/details/' + item.permalink}>
                  <img
                    src={
                      item.image
                        ? imageURL(
                            item.image,
                            IMAGE_OF.PRODUCT,
                            IMAGE_SIZE.ICON
                          )
                        : defaultImage
                    }
                    alt='Product'
                  />
                </Link>
              </td>
              <td class='cart_description'>
                <p class='product-name'>
                  <a href>
                    {item.Nm} {item.St} {item.Ct}
                  </a>
                </p>
              </td>
              <td class='price' style={{ textAlign: 'center', width: '15%' }}>
                {item?.discount > 0 && item.discount !== null ? (
                  <span>৳ {(item.MRP - item.discount).toFixed(2)}</span>
                ) : (
                  <span>৳ {item.MRP}</span>
                )}
              </td>
              <td class='qty'>
                <div class='pro_qty'>
                  <a href onClick={qtyDecHandler.bind(this, item)}>
                    <i class='fa fa-minus' aria-hidden='true'></i>
                  </a>
                  <input
                    class='form-control input-sm'
                    type='text'
                    value={item.quantity}
                    onChange={qtyChangeHandler.bind(null, item)}
                    onBlur={blurHandler.bind(null, item)}
                  />
                  <a href onClick={qtyIncHandler.bind(this, item)}>
                    <i class='fa fa-plus'></i>
                  </a>
                </div>
              </td>
              <td class='price' style={{ textAlign: 'center', width: '18%' }}>
                {item.discount === 0 && item.discount !== null && (
                  <span>৳ {(item.MRP * item.quantity).toFixed(2)}</span>
                )}
                {item.discount > 0 && (
                  <span>
                    ৳{((item.MRP - item.discount) * item.quantity).toFixed(2)}
                  </span>
                )}
              </td>
              <td
                class='action'
                onClick={cartItemRemoverHandler.bind(null, item)}
              >
                <a
                  className='icon-remove'
                  href
                  style={{ fontSize: '1.5rem', color: 'red' }}
                >
                  <i class='fa fa-remove' aria-hidden='true'></i>
                </a>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colspan='4' className='text-semibold'>Total</td>
            <td colspan='2' className='text-semibold' style={{ textAlign: 'center' }}>
              ৳ <span>{cart.TotalAmmount.toFixed(2)}</span>
            </td>
          </tr>
        </tfoot>
      </table>
    </Card>
  );
};

export default Products;
