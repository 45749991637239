import React from 'react';
import { Link } from 'react-router-dom';

const SlideShowTemplate = ({ item }) => {
  const isPhoneNumber = item.link?.startsWith('tel');

  return (
    
    <div className='category-gallery-shadow'>
      {!!!item.link && (
        <span>
        <img
          class='img-responsive'
          alt={item.altrText}
          src={window.BaseUrl + item.image}
          style={{ margin: '0px auto' }}
        />
        </span>
      )}
      {!!item.link && !isPhoneNumber && (
        <Link to={item.link}>
        <img
          class='img-responsive'
          alt={item.altrText}
          src={window.BaseUrl + item.image}
          style={{ margin: '0px auto' }}
        />
        </Link>
      )}
      {!!item.link && isPhoneNumber && (
        <a href={item.link}>
        <img
          class='img-responsive'
          alt={item.altrText}
          src={window.BaseUrl + item.image}
          style={{ margin: '0px auto' }}
        />
        </a>
      )}
    </div>
  );
};

export default SlideShowTemplate;
