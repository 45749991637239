import { Link } from "react-router-dom";
import { humanizeShortDateTime } from "../../../../lib/utilities";

const Transaction = ({ transaction, index }) => {
    const paymentDate = transaction.PaymentDate && humanizeShortDateTime(transaction.PaymentDate);


  return (
    <tr>
      <th scope="row">{index + 1}</th>
      <td>
        #
        <Link to={`/profile/order/details/${transaction.OrderId}`} className="hover-underline">
          {transaction.OrderNo}
        </Link>
      </td>
      <td>{transaction.TranId}</td>
      <td>{transaction.CardType}</td>
      <td>{transaction.AttemptAmount}Tk</td>
      <td>{transaction.Status}</td>
      <td>{paymentDate}</td>
    </tr>
  );
};

export default Transaction;
